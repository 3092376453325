import convertToExam from '../helpers/converters/convert-exam';
import httpClient from '../http-client';
import Exam from '../models/exam';
import ExamForCreate from '../models/for-create/exam';
import ExamForUpdate from '../models/for-update/exam';
import ExamFromResponse from '../models/from-api-response/exam';

const getExams = async (filters?: any) => {
  if (filters) {
    for (let param of Object.keys(filters)) {
      if (!filters[param]) {
        delete filters[param];
      }
    }
  }

  const exams = (
    await httpClient.get<ExamFromResponse[]>('/exams', { params: filters })
  ).data;

  let convertedExams = [] as Exam[];
  if (exams && exams.length) {
    convertedExams = exams.map(convertToExam);
  }

  return convertedExams;
};

const createExam = async (exam: ExamForCreate) => {
  await httpClient.post('/exams', exam);
};

const updateExam = async (examId: string, exam: ExamForUpdate) => {
  await httpClient.put(`/exams/${examId}`, exam);
};

const getExam = async (examId: string) => {
  const exam = (await httpClient.get<ExamFromResponse>(`/exams/${examId}`))
    .data;

  let convertedExam = {} as Exam;
  if (exam && Object.keys(exam).length) {
    convertedExam = convertToExam(exam);
  }

  return convertedExam;
};

const deleteExam = async (examId: string) => {
  await httpClient.delete(`exams/${examId}`);
};

const activateOrInactivateExam = async (examId: string) => {
  await httpClient.put(`/exams/${examId}/active`);
};

export {
  getExams,
  createExam,
  updateExam,
  getExam,
  deleteExam,
  activateOrInactivateExam,
};
