import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import BreadCrumb from '../../components/BreadCrumb';
import DefaultButton from '../../components/DefaultButton';
import DefaultFilterForm from '../../components/DefaultFilterForm';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { getCoursesForSelect } from '../../services/courses';
import { generateAccessesReport } from '../../services/reports';
import { getUsersForSelect } from '../../services/users';
import { AccessesContainer } from './style';

const Accesses: React.FC = () => {
  const [courses, setCourses] = useState(
    [] as { value: string; label: string }[],
  );
  const [selectedCourses, setSelectedCourses] = useState([] as string[]);

  const [users, setUsers] = useState([] as { value: string; label: string }[]);
  const [selectedUsers, setSelectedUsers] = useState([] as string[]);

  const [accessStart, setAccessStart] = useState('');
  const [accessFinish, setAccessFinish] = useState('');

  const generateReport = async (reportType: string) => {
    const queryStrings: any = {
      user_id_ft: selectedUsers,
      trail_id_ft: selectedCourses,
      access_at_gte: accessStart,
      access_at_lte: accessFinish,
    };

    for (let prop of Object.keys(queryStrings)) {
      if (!queryStrings[prop]) {
        delete queryStrings[prop];
      }
    }

    await generateAccessesReport(reportType, queryStrings);
  };

  useEffect(() => {
    (async () => {
      const localCourses = await getCoursesForSelect();
      if (localCourses && localCourses.length) {
        setCourses(localCourses);
      }

      const localUsers = await getUsersForSelect();
      if (localUsers && localUsers.length) {
        setUsers(localUsers);
      }
    })();
  }, []);

  return (
    <AccessesContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Dashboard</Link>,
          <span>Relatórios</span>,
          <span>Acessos</span>,
        ]}
      />

      <DefaultPageTitle>Relatório de Acessos</DefaultPageTitle>

      <DefaultFilterForm>
        <span className="entire-row">
          <label htmlFor="users">Usuários</label>
          <Select
            placeholder="Selecione um usuário"
            isMulti
            onChange={options =>
              setSelectedUsers(options.map(opt => opt.value))
            }
            options={users}
          />
        </span>

        <span>
          <label htmlFor="startAt">Acesso em</label>
          <input
            type="date"
            id="startAtGte"
            max={accessStart}
            onChange={e => setAccessStart(e.target.value)}
            onKeyDown={e => {
              e.stopPropagation();
              e.preventDefault();
              return false;
            }}
          />
        </span>

        <span>
          <span className="label">até</span>
          <input
            type="date"
            id="startAtLte"
            min={accessFinish}
            onChange={e => setAccessFinish(e.target.value)}
            onKeyDown={e => {
              e.stopPropagation();
              e.preventDefault();
              return false;
            }}
          />
        </span>

        <span className="entire-row center">
          <DefaultButton
            onClick={() => generateReport('pdf')}
            className="success"
            type="button"
          >
            Gerar Relatório (PDF)
          </DefaultButton>{' '}
          <DefaultButton
            onClick={() => generateReport('csv')}
            className="success"
            type="button"
          >
            Gerar Relatório (CSV)
          </DefaultButton>
        </span>
      </DefaultFilterForm>
    </AccessesContainer>
  );
};

export default Accesses;
