import React from 'react';
import {
  BsClipboardData,
  BsFileEarmarkCheck,
  BsFileText,
  BsGraphUp,
} from 'react-icons/bs';
import { GiTeamUpgrade } from 'react-icons/gi';

const menusAnalyst = [
  {
    label: (
      <>
        <span className="icon">
          <BsClipboardData />
        </span>
        <span className="text">Relatórios</span>
      </>
    ),
    children: [
      {
        label: (
          <>
            <span className="icon">
              <GiTeamUpgrade />
            </span>
            <span className="text">Engajamento Cursos</span>
          </>
        ),
        path: '/reports/engagement',
      },
      {
        label: (
          <>
            <span className="icon">
              <GiTeamUpgrade />
            </span>
            <span className="text">Engajamento Trilha</span>
          </>
        ),
        path: '/reports/engagementTrail',
      },
      {
        label: (
          <>
            <span className="icon">
              <GiTeamUpgrade />
            </span>
            <span className="text">Engajamento Depoimentos</span>
          </>
        ),
        path: '/reports/engagementTestimonial',
      },
      {
        label: (
          <>
            <span className="icon">
              <BsGraphUp />
            </span>
            <span className="text">Acessos</span>
          </>
        ),
        path: '/reports/accesses',
      },
      {
        label: (
          <>
            <span className="icon">
              <BsFileText />
            </span>
            <span className="text">Provas</span>
          </>
        ),
        path: '/reports/exams',
      },
    ],
  },
  {
    label: (
      <>
        <span className="icon">
          <BsFileEarmarkCheck />
        </span>
        <span className="text">Avaliar Exercícios</span>
      </>
    ),
    path: '/tests-received',
  },
];

export default menusAnalyst;
