import httpClient from "../http-client";
import AboutFromResponse from "../models/from-api-response/about";
import AboutUsBannerFromResponse from "../models/from-api-response/about-us-banner";
import FooterImageFromResponse from "../models/from-api-response/footer-image";
import { v4 as uuidv4 } from "uuid";

const getAbout = async () => {
  const about = (await httpClient.get<AboutFromResponse[]>("/about-us")).data;

  if (about && about.length) {
    const aboutToBeReturned = about[0];

    return aboutToBeReturned;
  } else {
    const createdAbout = await createEmptyAbout();

    return createdAbout;
  }
};

const createEmptyAbout = async () => {
  return (await httpClient.post<AboutFromResponse>("/about-us")).data;
};

const updateAbout = async (about: AboutFromResponse) => {
  if (!about.banner) {
    about.banner = { title: "", description: "", image: "" };
  }

  if (!about.institucional_video) {
    about.institucional_video = "";
  }

  if (!about.footer_images || !about.footer_images.length) {
    about.footer_images = [];
  }

  const updatedAbout = (
    await httpClient.put<AboutFromResponse>(`/about-us/${about.about_us_id}`, {
      banner: about.banner,
      institucional_video: about.institucional_video,
      footer_images: about.footer_images,
    } as AboutFromResponse)
  ).data;

  return updatedAbout;
};

const getAboutUs = async () => {
  const actualAbout = await getAbout();

  return actualAbout.banner;
};

const updateAboutUs = async (banner: AboutUsBannerFromResponse) => {
  const actualAbout = await getAbout();

  actualAbout.banner = banner;

  await updateAbout(actualAbout);
};

const getInstitutionalVideo = async () => {
  const actualAbout = await getAbout();

  return actualAbout.institucional_video;
};

const updateInstitutionalVideo = async (
  institutionalVideoReference: string
) => {
  const actualAbout = await getAbout();

  actualAbout.institucional_video = institutionalVideoReference;

  await updateAbout(actualAbout);
};

const changePhisicalSpacePosition = async (imageId: string, up: boolean) => {
  const actualAbout = await getAbout();

  if (actualAbout.footer_images && actualAbout.footer_images.length) {
    if (up) {
      const bannerToBeUpdated = actualAbout.footer_images.find(
        (banner) => banner.image_id === imageId
      );

      if (bannerToBeUpdated) {
        const indexOFBannerToBeUpdated = actualAbout.footer_images.indexOf(
          bannerToBeUpdated
        );

        if (
          indexOFBannerToBeUpdated > 0 &&
          indexOFBannerToBeUpdated < actualAbout.footer_images.length
        ) {
          const previousBanner =
            actualAbout.footer_images[indexOFBannerToBeUpdated - 1];

          actualAbout.footer_images.splice(
            indexOFBannerToBeUpdated - 1,
            1,
            bannerToBeUpdated
          );
          actualAbout.footer_images.splice(
            indexOFBannerToBeUpdated,
            1,
            previousBanner
          );

          if (previousBanner) {
            await updateAbout(actualAbout);

            return bannerToBeUpdated;
          }
        }
      }
    } else {
      const bannerToBeUpdated = actualAbout.footer_images.find(
        (banner) => banner.image_id === imageId
      );

      if (bannerToBeUpdated) {
        const indexOFBannerToBeUpdated = actualAbout.footer_images.indexOf(
          bannerToBeUpdated
        );

        if (
          indexOFBannerToBeUpdated > 0 &&
          indexOFBannerToBeUpdated < actualAbout.footer_images.length - 1
        ) {
          const nextBanner =
            actualAbout.footer_images[indexOFBannerToBeUpdated + 1];

          actualAbout.footer_images.splice(
            indexOFBannerToBeUpdated + 1,
            1,
            bannerToBeUpdated
          );
          actualAbout.footer_images.splice(
            indexOFBannerToBeUpdated,
            1,
            nextBanner
          );

          if (nextBanner) {
            await updateAbout(actualAbout);

            return bannerToBeUpdated;
          }
        }
      }
    }
  }

  return {} as FooterImageFromResponse;
};

const removePhisicalSpace = async (imageId: string) => {
  const actualAbout = await getAbout();

  if (actualAbout.footer_images && actualAbout.footer_images.length) {
    const bannerToBeRemoved = actualAbout.footer_images.find(
      (banner) => banner.image_id === imageId
    );

    if (bannerToBeRemoved) {
      const indexOfBannerToBeRemoved = actualAbout.footer_images.indexOf(
        bannerToBeRemoved
      );
      if (
        indexOfBannerToBeRemoved > -1 &&
        indexOfBannerToBeRemoved < actualAbout.footer_images.length
      ) {
        actualAbout.footer_images.splice(indexOfBannerToBeRemoved, 1);

        await updateAbout(actualAbout);
      }
    }
  }
};

const getPhisicalSpace = async (imageId: string) => {
  const actualAbout = await getAbout();

  if (actualAbout.footer_images && actualAbout.footer_images.length) {
    const bannerToBeReturned = actualAbout.footer_images.find(
      (banner) => banner.image_id === imageId
    );

    return bannerToBeReturned;
  }

  return {} as FooterImageFromResponse;
};

const updatePhisicalSpace = async (
  imageId: string,
  image: string
): Promise<FooterImageFromResponse> => {
  const about = await getAbout();

  if (about.footer_images && about.footer_images.length) {
    let bannerToBeUpdated = about.footer_images.find(
      (banner) => banner.image_id === imageId
    );

    if (bannerToBeUpdated) {
      const indexOfBannerToBeUpdated = about.footer_images.indexOf(
        bannerToBeUpdated
      );
      if (
        indexOfBannerToBeUpdated > -1 &&
        indexOfBannerToBeUpdated < about.footer_images.length
      ) {
        const bannerUpdateData = {
          image_id: imageId,
          image: image,
        };

        about.footer_images.splice(
          indexOfBannerToBeUpdated,
          1,
          bannerUpdateData
        );

        await updateAbout(about);

        return bannerUpdateData;
      }
    }
  }

  return {} as FooterImageFromResponse;
};

const createPhisicalSpace = async (
  imageReference: string
): Promise<FooterImageFromResponse> => {
  const about = await getAbout();

  const createdBanner = {
    image_id: uuidv4(),
    image: imageReference,
  };

  about.footer_images = [...(about.footer_images || []), createdBanner];
  
  await updateAbout(about);

  return createdBanner;
};

export {
  getAbout,
  updateAboutUs,
  getAboutUs,
  updateAbout,
  updateInstitutionalVideo,
  getInstitutionalVideo,
  changePhisicalSpacePosition,
  removePhisicalSpace,
  getPhisicalSpace,
  updatePhisicalSpace,
  createPhisicalSpace,
};
