import httpClient from "../http-client";

const getCategory = async (categoryId: string) => {
  const category = (await httpClient.get<any>(`forum/categories/${categoryId}`))
    .data;

  return category;
};

const getAllCategories = async (filters?: any) => {
  if (filters) {
    for (let param of Object.keys(filters)) {
      if (!filters[param]) {
        delete filters[param];
      }
    }
  }

  const categories = (
    await httpClient.get<any[]>(`forum/categories/with-amount-questions`, { params: filters })
  ).data;

  return categories;
};

const getQuestionsForCategory = async (categoryId: string) => {
  const questions = (
    await httpClient.get<any[]>(`forum/categories/${categoryId}/with-questions`)
  ).data;

  return questions;
};

const deleteQuestions = async (questionId: string) => {
  await httpClient.delete(`forum/questions/${questionId}`);
};

const createCategory = async (newCategory: any) => {
  const createCategoryResponse = (
    await httpClient.post<any>(`forum/categories`, newCategory)
  ).data;

  return createCategoryResponse;
};

const updateCategory = async (
  categoryId: string,
  newContentForCategory: any
) => {
  await httpClient.put(`forum/categories/${categoryId}`, newContentForCategory);
};

const deleteCategory = async (categoryId: string) => {
  await httpClient.delete(`forum/categories/${categoryId}`);
};

export {
  getAllCategories,
  getQuestionsForCategory,
  deleteCategory,
  updateCategory,
  createCategory,
  getCategory,
  deleteQuestions,
};
