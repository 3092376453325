import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { BiDownload, BiTrash } from 'react-icons/bi';
import { Link, useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import BreadCrumb from '../../components/BreadCrumb';
import DefaultButton from '../../components/DefaultButton';
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from '../../components/DefaultCreationForm';
import DefaultInput from '../../components/DefaultInput';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { UpdatedAtLabel } from '../../components/UpdatedAt';
import { DefaultTextArea } from '../../components/DefaultTextArea';
import SelectSambaVideosContent from '../../components/SelectSambaVideosContent';
import { hideModal, showModal } from '../../helpers/modal';
import MediaFromResponse from '../../models/from-api-response/media';
import {
  createExtraMaterial as createExtraMaterialService,
  deleteExtraMaterial as deleteExtraMaterialService,
} from '../../services/extra-materials';
import { getExams } from '../../services/exams';
import {
  ContentThumbnail,
  ContentUploadOrSelectContainer,
  CreateAndEditContentContainer,
  ThumbnailUploadContainer,
  Switch,
  ExtraMaterial,
  ExtraMaterialList,
  ExtraMaterialsInput,
} from './style';
import {
  changeContentThumbnail as changeContentThumbnailService,
  createContent as createContentService,
  deleteContent,
  getContent as getContentService,
  updateContent as updateContentService,
} from '../../services/contents';
import CutImage from '../../components/CutImage';
import checkEmptyString from '../../helpers/check-empty-string';
import Select from 'react-select';
import Author from '../../models/author';
import { getAllAuthors } from '../../services/authors';
import Category from '../../models/category';
import { getAllCategories } from '../../services/categories';
import { getSegmentations } from '../../services/segmentation';
import { uploadFile } from '../../services/files';
import Exam from '../../models/exam';
import DefaultSwitch from '../../components/DefaultSwitch';
import UploadScorm from './components/UploadScorm';
import DatePicker from 'react-datepicker';
import addHours from '../../helpers/add-hours';

interface CreateAndEditContentProps {
  contentId: string;
}

interface SegmentationsItems {
  segmentation_item_id: string;
  item: string;
}

const CreateAndEditContent: React.FC = () => {
  const { contentId } = useParams<CreateAndEditContentProps>();
  const inputRef = useRef<any>(null);
  const history = useHistory();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [reference, setReference] = useState('');
  const [authors, setAuthors] = useState('' as unknown as Author[]);
  const [cargo, setCargo] = useState([] as SegmentationsItems[]);
  const [programa, setPrograma] = useState([] as SegmentationsItems[]);
  const [loja, setLoja] = useState([] as SegmentationsItems[]);
  const [validityDate, setValidityDate] = useState<Date | null>();
  const [isValidityDate, setIsValidityDate] = useState(false);

  const [selectedExam, setSelectedExam] = useState(
    {} as { label: string; value: string },
  );
  const [exams, setExams] = useState([] as Exam[]);

  const [selectedCargo, setSelectedCargo] = useState(
    [] as unknown as { label: string; value: string }[],
  );
  const [selectedLoja, setSelectedLoja] = useState(
    [] as unknown as { label: string; value: string }[],
  );
  const [selectedProgram, setSelectedProgram] = useState(
    [] as unknown as { label: string; value: string }[],
  );
  const [extraMaterials, setExtraMaterials] = useState<any>([] as any[]);
  const [extraMaterialsToBeDeleted, setExtraMaterialsToBeDeleted] =
    useState<any>([] as any[]);
  const [selectedAuthors, setSelectedAuthors] = useState(
    [] as unknown as { label: string; value: string }[],
  );

  const [categories, setCategories] = useState('' as unknown as Category[]);
  const [selectedCategory, setSelectedCategory] = useState(
    [] as unknown as { label: string; value: string }[],
  );

  const [duration, setDuration] = useState(0);
  const [isHighlighted, setIsHighlighted] = useState(false);
  const [isSuggested, setIsSuggested] = useState(false);
  const [type, setType] = useState('');

  const [thumbnailFileToUpload, setThumbnailFileToUpload] = useState<File>();
  const [thumbnail, setThumbnail] = useState('');
  const [switchState, setSwitchState] = useState<string>('video');
  const [updatedAt, setUpdatedAt] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [generateCertificate, setGenerateCertificate] = useState(true);

  const onSelectContent = (sambaVideosContent: MediaFromResponse) => {
    if (sambaVideosContent) {
      if (sambaVideosContent.files && sambaVideosContent.files.length) {
        const firstFile = sambaVideosContent.files[0];

        if (firstFile) {
          const playerKey = process.env.REACT_APP_PLAYER_KEY;
          const referenceUrl = `${process.env.REACT_APP_PLAYER_INITIAL_URL}/${playerKey}/${sambaVideosContent.id}`;

          setReference(referenceUrl);
          setDuration(firstFile.fileInfo.duration / 1000);
          setType(firstFile.qualifier);
        }
      }
    }

    hideModal();
  };

  const createContent = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(title)) {
        throw new Error('Informe um título válido para o conteúdo.');
      }

      if (!reference) {
        throw new Error('Selecione um conteúdo antes.');
      }

      if (!thumbnail) {
        throw new Error('Selecione uma imagem para a capa antes.');
      }

      if (!selectedCategory || !selectedCategory.length) {
        throw new Error('Selecione uma categoria antes.');
      }

      if (validityDate && validityDate <= new Date()) {
        throw new Error('Informe uma data de vigência superior a data atual.');
      }

      const segmentation_items = [
        ...selectedCargo.map(s => ({ segmentation_item_id: s.value })),
        ...selectedLoja.map(s => ({ segmentation_item_id: s.value })),
        ...selectedProgram.map(s => ({ segmentation_item_id: s.value })),
      ];

      let createCourseData;

      if (segmentation_items.length) {
        createCourseData = {
          title: title,
          description: description.trim().length ? description : undefined,
          duration: switchState === 'scorm' ? 0 : duration,
          reference: reference,
          type:
            switchState === 'scorm'
              ? switchState.toUpperCase()
              : type.toLocaleUpperCase(),
          is_library: true,
          segmentation_items,
          exam_id:
            selectedExam && selectedExam.value ? selectedExam.value : undefined,
          categories:
            selectedCategory &&
            selectedCategory.map(category => ({
              category_id: category.value,
            })),
          authors:
            selectedAuthors && selectedAuthors.length
              ? selectedAuthors.map((a: any) => ({ author_id: a.value }))
              : undefined,
          info: {
            is_highlighted: isHighlighted,
            is_suggested: isSuggested,
            allow_user_file: isChecked,
            no_certificate: generateCertificate ? false : true,
          },
          deadline:
            isValidityDate && validityDate
              ? validityDate.toISOString()
              : undefined,
        };
      } else {
        createCourseData = {
          title: title,
          description: description.trim().length ? description : undefined,
          duration: switchState === 'scorm' ? 0 : duration,
          reference: reference,
          exam_id:
            selectedExam && selectedExam.value ? selectedExam.value : undefined,
          type:
            switchState === 'scorm'
              ? switchState.toUpperCase()
              : type.toLocaleUpperCase(),
          is_library: true,
          categories:
            selectedCategory &&
            selectedCategory.map(category => ({
              category_id: category.value,
            })),
          authors:
            selectedAuthors && selectedAuthors.length
              ? selectedAuthors.map((a: any) => ({ author_id: a.value }))
              : undefined,

          info: {
            is_highlighted: isHighlighted,
            is_suggested: isSuggested,
            allow_user_file: isChecked,
            no_certificate: generateCertificate ? false : true,
          },
          deadline:
            isValidityDate && validityDate
              ? validityDate.toISOString()
              : undefined,
        };
      }

      const createdContent = await createContentService(createCourseData);

      if (thumbnailFileToUpload) {
        try {
          await changeContentThumbnail(
            thumbnailFileToUpload,
            createdContent.content_id,
          );
        } catch (error) {
          await deleteContent(createdContent.content_id);

          throw new Error(
            'Erro ao fazer upload da imagem de capa. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.',
          );
        }
      }

      for (let material of extraMaterialsToBeDeleted) {
        await deleteExtraMaterialService(material.id);
      }

      let uploadedMaterials = [] as { filename: string; reference: string }[];

      if (extraMaterials && extraMaterials.length >= 1) {
        for (let material of extraMaterials.filter(
          (extraMaterial: any) => !extraMaterial.alreadySaved,
        )) {
          const formData = new FormData();
          formData.append('file', material);

          await uploadFile(formData).then(({ reference, name }) =>
            uploadedMaterials.push({ reference, filename: name }),
          );
        }
      }

      if (uploadedMaterials.length >= 1) {
        await createExtraMaterialService(
          createdContent.content_id,
          uploadedMaterials,
        );
      }

      Swal.fire({
        title: 'Sucesso!',
        text: 'Curso criado com sucesso!',
        icon: 'success',
      });

      goToContents();
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao criar o curso. ' + error.message,
        icon: 'error',
      });
    }
  };

  const updateContent = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(title)) {
        throw new Error('Informe um título válido para o curso.');
      }

      if (!reference) {
        throw new Error('Selecione um conteúdo antes.');
      }

      if (!thumbnail) {
        throw new Error('Selecione uma imagem para a capa antes.');
      }

      if (!selectedCategory || !selectedCategory.length) {
        throw new Error('Selecione uma categoria antes.');
      }

      if (validityDate && validityDate <= new Date()) {
        throw new Error('Informe uma data de vigência superior a data atual.');
      }

      const segmentation_items = [
        ...selectedCargo.map(s => ({ segmentation_item_id: s.value })),
        ...selectedLoja.map(s => ({ segmentation_item_id: s.value })),
        ...selectedProgram.map(s => ({ segmentation_item_id: s.value })),
      ];

      let createCourseData;

      if (segmentation_items.length) {
        createCourseData = {
          title: title,
          description: description || undefined,
          duration: switchState === 'scorm' ? 0 : duration,
          reference: reference,
          type:
            switchState === 'scorm'
              ? switchState.toUpperCase()
              : type.toLocaleUpperCase(),
          is_active: true,
          is_library: true,
          exam_id:
            selectedExam && selectedExam.value ? selectedExam.value : undefined,
          categories:
            selectedCategory &&
            selectedCategory.map(category => ({
              category_id: category.value,
            })),
          authors:
            selectedAuthors && selectedAuthors.length
              ? selectedAuthors.map((a: any) => ({ author_id: a.value }))
              : undefined,
          segmentation_items,
          info: {
            is_highlighted: isHighlighted,
            is_suggested: isSuggested,
            allow_user_file: isChecked,
            no_certificate: generateCertificate ? false : true,
          },
          deadline:
            isValidityDate && validityDate ? validityDate.toISOString() : null,
        };
      } else {
        createCourseData = {
          title: title,
          description: description || undefined,
          duration: switchState === 'scorm' ? 0 : duration,
          reference: reference,
          type:
            switchState === 'scorm'
              ? switchState.toUpperCase()
              : type.toLocaleUpperCase(),
          is_active: true,
          is_library: true,
          exam_id:
            selectedExam && selectedExam.value ? selectedExam.value : undefined,
          categories:
            selectedCategory &&
            selectedCategory.map(category => ({
              category_id: category.value,
            })),
          authors:
            selectedAuthors && selectedAuthors.length
              ? selectedAuthors.map((a: any) => ({ author_id: a.value }))
              : undefined,
          info: {
            is_highlighted: isHighlighted,
            is_suggested: isSuggested,
            allow_user_file: isChecked,
            no_certificate: generateCertificate ? false : true,
          },
          deadline:
            isValidityDate && validityDate ? validityDate.toISOString() : null,
        };
      }
      await updateContentService(contentId, createCourseData);

      if (thumbnailFileToUpload) {
        try {
          await changeContentThumbnail(thumbnailFileToUpload, contentId);
        } catch (error) {
          throw new Error(
            'Erro ao fazer upload da imagem de capa. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.',
          );
        }
      }

      for (let material of extraMaterialsToBeDeleted) {
        await deleteExtraMaterialService(material.id);
      }

      let uploadedMaterials = [] as { filename: string; reference: string }[];

      if (extraMaterials && extraMaterials.length >= 1) {
        for (let material of extraMaterials.filter(
          (extraMaterial: any) => !extraMaterial.alreadySaved,
        )) {
          const formData = new FormData();
          formData.append('file', material);

          await uploadFile(formData).then(({ reference, name }) =>
            uploadedMaterials.push({ reference, filename: name }),
          );
        }
      }

      if (uploadedMaterials.length >= 1) {
        await createExtraMaterialService(contentId, uploadedMaterials);
      }

      Swal.fire({
        title: 'Sucesso!',
        text: 'Curso editado com sucesso!',
        icon: 'success',
      });

      goToContents();
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao editar o curso. ' + error.message,
        icon: 'error',
      });
    }
  };

  const changeContentThumbnail = async (
    localThumbnailFileToUpload: File,
    localContentId: string,
  ) => {
    const formData = new FormData();
    formData.append('file', localThumbnailFileToUpload);
    formData.append(
      'name',
      `content_thumbnail_${localContentId}.${localThumbnailFileToUpload.type}`,
    );
    formData.append(
      'description',
      `thumbnail do conteúdo de id ${localContentId}`,
    );

    await changeContentThumbnailService(localContentId, formData);
  };

  const selectContent = () => {
    showModal(
      'Selecionar Conteúdo',
      <SelectSambaVideosContent onSelectContent={onSelectContent} />,
    );
  };

  const selectThumbnail = () => {
    showModal(
      'Selecionar Imagem de Capa',
      <CutImage aspect={1.812} onCutImage={onCutImage} />,
    );
  };

  const onCutImage = (file: File) => {
    if (file) {
      setThumbnailFileToUpload(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setThumbnail(`${reader.result}`);

      hideModal();
    }
  };

  const goToContents = () => {
    history.push('/contents');
  };

  const getContent = useCallback(async () => {
    const foundExams = await getExams();
    const foundAuthors = await getAllAuthors();
    const foundCategories = await getAllCategories({ is_active: true });
    const foundSegmentations = await getSegmentations();
    setExams((foundExams || []).filter((exam: any) => exam.enabled));
    if (foundAuthors) setAuthors(foundAuthors);
    if (foundCategories) setCategories(foundCategories);
    if (foundSegmentations) {
      foundSegmentations.map((segmentation: any) => {
        if (segmentation.type === 'Cargo') {
          setCargo(segmentation.items);
        }
        if (segmentation.type === 'Unidade') {
          setLoja(segmentation.items);
        }
        if (segmentation.type === 'Programa') {
          setPrograma(segmentation.items);
        }
      });
    }
    if (contentId) {
      const content = await getContentService(contentId);

      if (content && Object.keys(content).length) {
        setTitle(content.content.title);
        if (content.content.deadline) {
          setIsValidityDate(true);
          setValidityDate(new Date(content.content.deadline));
        }
        setDescription(content.content.description);
        setReference(content.content.reference);
        setDuration(content.content.duration);
        setThumbnail(content.content.thumbnail);
        setType(content.content.type);
        setIsHighlighted(content.content.info.is_highlighted);
        setIsSuggested(content.content.info.is_suggested);
        setGenerateCertificate(
          content.content.info.no_certificate ? false : true,
        );
        if (
          typeof content.content.type === 'string' &&
          content.content.type.toUpperCase() === 'SCORM'
        ) {
          setSwitchState('scorm');
        }

        if (content.content?.updated_at) {
          let date = new Date(content.content.updated_at);
          setUpdatedAt(date.toLocaleString());
        }

        const courseExam =
          typeof content.content.exam === 'object'
            ? (foundExams || []).find(
                (exam: any) => exam.id === content.content.exam.examId,
              ) || ({} as Exam)
            : (foundExams || []).find(
                (exam: any) => exam.id === content.content.exam,
              ) || ({} as Exam);

        setSelectedExam({ label: courseExam.title, value: courseExam.id });

        if (content.content.authors) {
          const selectedAuthors = content.content.authors.map(
            (author: Author) => ({
              label: author.name,
              value: `${author.id}`,
            }),
          );
          setSelectedAuthors(selectedAuthors);
        }

        if (content.content.categories) {
          const selectedCategories = content.content.categories.map(
            (category: Category) => ({
              label: category.title,
              value: `${category.id}`,
            }),
          );
          setSelectedCategory(selectedCategories);
        }

        if (
          content.content.segmentations &&
          content.content.segmentations[0] !== null
        ) {
          content.content.segmentations.map((segmentation: any) => {
            if (segmentation.type === 'Cargo') {
              setSelectedCargo(
                segmentation.items.map((segItem: any) => ({
                  label: segItem.item,
                  value: segItem.segmentation_item_id,
                })),
              );
            }
            if (segmentation.type === 'Programa') {
              setSelectedProgram(
                segmentation.items.map((segItem: any) => ({
                  label: segItem.item,
                  value: segItem.segmentation_item_id,
                })),
              );
            }
            if (segmentation.type === 'Unidade') {
              setSelectedLoja(
                segmentation.items.map((segItem: any) => ({
                  label: segItem.item,
                  value: segItem.segmentation_item_id,
                })),
              );
            }
          });
        }

        const localExtraMaterials =
          content.content.extra_materials &&
          content.content.extra_materials.length &&
          content.content.extra_materials.map((extraMaterial: any) => ({
            id: extraMaterial.extra_material_id,
            name: extraMaterial.name,
            alreadySaved: true,
            reference: extraMaterial.reference,
          }));

        setExtraMaterials(localExtraMaterials);
      }
    }
  }, [contentId]);

  const lojaToBeSelected = useMemo(() => {
    if (loja && loja.length) {
      return loja.map(author => ({
        label: author.item,
        value: `${author.segmentation_item_id}`,
      }));
    }

    return [] as { label: string; value: string }[];
  }, [loja]);

  const programaToBeSelected = useMemo(() => {
    if (programa && programa.length) {
      return programa.map(programa => ({
        label: programa.item,
        value: `${programa.segmentation_item_id}`,
      }));
    }

    return [] as { label: string; value: string }[];
  }, [programa]);

  const authorsToBeSelected = useMemo(() => {
    if (authors && authors.length) {
      return authors.map(author => ({
        label: author.name,
        value: `${author.author_id}`,
      }));
    }

    return [] as { label: string; value: string }[];
  }, [authors]);

  const categoriesToBeSelected = useMemo(() => {
    if (categories && categories.length) {
      return categories.map(category => ({
        label: category.title,
        value: `${category.category_id}`,
      }));
    }

    return [] as { label: string; value: string }[];
  }, [categories]);

  const cargoToBeSelected = useMemo(() => {
    if (cargo && cargo.length) {
      return cargo.map(cargo => ({
        label: cargo.item,
        value: `${cargo.segmentation_item_id}`,
      }));
    }

    return [] as { label: string; value: string }[];
  }, [cargo]);

  useEffect(() => {
    getContent();
  }, [getContent]);
  useEffect(() => {
    setGenerateCertificate(true);
  }, [selectedExam.value]);
  const isEditting = useMemo(() => {
    if (contentId) {
      return true;
    }

    return false;
  }, [contentId]);

  const addExtraMaterial = (event: any) => {
    event.preventDefault();

    if (event) {
      let file = event.target.files[0];
      file.alreadySaved = false;

      setExtraMaterials([...(extraMaterials || []), file]);
    }

    inputRef.current.value = '';
  };

  const removeExtraMaterial = (extraMaterialToBeRemoved: any) => {
    if (extraMaterialToBeRemoved.alreadySaved) {
      setExtraMaterialsToBeDeleted([
        ...(extraMaterialsToBeDeleted || []),
        extraMaterialToBeRemoved,
      ]);
    }

    let localExtraMaterials = [...(extraMaterials || [])];
    localExtraMaterials = localExtraMaterials.filter(
      extraMaterial => extraMaterial !== extraMaterialToBeRemoved,
    );

    setExtraMaterials(localExtraMaterials);
  };

  const examsToBeSelected = useMemo(() => {
    let examsToBeReturned = [
      { label: 'Selecione uma prova para este curso', value: '' },
    ] as {
      label: string;
      value: string;
    }[];

    if (exams && exams.length) {
      examsToBeReturned.push(
        ...exams.map(exam => ({
          label: exam.title,
          value: `${exam.id}`,
        })),
      );
    }

    return examsToBeReturned;
  }, [exams]);

  return (
    <CreateAndEditContentContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Dashboard</Link>,
          <Link to="/contents">Cursos</Link>,
          <span>{isEditting ? 'Editar' : 'Criar'} Cursos</span>,
        ]}
      />

      <DefaultPageTitle>
        {isEditting ? 'Editar' : 'Criar'} Cursos
        {updatedAt && (
          <UpdatedAtLabel> - Última atualização em {updatedAt}</UpdatedAtLabel>
        )}
      </DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">
            Título
          </label>
          <DefaultInput
            value={title}
            onChange={e => setTitle(e.target.value)}
            id="title"
            required
            maxLength={255}
          />
          <span style={{ fontSize: '10px', color: '#ccc' }}>
            {title.length}/255
          </span>
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label htmlFor="description">Descrição</label>
          <DefaultTextArea
            value={description}
            onChange={e => setDescription(e.target.value)}
            id="description"
          />
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="reference">
            Capa
          </label>

          <ThumbnailUploadContainer>
            <DefaultButton type="button" onClick={selectThumbnail}>
              Selecionar Imagem de Capa
            </DefaultButton>

            {thumbnail && <ContentThumbnail src={thumbnail} />}
          </ThumbnailUploadContainer>
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="reference">
            Conteúdo
          </label>

          <DefaultSwitch
            switchState={switchState}
            setSwitchState={setSwitchState}
            setReference={setReference}
          />

          <ContentUploadOrSelectContainer>
            {switchState === 'video' ? (
              <DefaultButton type="button" onClick={selectContent}>
                Selecionar Conteúdo
              </DefaultButton>
            ) : (
              <UploadScorm setReference={setReference} />
            )}

            {reference ? (
              <iframe
                title="referenced-video"
                allowFullScreen
                src={reference}
                frameBorder={0}
              ></iframe>
            ) : (
              <></>
            )}
          </ContentUploadOrSelectContainer>
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label htmlFor="exam">Prova</label>
          <Select
            styles={{
              container: provided => ({
                ...provided,
                flexGrow: 1,
                width: '100%',
              }),
            }}
            options={examsToBeSelected}
            value={selectedExam}
            onChange={option =>
              option &&
              setSelectedExam(() => ({
                label: option.label,
                value: option.value,
              }))
            }
            id="exam"
          />
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label>Permitir o envio de arquivos por parte do usuário?</label>
          <Switch style={{ width: '50px' }}>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={() => {
                setIsChecked(oldValue => !oldValue);
              }}
            />
            <span className="slider round"></span>
          </Switch>
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label htmlFor="tags">Autores</label>
          <Select
            styles={{
              container: provided => ({
                ...provided,
                flexGrow: 1,
                width: '100%',
              }),
            }}
            options={authorsToBeSelected}
            value={selectedAuthors}
            onChange={options => {
              options &&
                !window.isNaN(options.length) &&
                setSelectedAuthors(
                  options.map(opt => ({ label: opt.label, value: opt.value })),
                );
            }}
            id="authors"
            isMulti
            placeholder="Selecione um ou mais autores para este curso"
          />
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="tags">
            Categorias
          </label>
          <Select
            styles={{
              container: provided => ({
                ...provided,
                flexGrow: 1,
                width: '100%',
              }),
            }}
            options={categoriesToBeSelected}
            value={selectedCategory}
            onChange={options =>
              options &&
              !window.isNaN(options.length) &&
              setSelectedCategory(
                options.map(opt => ({ label: opt.label, value: opt.value })),
              )
            }
            id="authors"
            isMulti
            placeholder="Selecione uma categoria para este conteúdo."
          />
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label htmlFor="tags">Cargo</label>
          <Select
            styles={{
              container: provided => ({
                ...provided,
                flexGrow: 1,
                width: '100%',
              }),
            }}
            options={cargoToBeSelected}
            value={selectedCargo}
            onChange={(options: any) =>
              options &&
              !window.isNaN(options.length) &&
              setSelectedCargo(
                options.map((opt: any) => ({
                  label: opt.label,
                  value: opt.value,
                })),
              )
            }
            id="cargos"
            placeholder="Selecione os cargos que poderão visualizar o curso."
            isMulti
          />
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label htmlFor="tags">Unidade</label>
          <Select
            styles={{
              container: provided => ({
                ...provided,
                flexGrow: 1,
                width: '100%',
              }),
            }}
            options={lojaToBeSelected}
            value={selectedLoja}
            onChange={(options: any) =>
              options &&
              !window.isNaN(options.length) &&
              setSelectedLoja(
                options.map((opt: any) => ({
                  label: opt.label,
                  value: opt.value,
                })),
              )
            }
            isMulti
            id="lojas"
            placeholder="Selecione as lojas que poderão visualizar o curso."
          />
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label htmlFor="tags">Programa</label>
          <Select
            styles={{
              container: provided => ({
                ...provided,
                flexGrow: 1,
                width: '100%',
              }),
            }}
            options={programaToBeSelected}
            value={selectedProgram}
            onChange={(options: any) =>
              options &&
              !window.isNaN(options.length) &&
              setSelectedProgram(
                options.map((opt: any) => ({
                  label: opt.label,
                  value: opt.value,
                })),
              )
            }
            isMulti
            id="categories"
            placeholder="Selecione os programas que poderão visualizar a trilha."
          />
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label>Destaque</label>
          <Switch>
            <input
              type="checkbox"
              checked={isHighlighted}
              onChange={e => setIsHighlighted(!isHighlighted)}
            />
            <span className="slider round"></span>
          </Switch>
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label>Recomendar ao usuário</label>
          <Switch>
            <input
              type="checkbox"
              checked={isSuggested}
              onChange={e => setIsSuggested(!isSuggested)}
            />
            <span className="slider round"></span>
          </Switch>
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label>Materiais de apoio</label>
          <label htmlFor="extra-materials" className="extra-materials">
            Selecionar material
          </label>
          <ExtraMaterialsInput
            id="extra-materials"
            type="file"
            onChange={e => addExtraMaterial(e)}
            ref={inputRef}
          />
          <ExtraMaterialList>
            {extraMaterials && extraMaterials.length ? (
              extraMaterials.map((material: any, index: number) => (
                <ExtraMaterial key={index}>
                  <h5>{material.name}</h5>
                  {material.reference ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={material.reference}
                    >
                      <BiDownload size={14} />
                    </a>
                  ) : (
                    <></>
                  )}

                  <button
                    onClick={() => removeExtraMaterial(material)}
                    type="button"
                  >
                    <BiTrash size={14} />
                  </button>
                </ExtraMaterial>
              ))
            ) : (
              <p>Não foi encontrado nenhum material de apoio.</p>
            )}
          </ExtraMaterialList>
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label>O curso terá vigência definida?</label>
          <Switch style={{ width: '50px' }}>
            <input
              type="checkbox"
              checked={isValidityDate}
              onChange={() => {
                setIsValidityDate(oldValue => !oldValue);
              }}
            />
            <span className="slider round"></span>
          </Switch>
        </DefaultCreationFormGroup>
        {isValidityDate ? (
          <DefaultCreationFormGroup>
            <label htmlFor="endDate">Vigência:</label>
            <DatePicker
              selected={validityDate}
              onChange={date =>
                date && date instanceof Date && setValidityDate(date)
              }
              onKeyDown={event => event.preventDefault()}
              minDate={addHours(new Date(), 1)}
              dateFormat="dd/MM/yyyy"
            />
          </DefaultCreationFormGroup>
        ) : (
          ''
        )}

        <DefaultCreationFormGroup
          style={selectedExam.value ? { display: 'none' } : { display: '' }}
        >
          <label>Esse curso irá gerar certificado?</label>
          <Switch style={{ width: '50px' }}>
            <input
              type="checkbox"
              checked={generateCertificate}
              onChange={() => {
                setGenerateCertificate(oldValue => !oldValue);
              }}
            />
            <span className="slider round"></span>
          </Switch>
        </DefaultCreationFormGroup>
        <DefaultCreationFormButtonGroup>
          <DefaultButton
            type="button"
            className="danger"
            onClick={goToContents}
          >
            Cancelar
          </DefaultButton>
          <DefaultButton
            onClick={e => (isEditting ? updateContent(e) : createContent(e))}
            className="success"
          >
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditContentContainer>
  );
};

export default CreateAndEditContent;
