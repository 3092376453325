export const putPhoneMask = (phoneStr: string): string => {

  const phone = getNumberForString(phoneStr)

  if (!phone) {
    return phone;
  }

  if (phone.length === 11) {
    return phone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  } else if (phone.length === 10) {
    return phone.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
  }


  if (phone.length <= 1) {
    return phone.replace(/(\d{1})/, '($1');
  }

  if (phone.length >= 7) {
    return phone.replace(/(\d{2})(\d{4})/, '($1) $2-');
  }

  if (phone.length > 2) {
    return phone.replace(/(\d{2})/, '($1) ');
  }

  return phone;
}

export const getNumberForString = (data: string): string =>
  data.replace(/\D/g, '').trim();