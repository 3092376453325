import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import BreadCrumb from '../../../components/BreadCrumb';
import { CreateButtonDefaultContainer } from '../../../components/CreateButtonDefaultContainer';
import DefaultButton from '../../../components/DefaultButton';
import { DefaultPageTitle } from '../../../components/DefaultPageTitle';
import DefaultTable from '../../../components/DefaultTable';
import {
  getAllTags,
  deleteTag,
  verifyTag,
} from '../../../services/tags';
import { CategoriesContainer } from './style';
import Tag from '../../../models/tag';

const Tags: React.FC = () => {
  const history = useHistory();

  const [categories, setCategories] = useState([] as Tag[]);

  const getTags = async () => {
    const localTags = await getAllTags();

    if (localTags && localTags.length) {
      setCategories(localTags);
    }
  };

  const editCategory = useCallback(
    (categoryId: any) => {
      history.push(`edit-tag/${categoryId}`);
    },
    [history],
  );

  const removeCategory = useCallback(async (categoryId: any) => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: 'Tem certeza que deseja remover esta tag?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await deleteTag(categoryId);

          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Tag excluida com sucesso!',
          });

          await getTags();
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: `Erro ao excluir tag. ${
              error.response && error.response.status === 423
                ? 'Esta tag já está associada a um curso!'
                : error.message
            }`,
          });
        }
      }
    });
  }, []);

  const createCategory = async () => {
    history.push('create-tag');
  };

  const activateOrInactivateCategory = useCallback(
    async (categoryId: any, activate: boolean) => {
      Swal.fire({
        title: 'Confirmação',
        text: `Tem certeza que deseja ${
          activate ? 'ativar' : 'inativar'
        } esta tag?`,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        focusConfirm: false,
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await verifyTag(categoryId, activate);

            getTags();
          } catch (error) {
            Swal.fire({
              title: 'Erro',
              text: `Erro ao ${activate ? 'ativar' : 'inativar'} tag. ${
                error.response && error.response.status === 423
                  ? 'Esta tag já está associada a um curso!'
                  : error.message
              }`,
            });
          }
        }
      });
    },
    [],
  );

  const contentsToBeShown = useMemo(() => {
    return categories && categories.length
      ? categories.map(category => ({
          id: category.tag_id,
          title: category.description,
          active: category.verified ? <AiOutlineCheck /> : <AiOutlineClose />,
          actions: (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                gap: '5px',
              }}
            >
              <DefaultButton
                onClick={() => editCategory(category.tag_id)}
                className="small info"
                title="Editar Tag"
              >
                <BiEdit />
              </DefaultButton>{' '}
              <DefaultButton
                onClick={() => verifyTag(category?.tag_id, !category.verified)}
                className="small warning"
                title={(category.verified ? 'Inativar' : 'Ativar') + ' Tag'}
              >
                {category.verified ? <AiOutlineClose /> : <AiOutlineCheck />}
              </DefaultButton>{' '}
              <DefaultButton
                onClick={() => {
                  removeCategory(category.tag_id);
                }}
                className="small danger"
                title="Excluir Tag"
              >
                <BiTrash />
              </DefaultButton>
            </div>
          ),
        }))
      : [];
  }, [categories, editCategory, removeCategory, activateOrInactivateCategory]);

  useEffect(() => {
    getTags();
  }, []);

  return (
    <CategoriesContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Dashboard</Link>,
          <span>Fórum</span>,
          <span>Tags</span>,
        ]}
      />

      <DefaultPageTitle>Tags</DefaultPageTitle>

      <CreateButtonDefaultContainer>
        <DefaultButton onClick={createCategory}>Criar Tag</DefaultButton>
      </CreateButtonDefaultContainer>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Título</span>,
            propName: 'title',
            attributes: { style: { width: '35%' } },
          },
          {
            headerLabel: <span>Ativo</span>,
            propName: 'active',
            order: (localUsers: any[], currentOrder: string) => {
              setCategories([
                ...categories.sort((a, b) => {
                  const isDescendant =
                    currentOrder.includes('active') &&
                    currentOrder.includes('asc');
                  if (isDescendant) {
                    return a.verified ? 1 : -1;
                  } else {
                    return a.verified ? -1 : 1;
                  }
                }),
              ]);
            },
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={contentsToBeShown}
        emptyListMessage={'Não foram encontradas tags cadastradas!'}
      />
    </CategoriesContainer>
  );
};

export default Tags;
