import styled from 'styled-components';

const ExamTitle = styled.h2`
  color: var(--primary-color);
`;

const ExamDescription = styled.p`
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding: 15px 0;
`;

const ContainerPreview = styled.div``;

const QuestionTitle = styled.h3`
  padding: 10px 0;
`;

const QuestionDetails = styled.p`
  padding: 3px 0;
`;

export {
  ContainerPreview,
  QuestionTitle,
  QuestionDetails,
  ExamTitle,
  ExamDescription,
};
