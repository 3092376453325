import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import DefaultButton from '../../components/DefaultButton';
import DefaultInput from '../../components/DefaultInput';

import { signIn as signInService } from '../../services/signIn';

import Logo from '../../assets/logo.svg';
import { LoginContainer, LoginForm, LoginFormGroup } from './style';
import checkEmptyString from '../../helpers/check-empty-string';

const Login: React.FC = () => {
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');

  const history = useHistory();

  const login = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!checkEmptyString(user) && !checkEmptyString(password)) {
      await signInService({ registration: user, password }).then(() => {
        history.push('/profile');
      });
    } else {
      Swal.fire({
        title: 'Erro',
        text: 'Preencha todos os campos.',
        icon: 'error',
      });
    }
  };

  return (
    <LoginContainer>
      <LoginForm onSubmit={login}>
        <img src={Logo} alt="Portal construir" />
        <LoginFormGroup>
          <label htmlFor="login">Matrícula</label>
          <DefaultInput
            value={user}
            onChange={e => setUser(e.target.value)}
            required
            id="login"
            placeholder="Login"
          />
        </LoginFormGroup>
        <LoginFormGroup>
          <label htmlFor="senha">Senha</label>
          <DefaultInput
            value={password}
            onChange={e => setPassword(e.target.value)}
            required
            type="password"
            id="senha"
            placeholder="Senha"
          />
        </LoginFormGroup>

        <DefaultButton style={{ justifyContent: 'center' }} type="submit">
          Login
        </DefaultButton>
      </LoginForm>
    </LoginContainer>
  );
};

export default Login;
