import styled from "styled-components";

export const Container = styled.div`

header {
  font-size: var(--font-small);
  margin-bottom: 24px;
  color: var(--default-title-color);
  font-weight: 400;
}

footer {
  display: flex;
  gap: 8px;
  margin-top: 24px;
  justify-content: end;
}

`
