import httpClient from "../http-client";
import { uploadFile } from "./files";

const getCourse = async (courseId: string) => {
  const course = (await httpClient.get<any>(`courses/${courseId}`)).data;

  return course;
};

const getAllCourses = async (filters?: any) => {
  if (filters) {
    for (let param of Object.keys(filters)) {
      if (!filters[param]) {
        delete filters[param];
      }
    }
  }

  const courses = (await httpClient.get<any>("courses", { params: filters })).data;

  return courses;
};

const deleteCourse = async (courseId: string) => {
  await httpClient.delete<any>(`courses/${courseId}`);
};

const createCourse = async (newCourse: any) => {
  const createCourseResponse = (
    await httpClient.post<any>(`courses`, newCourse)
  ).data;

  return createCourseResponse;
};

const updateCourse = async (courseId: string, newCourse: any) => {
  await httpClient.put<any>(`courses/${courseId}`, newCourse);
};

const changeCourseThumbnail = async (courseId: string, formData: FormData) => {
  const uploadedFile = await uploadFile(formData);

  await httpClient.patch(`courses/${courseId}/thumbnail`, {
    thumbnail: uploadedFile.reference,
  });
};

const activateOrInactivateCourse = async (
  courseId: string,
  activate: boolean
) => {
  await httpClient.put<any>(`courses/${courseId}/active`, {
    is_active: activate,
  });
};

const getCoursesForSelect = async () => {
  const courses = (await httpClient.get<any>("courses/")).data;

  if (courses && courses.length) {
    return courses.map((course: any) => ({
      value: course.trail_id,
      label: course.description,
    }));
  }

  return [] as { value: string; label: string }[];
};

export {
  getAllCourses,
  deleteCourse,
  updateCourse,
  createCourse,
  getCourse,
  changeCourseThumbnail,
  activateOrInactivateCourse,
  getCoursesForSelect,
};
