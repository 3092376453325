import Lesson from "../../models/lesson";
import LessonFromReponse from "../../models/from-api-response/lesson";
import Content from "../../models/content";
import convertToContent from "./convert-content";

const convertToLesson = (lesson: LessonFromReponse): Lesson => {
  return {
    id: lesson.course_id,
    title: lesson.title,
    description: lesson.description,
    categories: lesson.categories,
    active: lesson.is_active,
    author: lesson.author,
    contents:
      lesson.contents && lesson.contents.length
        ? lesson.contents
            .sort((contentA, contentB) =>
              contentA.position &&
              contentB.position &&
              contentA.position > contentB.position
                ? 1
                : -1
            )
            .map((content) => convertToContent(content))
        : ([] as Content[]),
    thumbnail: lesson.thumbnail,
  };
};

export default convertToLesson;
