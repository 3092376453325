import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb';
import CutImage from '../../components/CutImage';
import DefaultButton from '../../components/DefaultButton';
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from '../../components/DefaultCreationForm';
import DefaultInput from '../../components/DefaultInput';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { UpdatedAtLabel } from '../../components/UpdatedAt';
import { DefaultTextArea } from '../../components/DefaultTextArea';
import { hideModal, showModal } from '../../helpers/modal';
import SelectCourse from './components/SelectCourse';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import {
  getCourse as getCourseService,
  updateCourse as updateCourseService,
  createCourse as createCourseService,
  changeCourseThumbnail as changeCourseThumbnailService,
  deleteCourse,
  changeTrailBanner,
  changeTrailVideoReference,
} from '../../services/trails';
import Swal from 'sweetalert2';
import checkEmptyString from '../../helpers/check-empty-string';
import Select from 'react-select';
import Category from '../../models/category';
import Author from '../../models/author';
import { getAllAuthors } from '../../services/authors';
import { ContentUploadOrSelectContainer } from '../CreateAndEditContent/style';
import MediaFromResponse from '../../models/from-api-response/media';
import SelectSambaVideosContent from '../../components/SelectSambaVideosContent';
import Course from '../../models/course';
import {
  ThumbnailUploadContainer,
  CourseThumbnail,
  SortLessonsContainer,
  LessonSelectionContainer,
  CreateAndEditSerieContainer,
  Switch,
} from './style';
import { getAllCategories } from '../../services/categories';
import {
  getSegmentation as getSegmentationService,
  getSegmentations,
} from '../../services/segmentation';
import Segmentation from '../Segmentation';
import { getExams } from '../../services/exams';
import Exam from '../../models/exam';
import getErrorMessage from '../../helpers/get-error-message';
import DatePicker from 'react-datepicker';
import addHours from '../../helpers/add-hours';

interface CreateAndEditSerieProps {
  serieId: string;
}

interface SegmentationsItems {
  segmentation_item_id: string;
  item: string;
}

const CreateAndEditSerie: React.FC = () => {
  const history = useHistory();
  const { serieId } = useParams<CreateAndEditSerieProps>();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [reference, setReference] = useState('');
  const [introductionDuration, setIntroductionDuration] = useState(0);
  const [selectedCourses, setSelectedCourses] = useState([] as Course[]);

  const [cargo, setCargo] = useState([] as SegmentationsItems[]);
  const [programa, setPrograma] = useState([] as SegmentationsItems[]);
  const [loja, setLoja] = useState([] as SegmentationsItems[]);

  const [thumbnailFileToUpload, setThumbnailFileToUpload] = useState<File>();
  const [thumbnail, setThumbnail] = useState('');

  const [selectedExam, setSelectedExam] = useState(
    {} as { label: string; value: string },
  );
  const [exams, setExams] = useState([] as Exam[]);

  const [categories, setCategories] = useState([] as Category[]);
  const [selectedCargo, setSelectedCargo] = useState(
    [] as unknown as { label: string; value: string }[],
  );
  const [selectedLoja, setSelectedLoja] = useState(
    [] as unknown as { label: string; value: string }[],
  );
  const [selectedProgram, setSelectedProgram] = useState(
    [] as unknown as { label: string; value: string }[],
  );
  const [selectedCategory, setSelectedCategory] = useState(
    {} as { label: string; value: string },
  );

  const [authors, setAuthors] = useState([] as Author[]);
  const [selectedAuthors, setSelectedAuthors] = useState(
    [] as { label: string; value: string }[],
  );

  const [bannerFileToUpload, setBannerFileToUpload] = useState<File>();
  const [banner, setBanner] = useState('');
  const [updatedAt, setUpdatedAt] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [validityDate, setValidityDate] = useState<Date | null>();
  const [isValidityDate, setIsValidityDate] = useState(false);
  const [generateCertificate, setGenerateCertificate] = useState(true);

  const onSelectContent = (sambaVideosContent: MediaFromResponse) => {
    if (sambaVideosContent) {
      if (sambaVideosContent.files && sambaVideosContent.files.length) {
        const firstFile = sambaVideosContent.files[0];

        if (firstFile) {
          const playerKey = process.env.REACT_APP_PLAYER_KEY;
          const referenceUrl = `${process.env.REACT_APP_PLAYER_INITIAL_URL}/${playerKey}/${sambaVideosContent.id}`;
          setReference(referenceUrl);
          setIntroductionDuration(firstFile.fileInfo.duration / 1000);
        }
      }
    }

    hideModal();
  };

  useEffect(() => {}, []);

  const createCourse = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(name)) {
        throw new Error('Informe um nome válido para a trilha.');
      }

      if (!selectedCourses || !selectedCourses.length) {
        throw new Error('Selecione um ou mais módulos antes.');
      }

      if (!reference) {
        throw new Error('Informe um vídeo introdutório.');
      }

      if (!thumbnail) {
        throw new Error('Selecione uma imagem para a capa antes.');
      }

      if (!Object.keys(selectedCategory).length) {
        throw new Error('Selecione uma categoria antes.');
      }

      if (validityDate && validityDate <= new Date()) {
        throw new Error('Informe uma data de vigência superior a data atual.');
      }

      const segmentation_items = [
        ...selectedCargo.map(s => ({ segmentation_item_id: s.value })),
        ...selectedLoja.map(s => ({ segmentation_item_id: s.value })),
        ...selectedProgram.map(s => ({ segmentation_item_id: s.value })),
      ];

      let createCourseData;

      if (segmentation_items.length) {
        createCourseData = {
          name: name,
          description: description.trim().length ? description : undefined,
          courses: selectedCourses.map((content, index) => ({
            course_id: content.course_id,
            position: index + 1,
          })),
          info: {
            video_reference_duration: introductionDuration,
            allow_user_file: isChecked,
            no_certificate: generateCertificate ? false : true,
          },
          authors:
            selectedAuthors && selectedAuthors.length
              ? selectedAuthors.map((a: any) => ({ author_id: a.value }))
              : undefined,
          categories: [{ category_id: selectedCategory.value }],
          video_reference: reference,
          segmentation_items: segmentation_items,
          exam_id:
            selectedExam && selectedExam.value ? selectedExam.value : undefined,
          deadline:
            isValidityDate && validityDate
              ? validityDate.toISOString()
              : undefined,
        };
      } else {
        createCourseData = {
          name: name,
          description: description.trim().length ? description : undefined,
          courses: selectedCourses.map((content, index) => ({
            course_id: content.course_id,
            position: index + 1,
          })),
          info: {
            video_reference_duration: introductionDuration,
            allow_user_file: isChecked,
            no_certificate: generateCertificate ? false : true,
          },
          authors:
            selectedAuthors && selectedAuthors.length
              ? selectedAuthors.map((a: any) => ({ author_id: a.value }))
              : undefined,
          categories: [{ category_id: selectedCategory.value }],
          video_reference: reference,
          exam_id:
            selectedExam && selectedExam.value ? selectedExam.value : undefined,
          deadline:
            isValidityDate && validityDate
              ? validityDate.toISOString()
              : undefined,
        };
      }
      const createdCourse = await createCourseService(createCourseData);

      if (thumbnailFileToUpload) {
        try {
          await changeCourseThumbnail(
            thumbnailFileToUpload,
            createdCourse.trail_id,
          );
        } catch (error) {
          await deleteCourse(createdCourse.trail_id);

          throw new Error(
            'Erro ao fazer upload da imagem de capa. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.',
          );
        }
      }

      if (bannerFileToUpload) {
        try {
          await changeSerieBanner(bannerFileToUpload, createdCourse.trail_id);
        } catch (error) {
          await deleteCourse(createdCourse.trail_id);

          throw new Error(
            'Erro ao fazer upload da imagem de capa. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.',
          );
        }
      }

      if (reference) {
        try {
          await changeTrailVideoReference(createdCourse.trail_id, reference);
        } catch (error) {
          const errorMessage = getErrorMessage(error);
          Swal.fire({
            icon: 'error',
            text: `Erro ao definir video introdutório. ${errorMessage}`,
          });
        }
      }

      Swal.fire({
        title: 'Sucesso!',
        text: 'Trilha criada com sucesso!',
        icon: 'success',
      });

      goToCourses();
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao criar a trilha. ' + error.message,
        icon: 'error',
      });
    }
  };

  const updateCourse = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(name)) {
        throw new Error('Informe um nome válido para a trilha.');
      }

      if (!selectedCourses || !selectedCourses.length) {
        throw new Error('Selecione um ou mais módulos antes.');
      }

      if (introductionDuration <= 0) {
        throw new Error('Por favor, selecione novamente o vídeo introdutório.');
      }

      if (!selectedCategory) {
        throw new Error('Selecione uma categoria antes.');
      }

      if (!reference) {
        throw new Error('Informe um vídeo introdutório.');
      }

      if (!thumbnail) {
        throw new Error('Selecione uma imagem para a capa antes.');
      }

      if (validityDate && validityDate <= new Date()) {
        throw new Error('Informe uma data de vigência superior a data atual.');
      }

      const segmentation_items = [
        ...selectedCargo.map(s => ({ segmentation_item_id: s.value })),
        ...selectedLoja.map(s => ({ segmentation_item_id: s.value })),
        ...selectedProgram.map(s => ({ segmentation_item_id: s.value })),
      ];

      let createCourseData;

      if (segmentation_items.length) {
        createCourseData = {
          name: name,
          description: (description || '').trim().length
            ? description
            : undefined,
          courses: selectedCourses.map((content, index) => ({
            course_id: content.course_id,
            position: index + 1,
          })),
          info: {
            video_reference_duration: introductionDuration,
            allow_user_file: isChecked,
            no_certificate: generateCertificate ? false : true,
          },
          authors:
            selectedAuthors && selectedAuthors.length
              ? selectedAuthors.map((a: any) => ({ author_id: a.value }))
              : undefined,
          categories: [{ category_id: selectedCategory.value }],
          segmentation_items: segmentation_items,
          video_reference: reference,
          exam_id:
            selectedExam && selectedExam.value ? selectedExam.value : undefined,
          deadline:
            isValidityDate && validityDate ? validityDate.toISOString() : null,
        };
      } else {
        createCourseData = {
          name: name,
          description: (description || '').trim().length
            ? description
            : undefined,
          courses: selectedCourses.map((content, index) => ({
            course_id: content.course_id,
            position: index + 1,
          })),
          info: {
            video_reference_duration: introductionDuration,
            allow_user_file: isChecked,
            no_certificate: generateCertificate ? false : true,
          },
          authors:
            selectedAuthors && selectedAuthors.length
              ? selectedAuthors.map((a: any) => ({ author_id: a.value }))
              : undefined,
          categories: [{ category_id: selectedCategory.value }],
          video_reference: reference,
          exam_id:
            selectedExam && selectedExam.value ? selectedExam.value : undefined,
          deadline:
            isValidityDate && validityDate ? validityDate.toISOString() : null,
        };
      }

      await updateCourseService(serieId, createCourseData);

      if (thumbnailFileToUpload) {
        try {
          await changeCourseThumbnail(thumbnailFileToUpload, serieId);
        } catch (error) {
          throw new Error(
            'Erro ao fazer upload da imagem de capa. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.',
          );
        }
      }

      if (bannerFileToUpload) {
        try {
          await changeSerieBanner(bannerFileToUpload, serieId);
        } catch (error) {
          throw new Error(
            'Erro ao fazer upload da imagem do banner. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.',
          );
        }
      }

      if (reference) {
        try {
          await changeTrailVideoReference(serieId, reference);
        } catch (error) {
          const errorMessage = getErrorMessage(error);
          Swal.fire({
            icon: 'error',
            text: `Erro ao definir video introdutório. ${errorMessage}`,
          });
        }
      }

      Swal.fire({
        title: 'Sucesso!',
        text: 'Trilha editada com sucesso!',
        icon: 'success',
      });

      goToCourses();
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao editar a trilha. ' + error.message,
        icon: 'error',
      });
    }
  };

  const changeCourseThumbnail = async (
    localThumbnailFileToUpload: File,
    localSerieId: string,
  ) => {
    const formData = new FormData();
    formData.append('file', localThumbnailFileToUpload);
    formData.append(
      'name',
      `course_thumbnail_${localSerieId}.${localThumbnailFileToUpload.type}`,
    );
    formData.append('description', `thumbnail da trilha de id ${localSerieId}`);

    await changeCourseThumbnailService(localSerieId, formData);
  };

  const changeSerieBanner = async (
    localBannerFileToUpload: File,
    localSerieId: string,
  ) => {
    const formData = new FormData();
    formData.append('file', localBannerFileToUpload);
    formData.append(
      'name',
      `content_thumbnail_${localSerieId}.${localBannerFileToUpload.type}`,
    );
    formData.append(
      'description',
      `thumbnail do conteúdo de id ${localSerieId}`,
    );

    await changeTrailBanner(localSerieId, formData);
  };

  const selectCourses = () => {
    showModal(
      'Selecionar',
      <SelectCourse
        onApplySelection={applyCoursesSelection}
        selectedCourses={[...selectedCourses]}
      />,
    );
  };

  const selectContent = () => {
    showModal(
      'Selecionar Conteúdo',
      <SelectSambaVideosContent onSelectContent={onSelectContent} />,
    );
  };

  const selectThumbnail = () => {
    showModal(
      'Selecionar Imagem de Capa',
      <CutImage aspect={1.812} onCutImage={onCutImage} />,
    );
  };

  const selectBanner = () => {
    showModal(
      'Selecionar Imagem do Destaque',
      <CutImage aspect={1.812} onCutImage={onCutBanner} />,
    );
  };

  const onCutImage = (file: File) => {
    if (file) {
      setThumbnailFileToUpload(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setThumbnail(`${reader.result}`);

      hideModal();
    }
  };

  const onCutBanner = (file: File) => {
    if (file) {
      setBannerFileToUpload(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setBanner(`${reader.result}`);

      hideModal();
    }
  };

  const applyCoursesSelection = (localSelectedCourses: Course[]) => {
    hideModal();

    if (localSelectedCourses) {
      setSelectedCourses(localSelectedCourses);
    }
  };

  const reorderSelectedCourses = (lesson: Course, up: boolean) => {
    if (selectedCourses) {
      const localSelectedCourses = [...selectedCourses];
      const indexOfLesson = localSelectedCourses.indexOf(lesson);
      if (indexOfLesson > -1 && indexOfLesson < localSelectedCourses.length) {
        localSelectedCourses.splice(indexOfLesson, 1);
        if (up) {
          localSelectedCourses.splice(indexOfLesson - 1, 0, lesson);
        } else {
          localSelectedCourses.splice(indexOfLesson + 1, 0, lesson);
        }
      }

      setSelectedCourses(localSelectedCourses);
    }
  };

  const getCourse = useCallback(async () => {
    const foundAuthors = await getAllAuthors();
    const foundCategories = await getAllCategories({ is_active: true });
    const foundSegmentations = await getSegmentations();
    const foundExams = await getExams();

    setExams((foundExams || []).filter(exam => exam.enabled));
    if (foundAuthors) {
      setAuthors(foundAuthors);
    }
    if (foundCategories) {
      setCategories(foundCategories);
    }

    if (foundSegmentations) {
      foundSegmentations.map((segmentation: any) => {
        if (segmentation.type === 'Cargo') {
          setCargo(segmentation.items);
        }
        if (segmentation.type === 'Unidade') {
          setLoja(segmentation.items);
        }
        if (segmentation.type === 'Programa') {
          setPrograma(segmentation.items);
        }
      });
    }
    if (serieId && serieId !== 'as') {
      const course = await getCourseService(serieId);

      if (course && Object.keys(course).length) {
        setName(course.trail.name);

        if (course.trail.deadline) {
          setIsValidityDate(true);
          setValidityDate(new Date(course.trail.deadline));
        }
        setDescription(course.trail.description);
        setSelectedCourses(
          course.trail.courses && course.trail.courses.length
            ? course.trail.courses.sort((a: any, b: any) =>
                a.position > b.position ? 1 : -1,
              )
            : [],
        );
        setThumbnail(course.trail.thumbnail);
        setReference(course.trail.video_reference);
        setBanner(course.trail.info && course.trail.info.banner);
        setGenerateCertificate(course.trail.info.no_certificate ? false : true);

        if (course.trail.info && course.trail.info.video_reference_duration) {
          setIntroductionDuration(course.trail.info.video_reference_duration);
        }

        if (course.trail?.updated_at) {
          let date = new Date(course.trail.updated_at);
          setUpdatedAt(date.toLocaleString());
        }

        if (
          course.trail.segmentations &&
          course.trail.segmentations[0] !== null
        ) {
          course.trail.segmentations.map((segmentation: any) => {
            if (segmentation.type === 'Cargo') {
              setSelectedCargo(
                segmentation.items.map((segItem: any) => ({
                  label: segItem.item,
                  value: segItem.segmentation_item_id,
                })),
              );
            }
            if (segmentation.type === 'Programa') {
              setSelectedProgram(
                segmentation.items.map((segItem: any) => ({
                  label: segItem.item,
                  value: segItem.segmentation_item_id,
                })),
              );
            }
            if (segmentation.type === 'Unidade') {
              setSelectedLoja(
                segmentation.items.map((segItem: any) => ({
                  label: segItem.item,
                  value: segItem.segmentation_item_id,
                })),
              );
            }
          });
        }

        const courseExam =
          typeof course.trail.exam === 'object'
            ? (foundExams || []).find(
                (exam: any) => exam.id === course.trail.exam.examId,
              ) || ({} as Exam)
            : (foundExams || []).find(
                (exam: any) => exam.id === course.trail.exam,
              ) || ({} as Exam);

        if (courseExam) {
          setSelectedExam({ label: courseExam.title, value: courseExam.id });
        }

        setSelectedCategory({
          label: course.trail.category.title,
          value: course.trail.category.id,
        });
        setSelectedAuthors(
          course.trail && course.trail.authors && course.trail.authors.length
            ? course.trail.authors.map((a: any) => ({
                label: a.name,
                value: a.id,
              }))
            : [],
        );
      }
    }
  }, [serieId]);

  const cargoToBeSelected = useMemo(() => {
    if (cargo && cargo.length) {
      return cargo.map(cargo => ({
        label: cargo.item,
        value: `${cargo.segmentation_item_id}`,
      }));
    }

    return [] as { label: string; value: string }[];
  }, [cargo]);

  const programaToBeSelected = useMemo(() => {
    if (programa && programa.length) {
      return programa.map(programa => ({
        label: programa.item,
        value: `${programa.segmentation_item_id}`,
      }));
    }

    return [] as { label: string; value: string }[];
  }, [programa]);

  const lojaToBeSelected = useMemo(() => {
    if (loja && loja.length) {
      return loja.map(loja => ({
        label: loja.item,
        value: `${loja.segmentation_item_id}`,
      }));
    }

    return [] as { label: string; value: string }[];
  }, [loja]);

  const authorsToBeSelected = useMemo(() => {
    if (authors && authors.length) {
      return authors.map(author => ({
        label: author.name,
        value: `${author.author_id}`,
      }));
    }

    return [] as { label: string; value: string }[];
  }, [authors]);

  const categoriesToBeSelected = useMemo(() => {
    if (categories && categories.length) {
      return categories.map(category => ({
        label: category.title,
        value: `${category.category_id}`,
      }));
    }

    return [] as { label: string; value: string }[];
  }, [categories]);

  const examsToBeSelected = useMemo(() => {
    let examsToBeReturned = [
      { label: 'Selecione uma prova para este curso', value: '' },
    ] as {
      label: string;
      value: string;
    }[];

    if (exams && exams.length) {
      examsToBeReturned.push(
        ...exams.map(exam => ({
          label: exam.title,
          value: `${exam.id}`,
        })),
      );
    }

    return examsToBeReturned;
  }, [exams]);

  const goToCourses = () => {
    history.push('/trails/series');
  };

  useEffect(() => {
    getCourse();
  }, [getCourse]);
  useEffect(() => {
    setGenerateCertificate(true);
  }, [selectedExam.value]);
  const isEditting = useMemo(() => {
    if (serieId) {
      return true;
    }

    return false;
  }, [serieId]);

  return (
    <CreateAndEditSerieContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Dashboard</Link>,
          <Link to="/trails/series">Trilhas</Link>,
          <span>{isEditting ? 'Editar' : 'Criar'} Trilha</span>,
        ]}
      />

      <DefaultPageTitle>
        {isEditting ? 'Editar' : 'Criar'} Trilha
        {updatedAt && (
          <UpdatedAtLabel> - Última atualização em {updatedAt}</UpdatedAtLabel>
        )}
      </DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">
            Título
          </label>
          <DefaultInput
            value={name}
            onChange={e => setName(e.target.value)}
            id="title"
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="description">Descrição</label>
          <DefaultTextArea
            value={description || ''}
            onChange={e => setDescription(e.target.value)}
            id="description"
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="reference">
            Módulos
          </label>

          <LessonSelectionContainer>
            {selectedCourses && selectedCourses.length ? (
              <SortLessonsContainer>
                {selectedCourses.map((lesson, index) => (
                  <div key={lesson.course_id} className="selected-lessons">
                    <div className="buttons">
                      {index > 0 ? (
                        <DefaultButton
                          type="button"
                          title="Subir Aula"
                          className="small white up"
                          onClick={() => reorderSelectedCourses(lesson, true)}
                        >
                          <AiOutlineUp />
                        </DefaultButton>
                      ) : (
                        <></>
                      )}
                      {index < selectedCourses.length - 1 ? (
                        <DefaultButton
                          type="button"
                          title="Descer Aula"
                          className="small white down"
                          onClick={() => reorderSelectedCourses(lesson, false)}
                        >
                          <AiOutlineDown />
                        </DefaultButton>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="lesson-title">
                      {index + 1} - {lesson.title}
                    </div>
                  </div>
                ))}
              </SortLessonsContainer>
            ) : (
              <></>
            )}
            <DefaultButton type="button" onClick={selectCourses}>
              Selecionar
            </DefaultButton>
          </LessonSelectionContainer>
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="reference" className="required">
            Capa
          </label>

          <ThumbnailUploadContainer>
            <DefaultButton type="button" onClick={selectThumbnail}>
              Selecionar Imagem de Capa
            </DefaultButton>

            {thumbnail && <CourseThumbnail src={thumbnail} />}
          </ThumbnailUploadContainer>
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="reference">
            Vídeo Introdutório
          </label>

          <ContentUploadOrSelectContainer>
            <DefaultButton type="button" onClick={selectContent}>
              Selecionar Conteúdo
            </DefaultButton>
            {reference ? (
              <iframe
                title="referenced-video"
                allowFullScreen
                src={reference}
                frameBorder={0}
              ></iframe>
            ) : (
              <></>
            )}
          </ContentUploadOrSelectContainer>
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label>Permitir o envio de arquivos por parte do usuário?</label>
          <Switch style={{ width: '50px' }}>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={() => {
                setIsChecked(oldValue => !oldValue);
              }}
            />
            <span className="slider round"></span>
          </Switch>
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="exam">Prova</label>
          <Select
            styles={{
              container: provided => ({
                ...provided,
                flexGrow: 1,
                width: '100%',
              }),
            }}
            options={examsToBeSelected}
            value={selectedExam}
            onChange={option =>
              option &&
              setSelectedExam(() => ({
                label: option.label,
                value: option.value,
              }))
            }
            id="exam"
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="tags">Autores</label>
          <Select
            isMulti
            styles={{
              container: provided => ({
                ...provided,
                flexGrow: 1,
                width: '100%',
              }),
            }}
            options={authorsToBeSelected}
            value={selectedAuthors}
            onChange={options => {
              options &&
                !window.isNaN(options.length) &&
                setSelectedAuthors(
                  options.map(o => ({
                    label: o.label,
                    value: o.value,
                  })) as any,
                );
            }}
            id="authors"
            placeholder="Selecione um autor para esta série."
          />
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="tags">
            Categoria
          </label>
          <Select
            styles={{
              container: provided => ({
                ...provided,
                flexGrow: 1,
                width: '100%',
              }),
            }}
            options={categoriesToBeSelected}
            value={selectedCategory}
            onChange={options => {
              options &&
                setSelectedCategory({
                  label: options.label,
                  value: options.value,
                });
            }}
            id="categories"
            placeholder="Selecione uma categoria para este conteúdo."
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="tags">Cargo</label>
          <Select
            styles={{
              container: provided => ({
                ...provided,
                flexGrow: 1,
                width: '100%',
              }),
            }}
            options={cargoToBeSelected}
            value={selectedCargo}
            onChange={(options: any) =>
              options &&
              !window.isNaN(options.length) &&
              setSelectedCargo(
                options.map((opt: any) => ({
                  label: opt.label,
                  value: opt.value,
                })),
              )
            }
            isMulti
            id="categories"
            placeholder="Selecione os cargos que poderão visualizar a trilha."
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="tags">Unidade</label>
          <Select
            styles={{
              container: provided => ({
                ...provided,
                flexGrow: 1,
                width: '100%',
              }),
            }}
            options={lojaToBeSelected}
            value={selectedLoja}
            onChange={(options: any) =>
              options &&
              !window.isNaN(options.length) &&
              setSelectedLoja(
                options.map((opt: any) => ({
                  label: opt.label,
                  value: opt.value,
                })),
              )
            }
            isMulti
            id="categories"
            placeholder="Selecione as lojas que poderão visualizar a trilha."
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="tags">Programa</label>
          <Select
            styles={{
              container: provided => ({
                ...provided,
                flexGrow: 1,
                width: '100%',
              }),
            }}
            options={programaToBeSelected}
            value={selectedProgram}
            onChange={(options: any) =>
              options &&
              !window.isNaN(options.length) &&
              setSelectedProgram(
                options.map((opt: any) => ({
                  label: opt.label,
                  value: opt.value,
                })),
              )
            }
            isMulti
            id="categories"
            placeholder="Selecione os programas que poderão visualizar a trilha."
          />
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label>A trilha terá vigência definida?</label>
          <Switch style={{ width: '50px' }}>
            <input
              type="checkbox"
              checked={isValidityDate}
              onChange={() => {
                setIsValidityDate(oldValue => !oldValue);
              }}
            />
            <span className="slider round"></span>
          </Switch>
        </DefaultCreationFormGroup>
        {isValidityDate ? (
          <DefaultCreationFormGroup>
            <label htmlFor="endDate">Vigência:</label>
            <DatePicker
              selected={validityDate}
              onChange={date =>
                date && date instanceof Date && setValidityDate(date)
              }
              onKeyDown={event => event.preventDefault()}
              minDate={addHours(new Date(), 1)}
              dateFormat="dd/MM/yyyy"
            />
          </DefaultCreationFormGroup>
        ) : (
          ''
        )}
        <DefaultCreationFormGroup
          style={selectedExam.value ? { display: 'none' } : { display: '' }}
        >
          <label>Essa trilha irá gerar certificado?</label>
          <Switch style={{ width: '50px' }}>
            <input
              type="checkbox"
              checked={generateCertificate}
              onChange={() => {
                setGenerateCertificate(oldValue => !oldValue);
              }}
            />
            <span className="slider round"></span>
          </Switch>
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton type="button" className="danger" onClick={goToCourses}>
            Cancelar
          </DefaultButton>
          <DefaultButton
            onClick={e => (isEditting ? updateCourse(e) : createCourse(e))}
            className="success"
          >
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditSerieContainer>
  );
};

export default CreateAndEditSerie;
