import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone'
import { ProgressBarContainer, UploadScormContainer } from './style';
import DefaultButton from '../../../../components/DefaultButton';
import { IFile } from './IScormCourses';
import UploadProgressBar from '../../../../components/SelectSambaVideosContent/components/UploadProgressBar';
import Swal from 'sweetalert2';
import { getSignedUrl } from '../../../../services/files';

interface IPropsUploadScorm {
  setReference: React.Dispatch<React.SetStateAction<string>>
}

const UploadScorm: React.FC<IPropsUploadScorm> = ({
  setReference
}) => {
    const [uploadProgress, setUploadProgress] = useState(0);

    function InputDropzone(props: any) {
        const onDrop = useCallback(async (acceptedFiles) => {
          let pathIndex: string = "";

          Swal.fire({
            title: "Iniciando Upload",
            text: "Upload de conteúdo iniciado!",
            timer: 1000,
          });
          
          let countFilesUpload: number = 0;
          acceptedFiles.forEach(async (file: IFile, index: number) => {
            let contentType: string = file.type;
            if(!contentType){
              contentType = file.path.split(".")[1] === 'xsd' ? 'application/xml' : 'application/octet-stream' ;
            }
      
            if(file.path.includes("/res/index.html") || file.path.includes("index.html")){
              pathIndex = `${process.env.REACT_APP_ASSETS_SCORM}/${encodeURI(file.path)}`
            }
            
            const { uploadURL } = await getSignedUrl(contentType, file.path);
            const reader = new FileReader();
      
            reader.onload = async (data: any) => {
              const dataFileUrl = data.target.result;
              let binary = atob(dataFileUrl.split(',')[1])
              let array = []
              for (let i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i))
              }
              let blobData = new Blob([new Uint8Array(array)], {type: contentType})
      
              await fetch(uploadURL, {
                method: 'PUT',
                body: blobData,
                headers: {
                  'Access-Control-Allow-Origin': '*',
                }
              });
              countFilesUpload = countFilesUpload + 1;
              const progress = (countFilesUpload / acceptedFiles.length) * 100;
              setUploadProgress(progress);
              if(countFilesUpload === acceptedFiles.length){
                setReference(pathIndex);
                setUploadProgress(0);
                Swal.fire({
                  title: "Sucesso!",
                  icon: "success",
                  text: "Upload do scorm realizado com sucesso!"
                });
              }
            }
            reader.readAsDataURL(file);
          });
        }, [])

        const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
      
        return (
          <div {...getRootProps()}>
            <input {...getInputProps()} directory="" webkitdirectory="" type="file" {...props}/>
            {
              isDragActive ?
                <DefaultButton type="button">Solte o diretório aqui!!</DefaultButton> :
                <DefaultButton type="button">Arraste o diretório que contém os arquivos scorms ou clique aqui e selecione-o.</DefaultButton>
            }
          </div>
        )
    }

  return(
      <UploadScormContainer>
            <InputDropzone />
            {
              uploadProgress > 0 ? 
                (
                  <ProgressBarContainer> 
                    <UploadProgressBar progress={uploadProgress} /> 
                  </ProgressBarContainer>
                )
              : <></>
            } 
      </UploadScormContainer>
  )
};

export default UploadScorm;
