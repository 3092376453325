import React, { useEffect, useMemo, useState } from "react";

import { v4 as uuidv4 } from "uuid";
import { Link, useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import BreadCrumb from "../../components/BreadCrumb";
import DefaultButton from "../../components/DefaultButton";
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
} from "../../components/DefaultCreationForm";
import { BiTrash } from "react-icons/bi";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import {
  CreateAndEditCategoryContainer,
  AddItemButton,
  TrashButton,
} from "./style";
import checkEmptyString from "../../helpers/check-empty-string";
import {
  getSegmentation as getSegmentationService,
  removeSegmentation as removeSegmentationItemService,
  createSegmentation as createSegmentationService,
  updateSegmentation as updateSegmentationService,
  getSegmentations,
} from "../../services/segmentation";
import Datatable from "../../components/Datatable";

interface CreateAndEditCategoryProps {
  segmentationId: string;
}

interface ItemsToBeAdded {
  id: string;
  value: string;
}

interface Item {
  segmentation_item_id: string;
  item: any;
}

const CreateAndEditSegmentation = () => {
  const [itemsToBeChanged, setItemsToBeChanged] = useState(
    [] as ItemsToBeAdded[]
  );
  const [itemsToBeAdded, setItemsToBeAdded] = useState([] as ItemsToBeAdded[]);
  const [
    shouldShowSegmentationItemsTable,
    setShouldShowSegmentationItemsTable,
  ] = useState(false);
  const history = useHistory();

  const { segmentationId } = useParams<CreateAndEditCategoryProps>();
  const [segmentationItems, setSegmentationItems] = useState([] as Item[]);

  const getSegmentation = async () => {
    const localSegmentationItems = await getSegmentationService(segmentationId);

    setShouldShowSegmentationItemsTable(false);
    setSegmentationItems(localSegmentationItems || []);
  };

  useEffect(() => {
    getSegmentation();
  }, [getSegmentations]);

  const goToSegmentations = () => {
    history.push("/segmentation");
  };

  const saveChanges = async () => {
    try {
      if (itemsToBeAdded && itemsToBeAdded.length) {
        try {
          if (itemsToBeAdded.some((item) => checkEmptyString(item.value))) {
            throw new Error(
              "Informe uma descrição válida para os itens de segmentação!"
            );
          }

          await createSegmentationService(segmentationId, {
            segmentation_items: itemsToBeAdded.map((item) => ({
              item: item.value,
            })),
          });

          setItemsToBeAdded([]);
        } catch (error) {
          const errorMessage = error.message;
          throw new Error(
            `Erro ao criar itens de segmentação. ${errorMessage}`
          );
        }
      }

      if (itemsToBeChanged && itemsToBeChanged.length) {
        for (let segItem of itemsToBeChanged) {
          try {
            await updateSegmentationService(segItem.id, {
              item: segItem.value,
            });
          } catch (error) {
            const errorMessage = error.message;
            throw new Error(
              `Erro ao atualizar segmentação "${segItem.value}". ${errorMessage}`
            );
          }
        }
      }

      await getSegmentation();
    } catch (error) {
      const errorMessage = error.message;

      Swal.fire({ icon: "error", text: errorMessage });
    }
  };

  const handleChange = (
    segmentationDescription: string,
    segmentationItemId: string
  ) => {
    if (!itemsToBeChanged.some((item) => item.id === segmentationItemId)) {
      if (
        segmentationItems.some(
          (item) => item.segmentation_item_id === segmentationItemId
        )
      ) {
        itemsToBeChanged.push({
          id: segmentationItemId,
          value: segmentationDescription,
        });
      } else {
        const foundItemToCreate = itemsToBeAdded.find(
          (item) => item.id === segmentationItemId
        );
        if (foundItemToCreate) {
          const indexOfFoundItemToCreate = itemsToBeAdded.indexOf(
            foundItemToCreate
          );
          if (
            indexOfFoundItemToCreate > -1 &&
            indexOfFoundItemToCreate < itemsToBeAdded.length
          ) {
            itemsToBeAdded.splice(indexOfFoundItemToCreate, 1, {
              id: segmentationItemId,
              value: segmentationDescription,
            });
          }
        }

        setItemsToBeAdded([...itemsToBeAdded]);
      }
    } else {
      const foundItemToBeChanged = itemsToBeChanged.find(
        (item) => item.id === segmentationItemId
      );
      if (foundItemToBeChanged) {
        foundItemToBeChanged.value = segmentationDescription;
      }
    }

    setItemsToBeChanged([...itemsToBeChanged]);
  };

  const addItem = () => {
    setShouldShowSegmentationItemsTable(false);
    itemsToBeAdded.push({ id: uuidv4(), value: "" });
    setItemsToBeAdded([...itemsToBeAdded]);
  };

  const removeItem = (segmentationItemId: string) => {
    Swal.fire({
      icon: "question",
      text: "Certeza que deseja remover este item de segmentação?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (
          segmentationItems.some(
            (item) => item.segmentation_item_id === segmentationItemId
          )
        ) {
          try {
            await removeSegmentationItemService(segmentationItemId);

            await getSegmentation();
          } catch (error) {
            let errorMessage = error.message;

            if (
              error.response &&
              error.response.data &&
              (error.response.data.message || "").includes("enrolled")
            ) {
              errorMessage =
                "Este item de segmentação não pode ser removido, pois, está associado a um conteúdo.";
            }

            Swal.fire({
              icon: "error",
              text: `Erro ao remover item de segmentação. ${errorMessage}`,
            });
          }
        } else {
          const foundItemToCreate = itemsToBeAdded.find(
            (item) => item.id === segmentationItemId
          );
          if (foundItemToCreate) {
            const indexOfFoundItemToCreate = itemsToBeAdded.indexOf(
              foundItemToCreate
            );
            if (
              indexOfFoundItemToCreate > -1 &&
              indexOfFoundItemToCreate < itemsToBeAdded.length
            ) {
              itemsToBeAdded.splice(indexOfFoundItemToCreate, 1);
            }
          }

          setItemsToBeAdded([...itemsToBeAdded]);
        }
      }
    });
  };

  const segmentationItemsToBeShown = useMemo(() => {
    let localSegmentationItems = [];
    if (segmentationItems && segmentationItems.length) {
      localSegmentationItems.push(
        ...segmentationItems.map((segItem) => {
          const foundSegItemToChange: any = itemsToBeChanged.find(
            (item) => item.id === segItem.segmentation_item_id
          );

          return {
            descrição: (
              <textarea
                style={{ width: "100%" }}
                defaultValue={
                  foundSegItemToChange
                    ? foundSegItemToChange.value
                    : segItem.item
                }
                onChange={(event) =>
                  handleChange(event.target.value, segItem.segmentation_item_id)
                }
              ></textarea>
            ),
            ações: (
              <>
                <TrashButton
                  onClick={() => removeItem(segItem.segmentation_item_id)}
                >
                  <BiTrash size={18} />
                </TrashButton>
              </>
            ),
          };
        })
      );
    }

    if (itemsToBeAdded && itemsToBeAdded.length) {
      localSegmentationItems.push(
        ...itemsToBeAdded.map((segItem) => ({
          descrição: (
            <textarea
              style={{ width: "100%" }}
              defaultValue={segItem.value}
              onChange={(event) => handleChange(event.target.value, segItem.id)}
            ></textarea>
          ),
          ações: (
            <>
              <TrashButton onClick={() => removeItem(segItem.id)}>
                <BiTrash size={18} />
              </TrashButton>
            </>
          ),
        }))
      );
    }

    process.nextTick(() => {
      setShouldShowSegmentationItemsTable(true);
    });

    return localSegmentationItems;
  }, [segmentationItems, itemsToBeAdded]);

  return (
    <CreateAndEditCategoryContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Dashboard</Link>,
          <Link to="/categories">Segmentação</Link>,
          <span>Editar Segmentação</span>,
        ]}
      />

      <DefaultPageTitle>Editar Segmentação</DefaultPageTitle>

      <div>
        {shouldShowSegmentationItemsTable ? (
          <Datatable
            myData={segmentationItemsToBeShown}
            className=""
            pagination={false}
            pageSize={segmentationItemsToBeShown.length}
            customColumns={[
              {
                Header: "Descrição",
                accessor: "descrição",
              },
              {
                Header: "Ações",
                accessor: "ações",
                width: 150,
              },
            ]}
          />
        ) : (
          <></>
        )}
      </div>

      <DefaultCreationForm>
        <AddItemButton>
          <DefaultButton type="button" onClick={addItem} className="info">
            Criar Item
          </DefaultButton>
        </AddItemButton>

        <DefaultCreationFormButtonGroup>
          <DefaultButton
            type="button"
            className="danger"
            onClick={goToSegmentations}
          >
            Cancelar
          </DefaultButton>
          <DefaultButton
            type="button"
            onClick={saveChanges}
            className="success"
          >
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditCategoryContainer>
  );
};

export default CreateAndEditSegmentation;
