import httpClient from '../http-client';
import download from 'downloadjs';

const generateEngagementReport = async (
  format: string,
  queryString: string,
) => {
  const reportResponse = await httpClient.get(`reports/engagement/${format}`, {
    params: queryString,
    responseType: 'blob',
  });

  const contentType = reportResponse.headers['content-type'];

  download(reportResponse.data, `relatório_engajamento.${format}`, contentType);
};

const generateEngagementReportTrail = async (
  format: string,
  queryString: string,
) => {
  const reportResponse = await httpClient.get(
    `/reports/engagementTrail/${format}`,
    {
      params: queryString,
      responseType: 'blob',
    },
  );

  const contentType = reportResponse.headers['content-type'];

  download(reportResponse.data, `relatório_engajamento.${format}`, contentType);
};

const generateEngagementReportTestimonial = async (
  format: string,
  queryString: string,
  type: string,
) => {
  const reportResponse = await httpClient.get(
    `/reports/testimonial/${format}/${type}`,
    {
      params: queryString,
      responseType: 'blob',
    },
  );

  const contentType = reportResponse.headers['content-type'];

  download(reportResponse.data, `relatório_engajamento.${format}`, contentType);
};

const generateAccessesReport = async (format: string, queryString: string) => {
  const reportResponse = await httpClient.get(`reports/user-access/${format}`, {
    params: queryString,
    responseType: 'blob',
  });

  const contentType = reportResponse.headers['content-type'];

  download(reportResponse.data, `relatório_acessos.${format}`, contentType);
};

const generateExamsReport = async (format: string, queryString: string) => {
  const reportResponse = await httpClient.get(
    `/reports/exam-performance/${format}`,
    {
      params: queryString,
      responseType: 'blob',
    },
  );

  const contentType = reportResponse.headers['content-type'];

  download(reportResponse.data, `relatório_provas.${format}`, contentType);
};

export {
  generateEngagementReport,
  generateEngagementReportTrail,
  generateEngagementReportTestimonial,
  generateAccessesReport,
  generateExamsReport,
};
