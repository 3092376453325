import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { BiDownload, BiTrash } from "react-icons/bi";
import Swal from "sweetalert2";
import BreadCrumb from "../../components/BreadCrumb";
import DefaultButton from "../../components/DefaultButton";
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from "../../components/DefaultCreationForm";
import {
  createExtraMaterial as createExtraMaterialService,
  deleteExtraMaterial as deleteExtraMaterialService,
} from "../../services/extra-materials";
import DefaultInput from "../../components/DefaultInput";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import { UpdatedAtLabel } from '../../components/UpdatedAt';
import { DefaultTextArea } from "../../components/DefaultTextArea";
import SelectSambaVideosContent from "../../components/SelectSambaVideosContent";
import { hideModal, showModal } from "../../helpers/modal";
import MediaFromResponse from "../../models/from-api-response/media";
import {
  ContentThumbnail,
  ContentUploadOrSelectContainer,
  CreateAndEditContentContainer,
  ThumbnailUploadContainer,
  Switch,
  ExtraMaterial,
  ExtraMaterialList,
  ExtraMaterialsInput,
} from "./style";
import {
  changeContentThumbnail as changeContentThumbnailService,
  createContent as createContentService,
  deleteContent,
  getContent as getContentService,
  updateContent as updateContentService,
} from "../../services/contents-trail";
import CutImage from "../../components/CutImage";
import checkEmptyString from "../../helpers/check-empty-string";
import Select from "react-select";
import Author from "../../models/author";
import { getAllAuthors } from "../../services/authors";
import { uploadFile } from "../../services/files";
import DefaultSwitch from "../../components/DefaultSwitch";
import UploadScorm from "../CreateAndEditContent/components/UploadScorm";

interface CreateAndEditContentProps {
  contentId: string;
}

const CreateAndEditContent: React.FC = () => {
  const inputRef = useRef<any>(null);
  const [extraMaterials, setExtraMaterials] = useState<any>([] as any[]);
  const [
    extraMaterialsToBeDeleted,
    setExtraMaterialsToBeDeleted,
  ] = useState<any>([] as any[]);
  const { contentId } = useParams<CreateAndEditContentProps>();

  const history = useHistory();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [reference, setReference] = useState("");
  const [authors, setAuthors] = useState(("" as unknown) as Author[]);
  const [duration, setDuration] = useState(0);
  const [type, setType] = useState("");
  const [thumbnailFileToUpload, setThumbnailFileToUpload] = useState<File>();
  const [thumbnail, setThumbnail] = useState("");
  const [switchState, setSwitchState] = useState<string>("video");
  const [updatedAt, setUpdatedAt] = useState("")

  const onSelectContent = (sambaVideosContent: MediaFromResponse) => {
    if (sambaVideosContent) {
      if (sambaVideosContent.files && sambaVideosContent.files.length) {
        const firstFile = sambaVideosContent.files[0];

        if (firstFile) {
          const playerKey = process.env.REACT_APP_PLAYER_KEY;
          const referenceUrl = `${process.env.REACT_APP_PLAYER_INITIAL_URL}/${playerKey}/${sambaVideosContent.id}`;

          setReference(referenceUrl);
          setDuration(firstFile.fileInfo.duration / 1000);
          setType(firstFile.qualifier);
        }
      }
    }

    hideModal();
  };

  const createContent = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(title)) {
        throw new Error("Informe um título válido para o conteúdo.");
      }

      if (!reference) {
        throw new Error("Selecione um conteúdo antes.");
      }

      const createdContent = await createContentService({
        title: title,
        description: description.trim().length ? description : undefined,
        duration: switchState === 'scorm' ? 0 : duration,
        reference: reference,
        type: switchState === 'scorm' ? switchState.toUpperCase() : type.toLocaleUpperCase(),
        is_library: false,
      });

      for (let material of extraMaterialsToBeDeleted) {
        await deleteExtraMaterialService(material.id);
      }

      let uploadedMaterials = [] as { filename: string; reference: string }[];

      if (extraMaterials && extraMaterials.length >= 1) {
        for (let material of extraMaterials.filter(
          (extraMaterial: any) => !extraMaterial.alreadySaved
        )) {
          const formData = new FormData();
          formData.append("file", material);

          await uploadFile(formData).then(({ reference, name }) =>
            uploadedMaterials.push({ reference, filename: name })
          );
        }
      }

      if (uploadedMaterials.length >= 1) {
        await createExtraMaterialService(
          createdContent.content_id,
          uploadedMaterials
        );
      }

      Swal.fire({
        title: "Sucesso!",
        text: "Conteúdo criado com sucesso!",
        icon: "success",
      });

      goToContents();
    } catch (error) {
      Swal.fire({
        title: "Erro",
        text: "Houve um erro ao criar o conteúdo. " + error.message,
        icon: "error",
      });
    }
  };

  const updateContent = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(title)) {
        throw new Error("Informe um título válido para o conteúdo.");
      }

      if (!reference) {
        throw new Error("Selecione um conteúdo antes.");
      }

      await updateContentService(contentId, {
        title: title,
        description: description || undefined,
        duration: switchState === 'scorm' ? 0 : Number(duration),
        reference: reference,
        type: switchState === 'scorm' ? switchState.toUpperCase() : type.toLocaleUpperCase(),
        is_active: true,
        is_library: false,
      });

      for (let material of extraMaterialsToBeDeleted) {
        await deleteExtraMaterialService(material.id);
      }

      let uploadedMaterials = [] as { filename: string; reference: string }[];

      if (extraMaterials && extraMaterials.length >= 1) {
        for (let material of extraMaterials.filter(
          (extraMaterial: any) => !extraMaterial.alreadySaved
        )) {
          const formData = new FormData();
          formData.append("file", material);

          await uploadFile(formData).then(({ reference, name }) =>
            uploadedMaterials.push({ reference, filename: name })
          );
        }
      }

      if (uploadedMaterials.length >= 1) {
        await createExtraMaterialService(contentId, uploadedMaterials);
      }

      Swal.fire({
        title: "Sucesso!",
        text: "Curso editado com sucesso!",
        icon: "success",
      });

      goToContents();
    } catch (error) {
      Swal.fire({
        title: "Erro",
        text: "Houve um erro ao editar o conteúdo. " + error.message,
        icon: "error",
      });
    }
  };

  const changeContentThumbnail = async (
    localThumbnailFileToUpload: File,
    localContentId: string
  ) => {
    const formData = new FormData();
    formData.append("file", localThumbnailFileToUpload);
    formData.append(
      "name",
      `content_thumbnail_${localContentId}.${localThumbnailFileToUpload.type}`
    );
    formData.append(
      "description",
      `thumbnail do conteúdo de id ${localContentId}`
    );

    await changeContentThumbnailService(localContentId, formData);
  };

  const selectContent = () => {
    showModal(
      "Selecionar Conteúdo",
      <SelectSambaVideosContent onSelectContent={onSelectContent} />
    );
  };

  const selectThumbnail = () => {
    showModal(
      "Selecionar Imagem de Capa",
      <CutImage aspect={1.812} onCutImage={onCutImage} />
    );
  };

  const onCutImage = (file: File) => {
    if (file) {
      setThumbnailFileToUpload(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setThumbnail(`${reader.result}`);

      hideModal();
    }
  };

  const goToContents = () => {
    history.push("/trails/contents");
  };

  const getContent = useCallback(async () => {
    if (contentId) {
      const content = await getContentService(contentId);

      if (content && Object.keys(content).length) {
        setTitle(content.content.title);
        setDescription(content.content.description);
        setReference(content.content.reference);
        setDuration(content.content.duration);
        setThumbnail(content.content.thumbnail);
        setType(content.content.type);
        if(typeof content.content.type === 'string' && content.content.type.toUpperCase() === 'SCORM'){
          setSwitchState('scorm');
        }


        if (content.content?.updated_at) {
          let date = new Date(content.content.updated_at);
          setUpdatedAt(date.toLocaleString());
        }

        const localExtraMaterials =
          content.content.extra_materials &&
          content.content.extra_materials.length &&
          content.content.extra_materials.map((extraMaterial: any) => ({
            id: extraMaterial.extra_material_id,
            name: extraMaterial.name,
            alreadySaved: true,
            reference: extraMaterial.reference,
          }));

        setExtraMaterials(localExtraMaterials);
      }
    }
  }, [contentId]);

  useEffect(() => {
    getContent();
  }, [getContent]);

  const isEditting = useMemo(() => {
    if (contentId) {
      return true;
    }

    return false;
  }, [contentId]);

  const addExtraMaterial = (event: any) => {
    event.preventDefault();

    if (event) {
      let file = event.target.files[0];
      file.alreadySaved = false;

      setExtraMaterials([...(extraMaterials || []), file]);
    }
  };

  const removeExtraMaterial = (extraMaterialToBeRemoved: any) => {
    if (extraMaterialToBeRemoved.alreadySaved) {
      setExtraMaterialsToBeDeleted([
        ...(extraMaterialsToBeDeleted || []),
        extraMaterialToBeRemoved,
      ]);
    }

    let localExtraMaterials = [...(extraMaterials || [])];
    localExtraMaterials = localExtraMaterials.filter(
      (extraMaterial) => extraMaterial !== extraMaterialToBeRemoved
    );

    setExtraMaterials(localExtraMaterials);
  };

  return (
    <CreateAndEditContentContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Dashboard</Link>,
          <Link to="/trails/contents">Trilhas</Link>,
          <span>{isEditting ? 'Editar' : 'Criar'} Conteúdos</span>,
        ]}
      />

      <DefaultPageTitle>
        {isEditting ? 'Editar' : 'Criar'} Conteúdo
        {updatedAt && (
          <UpdatedAtLabel> - Última atualização em {updatedAt}</UpdatedAtLabel>
        )}
      </DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">
            Título
          </label>
          <DefaultInput
            value={title}
            onChange={e => setTitle(e.target.value)}
            id="title"
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="description">Descrição</label>
          <DefaultTextArea
            value={description}
            onChange={e => setDescription(e.target.value)}
            id="description"
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="reference">
            Conteúdo
          </label>

          <DefaultSwitch 
            switchState={switchState}
            setSwitchState={setSwitchState}
            setReference={setReference}
          />
          <ContentUploadOrSelectContainer>
          {
            switchState === 'video' ?
            (
              <DefaultButton type="button" onClick={selectContent}>
                Selecionar Conteúdo
              </DefaultButton>
            ) : (
              <UploadScorm setReference={setReference} />
            )
          }
          {
            reference ? 
            (
              <iframe
                title="referenced-video"
                allowFullScreen
                src={reference}
                frameBorder={0}
              ></iframe>
            ) : (
                  <></>
            )
          }
          </ContentUploadOrSelectContainer>
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label>Materiais de apoio</label>
          <label htmlFor="extra-materials" className="extra-materials">
            Selecionar material
          </label>
          <ExtraMaterialsInput
            type="file"
            onChange={e => addExtraMaterial(e)}
            ref={inputRef}
            id="extra-materials"
          />
          <ExtraMaterialList>
            {extraMaterials && extraMaterials.length ? (
              extraMaterials.map((material: any, index: number) => (
                <ExtraMaterial key={index}>
                  <h5>{material.name}</h5>
                  {material.reference ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={material.reference}
                    >
                      <BiDownload size={14} />
                    </a>
                  ) : (
                    <></>
                  )}

                  <button
                    onClick={() => removeExtraMaterial(material)}
                    type="button"
                  >
                    <BiTrash size={14} />
                  </button>
                </ExtraMaterial>
              ))
            ) : (
              <p>Não foi encontrado nenhum material de apoio.</p>
            )}
          </ExtraMaterialList>
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton
            type="button"
            className="danger"
            onClick={goToContents}
          >
            Cancelar
          </DefaultButton>
          <DefaultButton
            onClick={e => (isEditting ? updateContent(e) : createContent(e))}
            className="success"
          >
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditContentContainer>
  );
};

export default CreateAndEditContent;
