import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import BreadCrumb from '../../../components/BreadCrumb';
import { CreateButtonDefaultContainer } from '../../../components/CreateButtonDefaultContainer';
import DefaultButton from '../../../components/DefaultButton';
import { DefaultPageTitle } from '../../../components/DefaultPageTitle';
import DefaultTable from '../../../components/DefaultTable';

import {
  getAllCategories,
  deleteCategory,
} from '../../../services/forum-category';
import { CategoriesContainer } from './style';
import Category from '../../../models/category';
import DefaultInput from '../../../components/DefaultInput';
import { AiOutlineEye, AiOutlineSearch } from 'react-icons/ai';
import { DefaultCreateButtonAndSearchFormContainer } from '../../../components/DefaultCreateButtonAndSearchFormContainer';
import getErrorMessage from '../../../helpers/get-error-message';

const Categories: React.FC = () => {
  const history = useHistory();

  const [categories, setCategories] = useState([] as Category[]);
  const [categoryName, setCategoryName] = useState('');

  const getCategories = async (event?: React.FormEvent) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    const localCategories = await getAllCategories({
      description: categoryName,
    });
    setCategories(localCategories);
  };

  const editCategory = useCallback(
    (categoryId: any) => {
      history.push(`edit-category/${categoryId}`);
    },
    [history],
  );

  const removeCategory = useCallback(async (categoryId: any) => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: 'Tem certeza que deseja remover esta categoria?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await deleteCategory(categoryId);

          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Categoria excluída com sucesso!',
          });

          await getCategories();
        } catch (error) {
          const errorMessage = getErrorMessage(error);
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: `Erro ao excluir categoria. ${
              error.response && error.response.status === 423
                ? 'Esta categoria já está associada a um tópico!'
                : errorMessage
            }`,
          });
        }
      }
    });
  }, []);

  const createCategory = async () => {
    history.push('create-category');
  };

  const contentsToBeShown = useMemo(() => {
    return categories && categories.length
      ? categories.map(category => ({
          id: category.category_id,
          title: category.description,
          questions: (
            <div className="button">
              <span>
                {category.questions && category.questions > 0 ? (
                  <DefaultButton
                    style={{ margin: 0 }}
                    className="questions-number transparent small"
                    onClick={() =>
                      history.push(
                        `/forum/categories/${category.category_id}/questions`,
                      )
                    }
                  >
                    <span>{category.questions}</span> <AiOutlineEye size={18} />
                  </DefaultButton>
                ) : null}
              </span>
            </div>
          ),
          actions: (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                gap: '5px',
              }}
            >
              <DefaultButton
                onClick={() => editCategory(category.category_id)}
                className="small info"
                title="Editar Categoria"
              >
                <BiEdit />
              </DefaultButton>{' '}
              <DefaultButton
                onClick={() => {
                  removeCategory(category.category_id);
                }}
                className="small danger"
                title="Excluir Categoria"
              >
                <BiTrash />
              </DefaultButton>
            </div>
          ),
        }))
      : [];
  }, [categories, editCategory, removeCategory]);

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (!categoryName) {
      getCategories();
    }
  }, [categoryName]);

  return (
    <CategoriesContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Dashboard</Link>,
          <span>Fórum</span>,
          <span>Categorias</span>,
        ]}
      />

      <DefaultPageTitle>Categorias</DefaultPageTitle>

      <DefaultCreateButtonAndSearchFormContainer>
        <CreateButtonDefaultContainer>
          <DefaultButton onClick={createCategory}>
            Criar Categoria
          </DefaultButton>
        </CreateButtonDefaultContainer>

        <form
          onSubmit={getCategories}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'stretch',
            gap: '10px',
          }}
        >
          <DefaultInput
            type="search"
            placeholder="Digite sua pesquisa aqui..."
            value={categoryName}
            onChange={e => setCategoryName(e.target.value)}
            style={{ margin: 0 }}
          />
          <DefaultButton className="small" style={{ margin: 0 }} type="submit">
            <AiOutlineSearch size={24} />
          </DefaultButton>
        </form>
      </DefaultCreateButtonAndSearchFormContainer>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Título</span>,
            propName: 'title',
            attributes: { style: { width: '35%' } },
          },
          {
            headerLabel: <span>Tópicos</span>,
            propName: 'questions',
            attributes: { style: { textAlign: 'left' } },
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={contentsToBeShown}
        emptyListMessage={'Não foram encontrados categorias cadastradas!'}
      />
    </CategoriesContainer>
  );
};

export default Categories;
