import React from 'react';
import DefaultButton from '../../../components/DefaultButton';
import DefaultInput from '../../../components/DefaultInput';
import Modal from '../../../components/Modal';
import { putPhoneMask } from '../../../helpers/mask';
import { Container } from './styles';

interface IModalPhone {
  onClose: () => void;
  onSave: (phone: string) => void;
}

const ModalPhone: React.FC<IModalPhone> = ({ onClose, onSave }: IModalPhone) => {

  const [phone, setPhone] = React.useState('');
  const [error, setError] = React.useState('');

  const onUpdateNumber = (value: string) => {
    setPhone(putPhoneMask(value));

    if (error) {
      if (phone.length < 14) {
        setError('Telefone inválido');
      } else {
        setError('');
      }
    }
  }

  const handleSavePhone = () => {
    if (phone.length < 14) {
      setError('Telefone inválido');
    } else {
      onSave(phone);
      onClose();
    }
  }

  return <Modal widthAuto onClickOutside={onClose} >
    <Container>
      <header>
        <h2>Adicione o novo número de telefone</h2>
      </header>
      <main>
        <DefaultInput value={phone} onChange={(e) => onUpdateNumber(e.target.value)} maxLength={15} minLength={14} placeholder="(XX) X XXXX-XXXX" />
        {error && (<small>{error}</small>)}
      </main>
      <footer>
        <DefaultButton className="info" onClick={onClose}>Cancelar</DefaultButton>
        <DefaultButton className="success" onClick={handleSavePhone}>Salvar</DefaultButton>
      </footer>
    </Container>
  </Modal>;
}

export default ModalPhone;