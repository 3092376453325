import httpClient from "../http-client";
import TagFromResponse from "../models/from-api-response/tag";

const getTag = async (tagId: string): Promise<TagFromResponse> => {
  const tags = (await httpClient.get(`tags/${tagId}`)).data;

  return tags;
};

const getAllTags = async (): Promise<TagFromResponse[]> => {
  const tags = (await httpClient.get(`tags`)).data;

  return tags;
};

const createTag = async (newTag: any) => {
  const createTagResponse = (await httpClient.post<any>(`tags`, newTag)).data;

  return createTagResponse;
};

const updateTag = async (tagId: string, newContentTab: any) => {
  await httpClient.put(`tags/${tagId}`, newContentTab);
};

const deleteTag = async (tagId: string) => {
  await httpClient.delete(`tags/${tagId}`);
};

const verifyTag = async (tagId: any, verify: boolean) => {
  await httpClient.put(`tags/${tagId}/verified`);
};

export { getTag, getAllTags, createTag, updateTag, deleteTag, verifyTag };
