import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BiEdit } from 'react-icons/bi';
import { Link, useHistory } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb';
import DefaultButton from '../../components/DefaultButton';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import DefaultTable from '../../components/DefaultTable';
import { getSegmentations as getSegmentationsService } from '../../services/segmentation';
import { CategoriesContainer } from './style';
import SegmentationType from '../../models/segmentation-type';

const Segmentation: React.FC = () => {
  const history = useHistory();
  const [segmentations, setSegmentations] = useState([] as SegmentationType[]);

  const getSegmentations = async () => {
    const localSegmentations = await getSegmentationsService();

    if (localSegmentations && localSegmentations.length) {
      setSegmentations(localSegmentations);
    }
  };

  const editSegmentation = useCallback(
    (segmentationId: any) => {
      history.push(`edit-segmentation/${segmentationId}`);
    },
    [history],
  );

  const segmentationsToBeShown = useMemo(() => {
    return segmentations && segmentations.length
      ? segmentations.map(segmentation => ({
          id: segmentation.segmentation_type_id,
          description: segmentation.type,
          actions: (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                gap: '5px',
              }}
            >
              <DefaultButton
                onClick={() =>
                  editSegmentation(segmentation.segmentation_type_id)
                }
                className="small info"
                title="Editar Segmentação"
              >
                <BiEdit />
              </DefaultButton>{' '}
            </div>
          ),
        }))
      : [];
  }, [segmentations, editSegmentation]);

  useEffect(() => {
    getSegmentations();
  }, []);

  return (
    <CategoriesContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Dashboard</Link>,
          <span>Segmentação</span>,
        ]}
      />

      <DefaultPageTitle>Segmentação</DefaultPageTitle>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Descrição</span>,
            propName: 'description',
            attributes: { style: { width: '35%' } },
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={segmentationsToBeShown}
        emptyListMessage={'Não foram encontradas segmentações cadastradas!'}
      />
    </CategoriesContainer>
  );
};

export default Segmentation;
