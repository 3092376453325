import {
  AiOutlineFileText,
  AiOutlineUser,
  AiOutlineDashboard,
} from 'react-icons/ai';
import { IoLibraryOutline, IoPlayCircleOutline } from 'react-icons/io5';
import {
  BsClipboardData,
  BsFileEarmarkCheck,
  BsFileText,
  BsGraphUp,
} from 'react-icons/bs';
import { GiPencil, GiPencilRuler, GiTeamUpgrade } from 'react-icons/gi';
import { FiMap } from 'react-icons/fi';
import { MdForum } from 'react-icons/md';
import { FaQuestionCircle, FaUserShield } from 'react-icons/fa';
import { RiFileList2Line, RiPagesLine } from 'react-icons/ri';
import { FiShield } from 'react-icons/fi';
import { HiOutlineArchive } from 'react-icons/hi';

const menusAdmin = [
  {
    label: (
      <>
        <span className="icon">
          <AiOutlineDashboard />
        </span>
        <span className="text">Dashboard</span>
      </>
    ),
    path: '/profile',
  },
  {
    label: (
      <>
        <span className="icon">
          <FaUserShield />
        </span>
        <span className="text">Usuários</span>
      </>
    ),
    path: '/users',
  },
  {
    label: (
      <>
        <span className="icon">
          <AiOutlineUser />
        </span>
        <span className="text">Autores</span>
      </>
    ),
    path: '/authors',
  },
  {
    label: (
      <>
        <span className="icon">
          <RiPagesLine />
        </span>
        <span className="text">Depoimentos</span>
      </>
    ),
    path: '/depositions',
  },
  {
    label: (
      <>
        <span className="icon">
          <IoLibraryOutline />
        </span>
        <span className="text">Categorias</span>
      </>
    ),
    path: '/categories',
  },
  {
    label: (
      <>
        <span className="icon">
          <AiOutlineFileText />
        </span>
        <span className="text">Cursos</span>
      </>
    ),
    path: '/contents',
  },
  {
    label: (
      <>
        <span className="icon">
          <FiMap />
        </span>
        <span className="text">Trilhas</span>
      </>
    ),
    children: [
      {
        label: (
          <>
            <span className="icon">
              <GiPencil />
            </span>
            <span className="text">Conteúdos</span>
          </>
        ),
        path: '/trails/contents',
      },
      {
        label: (
          <>
            <span className="icon">
              <HiOutlineArchive />
            </span>
            <span className="text">Arquivos</span>
          </>
        ),
        path: '/trails/file',
      },
      {
        label: (
          <>
            <span className="icon">
              <AiOutlineFileText />
            </span>
            <span className="text">Módulos</span>
          </>
        ),
        path: '/trails/episodes',
      },
      {
        label: (
          <>
            <span className="icon">
              <IoLibraryOutline />
            </span>
            <span className="text">Trilha</span>
          </>
        ),
        path: '/trails/series',
      },
    ],
  },

  {
    label: (
      <>
        <span className="icon">
          <FaQuestionCircle />
        </span>
        <span className="text">FAQs</span>
      </>
    ),
    path: '/faqs',
  },
  {
    label: (
      <>
        <span className="icon">
          <FiShield />
        </span>
        <span className="text">Segmentação</span>
      </>
    ),
    path: '/segmentation',
  },
  {
    label: (
      <>
        <span className="icon">
          <MdForum />
        </span>
        <span className="text">Fórum</span>
      </>
    ),
    children: [
      {
        label: (
          <>
            <span className="icon">
              <RiFileList2Line />
            </span>
            <span className="text">Categorias</span>
          </>
        ),
        path: '/forum/categories',
      },
    ],
  },
  // {
  //   label: (
  //     <>
  //       <span className="icon">
  //         <IoPlayCircleOutline />
  //       </span>
  //       <span className="text">Lives</span>
  //     </>
  //   ),
  //   path: '/lives',
  // },
  {
    label: (
      <>
        <span className="icon">
          <BsFileEarmarkCheck />
        </span>
        <span className="text">Avaliar Exercícios</span>
      </>
    ),
    path: '/tests-received',
  },
  {
    label: (
      <>
        <span className="icon">
          <GiPencilRuler />
        </span>
        <span className="text">Provas e Questões</span>
      </>
    ),
    children: [
      {
        label: (
          <>
            <span className="icon">
              <GiPencil />
            </span>
            <span className="text">Provas</span>
          </>
        ),
        path: '/exams-questions/exams',
      },
      {
        label: (
          <>
            <span className="icon">
              <AiOutlineFileText />
            </span>
            <span className="text">Questões</span>
          </>
        ),
        path: '/exams-questions/questions',
      },
    ],
  },
  {
    label: (
      <>
        <span className="icon">
          <BsClipboardData />
        </span>
        <span className="text">Relatórios</span>
      </>
    ),
    children: [
      {
        label: (
          <>
            <span className="icon">
              <GiTeamUpgrade />
            </span>
            <span className="text">Engajamento Cursos</span>
          </>
        ),
        path: '/reports/engagement',
      },
      {
        label: (
          <>
            <span className="icon">
              <GiTeamUpgrade />
            </span>
            <span className="text">Engajamento Trilha</span>
          </>
        ),
        path: '/reports/engagementTrail',
      },
      {
        label: (
          <>
            <span className="icon">
              <GiTeamUpgrade />
            </span>
            <span className="text">Engajamento Depoimentos</span>
          </>
        ),
        path: '/reports/engagementTestimonial',
      },
      {
        label: (
          <>
            <span className="icon">
              <BsGraphUp />
            </span>
            <span className="text">Acessos</span>
          </>
        ),
        path: '/reports/accesses',
      },
      {
        label: (
          <>
            <span className="icon">
              <BsFileText />
            </span>
            <span className="text">Provas</span>
          </>
        ),
        path: '/reports/exams',
      },
    ],
  },
];

export default menusAdmin;
