import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb';
import DefaultFilterForm from '../../components/DefaultFilterForm';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { EngagementContainer } from './style';
import Select, { components } from 'react-select';
import DefaultButton from '../../components/DefaultButton';
import { generateEngagementReportTrail } from '../../services/reports';
import { getSegmentation } from '../../services/segmentation';
import { getAllCourses as getAllTrailsService } from '../../services/trails';

interface UnityPros {
  item: string;
  segmentation_item_id: string;
}

const msgStyles = {
  color: '#ff0000',
};

const NoOptionsMessage = (props: any) => {
  return (
    <components.NoOptionsMessage {...props}>
      <span className="custom-css-class">Sem mais opções</span>
    </components.NoOptionsMessage>
  );
};

const EngagementTrail: React.FC = () => {
  const [trails, setTrails] = useState(
    [] as { value: string; label: string }[],
  );
  const [selectedTrails, setSelectedTrails] = useState([] as string[]);

  const [startCourseStart, setStartCourseStart] = useState('');
  const [startCourseFinish, setStartCourseFinish] = useState('');
  const [finishCourseStart, setFinishCourseStart] = useState('');
  const [finishCourseFinish, setFinishCourseFinish] = useState('');
  const [units, setUnits] = useState([] as { label: string; value: string }[]);
  const [selectedUnity, setSelectedUnity] = useState([] as string[]);

  const generateReport = async (reportType: string) => {
    const queryStrings: any = {
      start_at_lte: startCourseFinish,
      start_at_gte: startCourseStart,
      finish_at_lte: finishCourseFinish,
      finish_at_gte: finishCourseStart,
      trail_id_ft: selectedTrails,
      shop_unit_ft: selectedUnity,
    };

    for (let prop of Object.keys(queryStrings)) {
      if (!queryStrings[prop]) {
        delete queryStrings[prop];
      }
    }

    await generateEngagementReportTrail(reportType, queryStrings);
  };

  useEffect(() => {
    (async () => {
      const localTrails = await getAllTrailsService();

      if (localTrails && localTrails.length) {
        setTrails(
          localTrails.map((trail: { name: string; trail_id: string }) => ({
            label: trail.name,
            value: trail.trail_id,
          })),
        );
      }

      const idUnitySegment = process.env.REACT_APP_SEGMENTATION_TYPE_ID || '';
      const unitsSegment = await getSegmentation(idUnitySegment);

      if (unitsSegment && unitsSegment.length) {
        setUnits(
          unitsSegment.map((unity: UnityPros) => ({
            label: unity.item,
            value: unity.segmentation_item_id,
          })),
        );
      }
    })();
  }, []);

  return (
    <EngagementContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Dashboard</Link>,
          <span>Relatórios</span>,
          <span>Engajamento</span>,
        ]}
      />

      <DefaultPageTitle>Trilha</DefaultPageTitle>

      <DefaultFilterForm>
        <span className="entire-row">
          <label htmlFor="trilha">Trilha</label>
          <Select
            isMulti
            onChange={options =>
              setSelectedTrails(options.map(opt => opt.value))
            }
            options={trails}
            placeholder="Selecione uma trilha..."
            components={{ NoOptionsMessage }}
            styles={{ noOptionsMessage: base => ({ ...base, ...msgStyles }) }}
          />
        </span>

        <span className="entire-row">
          <label htmlFor="unidade">Unidade</label>
          <Select
            isMulti
            onChange={options =>
              setSelectedUnity(options.map(opt => opt.label))
            }
            options={units}
            placeholder="Selecione uma unidade..."
            components={{ NoOptionsMessage }}
            styles={{ noOptionsMessage: base => ({ ...base, ...msgStyles }) }}
          />
        </span>

        <span>
          <label htmlFor="startAt">Início em</label>
          <input
            type="date"
            id="startAtGte"
            max={new Date().toISOString().split('T')[0]}
            onChange={e => setStartCourseStart(e.target.value)}
            onKeyDown={e => {
              e.stopPropagation();
              e.preventDefault();
              return false;
            }}
          />
        </span>

        <span>
          <span className="label">até</span>
          <input
            type="date"
            id="startAtLte"
            min={startCourseStart}
            onChange={e => setStartCourseFinish(e.target.value)}
            onKeyDown={e => {
              e.stopPropagation();
              e.preventDefault();
              return false;
            }}
            max={new Date().toISOString().split('T')[0]}
          />
        </span>

        <span>
          <label htmlFor="finishtAt">Término em</label>
          <input
            type="date"
            min={startCourseStart}
            id="finishAtGte"
            max={new Date().toISOString().split('T')[0]}
            onChange={e => setFinishCourseStart(e.target.value)}
            onKeyDown={e => {
              e.stopPropagation();
              e.preventDefault();
              return false;
            }}
          />
        </span>

        <span>
          <span className="label">até</span>
          <input
            type="date"
            id="finishAtLte"
            min={finishCourseStart}
            onChange={e => setFinishCourseFinish(e.target.value)}
            onKeyDown={e => {
              e.stopPropagation();
              e.preventDefault();
              return false;
            }}
            max={new Date().toISOString().split('T')[0]}
          />
        </span>

        <span className="entire-row center">
          <DefaultButton
            onClick={() => generateReport('pdf')}
            className="success"
            type="button"
          >
            Gerar Relatório (PDF)
          </DefaultButton>{' '}
          <DefaultButton
            onClick={() => generateReport('csv')}
            className="success"
            type="button"
          >
            Gerar Relatório (CSV)
          </DefaultButton>
        </span>
      </DefaultFilterForm>
    </EngagementContainer>
  );
};

export default EngagementTrail;
