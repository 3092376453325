import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import BreadCrumb from '../../components/BreadCrumb';
import DefaultButton from '../../components/DefaultButton';
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from '../../components/DefaultCreationForm';
import DefaultInput from '../../components/DefaultInput';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { DefaultTextArea } from '../../components/DefaultTextArea';
import { CreateAndEditCategoryContainer } from './style';
import checkEmptyString from '../../helpers/check-empty-string';
import {
  createCategory as createCategoryService,
  getAllCategories,
  getCategory,
  updateCategory as updateCategoryService,
} from '../../services/categories';
import Category from '../../models/category';
import Select from 'react-select';
import getErrorMessage from '../../helpers/get-error-message';

interface CreateAndEditCategoryProps {
  categoryId: string;
}

const CreateAndEditCategory: React.FC = () => {
  const history = useHistory();

  const { categoryId } = useParams<CreateAndEditCategoryProps>();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [categories, setCategories] = useState([] as Category[]);
  const [selectedCategory, setSelectedCategory] = useState<any>();

  const createCategory = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(title)) {
        throw new Error('Informe um título válido para a categoria.');
      }

      await createCategoryService({
        title: title,
        description: description.trim().length ? description : undefined,
        category_parent: selectedCategory ? selectedCategory.value : null,
      });

      Swal.fire({
        title: 'Sucesso!',
        text: 'Categoria criada com sucesso!',
        icon: 'success',
      });

      goToCategories();
    } catch (error) {
      const message = getErrorMessage(error);

      if (message === 'API erro: A categoria escolhida é uma subcategoria') {
        Swal.fire({
          title: 'Erro',
          text: 'A categoria escolhida é uma subcategoria.',
          icon: 'error',
        });
      } else {
        Swal.fire({
          title: 'Erro',
          text:
            'Houve um erro ao criar o categoria. ' + error &&
            error.response &&
            error.response.status === 409
              ? 'Já existe uma categoria com este título'
              : error.message,
          icon: 'error',
        });
      }
    }
  };

  const updateCategory = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(title)) {
        throw new Error('Informe um título válido para a categoria.');
      }

      await updateCategoryService(categoryId, {
        title: title,
        description: description || undefined,
        category_parent: selectedCategory ? selectedCategory.value : null,
      });

      Swal.fire({
        title: 'Sucesso!',
        text: 'Categoria editado com sucesso!',
        icon: 'success',
      });

      goToCategories();
    } catch (error) {
      const message = getErrorMessage(error);

      if (message === 'API erro: A categoria escolhida é uma subcategoria') {
        Swal.fire({
          title: 'Erro',
          text: 'A categoria escolhida é uma subcategoria.',
          icon: 'error',
        });
      } else {
        Swal.fire({
          title: 'Erro',
          text:
            'Houve um erro ao criar o categoria. ' + error &&
            error.response &&
            error.response.status === 409
              ? 'Já existe uma categoria com este título'
              : error.message,
          icon: 'error',
        });
      }
    }
  };

  const goToCategories = () => {
    history.push('/categories');
  };

  const getContent = useCallback(async () => {
    const foundCategories = await getAllCategories({ is_active: true });

    if (categoryId) {
      const category = await getCategory(categoryId);

      if (category && Object.keys(category).length) {
        setTitle(category.title);
        setDescription(category.description);

        if (category.category_parent && category.category_parent.category_id) {
          setSelectedCategory({
            label: category.category_parent.title,
            value: category.category_parent.category_id,
          });
        }
      }
    }

    if (foundCategories) {
      setCategories(
        foundCategories.filter(category => category.category_id !== categoryId),
      );
    }
  }, [categoryId]);

  useEffect(() => {
    getContent();
  }, [getContent]);

  const categoriesToBeSelected = useMemo(() => {
    if (categories && categories.length) {
      return categories.map(category => ({
        label: category.title,
        value: `${category.category_id}`,
      }));
    }

    return [] as { label: string; value: string }[];
  }, [categories]);

  const isEditting = useMemo(() => {
    if (categoryId) {
      return true;
    }

    return false;
  }, [categoryId]);

  const onChangeSelectedCategory = useCallback(option => {
    if (option) {
      setSelectedCategory({
        label: option.label,
        value: option.value,
      });
    } else {
      setSelectedCategory(null);
    }
  }, []);

  return (
    <CreateAndEditCategoryContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Dashboard</Link>,
          <Link to="/categories">Categorias</Link>,
          <span>{isEditting ? 'Editar' : 'Criar'} Categorias</span>,
        ]}
      />

      <DefaultPageTitle>
        {isEditting ? 'Editar' : 'Criar'} Categorias
      </DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">
            Título
          </label>
          <DefaultInput
            value={title}
            onChange={e => setTitle(e.target.value)}
            id="title"
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="description">Descrição</label>
          <DefaultTextArea
            value={description}
            onChange={e => setDescription(e.target.value)}
            id="description"
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="tags">Categoria Pai (principal)</label>
          <Select
            isClearable
            styles={{
              container: provided => ({
                ...provided,
                flexGrow: 1,
                width: '100%',
              }),
            }}
            options={categoriesToBeSelected}
            value={selectedCategory}
            onChange={options => {
              onChangeSelectedCategory(options);
            }}
            id="categories"
            placeholder="Selecione uma categoria para este conteúdo."
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton
            type="button"
            className="danger"
            onClick={goToCategories}
          >
            Cancelar
          </DefaultButton>
          <DefaultButton
            onClick={e => (isEditting ? updateCategory(e) : createCategory(e))}
            className="success"
          >
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditCategoryContainer>
  );
};

export default CreateAndEditCategory;
