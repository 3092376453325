import React from 'react';
import Question from '../../../../models/question';
import {
  ContainerPreview,
  QuestionTitle,
  QuestionDetails,
  ExamTitle,
  ExamDescription,
} from './style';

interface IProps {
  questions: Question[];
  title: string;
  description: string;
}

const Previewer: React.FC<IProps> = ({ questions, title, description }) => {
  return (
    <ContainerPreview>
      <ExamTitle>{title}</ExamTitle>
      <ExamDescription>{description}</ExamDescription>
      {questions.map((question, index) => (
        <>
          <QuestionTitle>
            {`${index + 1} - `}
            {question.enunciated}
          </QuestionTitle>
          {question.alternative.map(item => (
            <QuestionDetails>○ {item.value}</QuestionDetails>
          ))}
        </>
      ))}
    </ContainerPreview>
  );
};

export default Previewer;
