import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import BreadCrumb from "../../components/BreadCrumb";
import DefaultButton from "../../components/DefaultButton";
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from "../../components/DefaultCreationForm";
import DefaultInput from "../../components/DefaultInput";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import { DefaultTextArea } from "../../components/DefaultTextArea";
import { hideModal, showModal } from "../../helpers/modal";
import {
  ContentThumbnail,
  CreateAndEditAuthorContainer,
  ThumbnailUploadContainer,
} from "./style";

import CutImage from "../../components/CutImage";
import checkEmptyString from "../../helpers/check-empty-string";
import {
  createAuthor,
  getAuthor,
  updateAuthor,
  changeAuthorThumbnail as changeAuthorThumbnailService,
  deleteAuthor,
} from "../../services/authors";

interface CreateAndEditContentProps {
  authorId: string;
}

const CreateAndEditContent: React.FC = () => {
  const { authorId } = useParams<CreateAndEditContentProps>();

  const history = useHistory();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [avatarFileToUpload, setAvatarFileToUpload] = useState<File>();
  const [thumbnail, setThumbnail] = useState<any>();

  const createContent = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(name)) {
        throw new Error("Informe um nome válido para o autor.");
      }

      const createdAuthor = await createAuthor({
        name,
        description: description.trim().length ? description : undefined,
      });

      if (avatarFileToUpload) {
        try {
          await changeContentThumbnail(
            avatarFileToUpload,
            createdAuthor.author_id
          );
        } catch (error) {
          await deleteAuthor(createdAuthor.author_id);

          throw new Error(
            "Erro ao fazer upload da imagem. Certifique-se de que a imagem selecionada não ultrapasse os 5MB."
          );
        }
      }

      Swal.fire({
        title: "Sucesso!",
        text: "Autor criado com sucesso!",
        icon: "success",
      });

      goToAuthors();
    } catch (error) {
      Swal.fire({
        title: "Erro",
        text: "Houve um erro ao criar o autor. " + error.message,
        icon: "error",
      });
    }
  };

  const updateContent = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(name)) {
        throw new Error("Informe um nome válido para o autor.");
      }

      await updateAuthor(authorId, {
        name,
        description: description || undefined,
      });

      if (avatarFileToUpload) {
        try {
          await changeContentThumbnail(avatarFileToUpload, authorId);
        } catch (error) {
          throw new Error(
            "Erro ao fazer upload da imagem. Certifique-se de que a imagem selecionada não ultrapasse os 5MB."
          );
        }
      }

      Swal.fire({
        title: "Sucesso!",
        text: "Autor editado com sucesso!",
        icon: "success",
      });

      goToAuthors();
    } catch (error) {
      Swal.fire({
        title: "Erro",
        text: "Houve um erro ao editar o autor. " + error.message,
        icon: "error",
      });
    }
  };

  const changeContentThumbnail = async (
    localAvatarFileToUpload: File,
    localAuthorId: string
  ) => {
    const formData = new FormData();
    formData.append("file", localAvatarFileToUpload);
    formData.append(
      "name",
      `content_thumbnail_${localAuthorId}.${localAvatarFileToUpload.type}`
    );
    formData.append(
      "description",
      `thumbnail do conteúdo de id ${localAuthorId}`
    );

    await changeAuthorThumbnailService(localAuthorId, formData);
  };

  const selectThumbnail = () => {
    showModal(
      "Selecionar Imagem do Autor",
      <CutImage aspect={1.812} onCutImage={onCutImage} />
    );
  };

  const onCutImage = (file: File) => {
    if (file) {
      setAvatarFileToUpload(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setThumbnail(`${reader.result}`);
      hideModal();
    }
  };

  const goToAuthors = () => {
    history.push("/authors");
  };

  const getContent = useCallback(async () => {
    if (authorId) {
      const author = await getAuthor(authorId);
      if (author && Object.keys(author).length) {
        setName(author.name);
        setDescription(author.description);
        setThumbnail(author.avatar_url);
      }
    }
  }, [authorId]);

  useEffect(() => {
    getContent();
  }, [getContent]);

  const isEditting = useMemo(() => {
    if (authorId) {
      return true;
    }

    return false;
  }, [authorId]);

  return (
    <CreateAndEditAuthorContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Dashboard</Link>,
          <Link to="/authors">Autor</Link>,
          <span>{isEditting ? "Editar" : "Criar"} Autor</span>,
        ]}
      />

      <DefaultPageTitle>
        {isEditting ? "Editar" : "Criar"} Autor
      </DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">
            Nome
          </label>
          <DefaultInput
            value={name}
            onChange={(e) => setName(e.target.value)}
            id="title"
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="description">Descrição</label>
          <DefaultTextArea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            id="description"
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton type="button" className="danger" onClick={goToAuthors}>
            Cancelar
          </DefaultButton>
          <DefaultButton
            onClick={(e) => (isEditting ? updateContent(e) : createContent(e))}
            className="success"
          >
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditAuthorContainer>
  );
};

export default CreateAndEditContent;
