import styled from "styled-components";

const UploadProgressBarContainer = styled.div`
  height: 30px;
  border-radius: 15px;
  width: 300px;
  border: solid 1px var(--default-dark-gray);
  overflow: hidden;
`;

const Progress = styled.div`
  background: var(--primary-color);
  height: 100%;
`;

export { UploadProgressBarContainer, Progress };
