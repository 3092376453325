import styled, { css } from "styled-components";

interface IPropsSwitch {
    switchState: string;
}

const DefaultSwitchContainer = styled.div`
    position: relative;
    width: 80px;
    height: 34px;
    cursor: pointer;
    margin-bottom: 15px;
`;

const Switch = styled.div`
    position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;

    border: 1px solid #5E72E4;
    border-radius: 12px;
`;

const LabelSwitch = styled.label<IPropsSwitch>`
    position: absolute;
    min-width: 0px !important;
    height: 18px;
    left: ${props => props.switchState === 'video' ? '2px' : '33px' };
    right: 44.23%;
    top: calc(50% - 18px/2);
    
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    /* identical to box height */

    text-align: center;
    transition: ease-in;
    color: #5E72E4;

`;

const CircleSwitch = styled.div<IPropsSwitch>`
    position: absolute;
    display: block;
    height: 28px;
    width: 28px;
    left: 31px;
    border-radius: 50%;
   
    background: var(--primary-color);;
    ${props => {        
        if(props.switchState === 'video') {
            return css`
                left: 61.62%;
                right: 5.77%;
                top: 7.5%;
                bottom: 12.5%;
            `;
        } else {
            return css`
                left: 2.66%;
                right: 60.38%;
                top: 7.5%;
                bottom: 12.5%;
            `;
        }
    }}
`

export { DefaultSwitchContainer, Switch, LabelSwitch, CircleSwitch }