import styled, { css, keyframes } from 'styled-components';

const fadeAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 3;
  display: flex;
  animation: ${fadeAnimation} 0.2s ease-in-out;
`;

interface IContentProps {
  widthAuto?: boolean;
}

export const Content = styled.div<IContentProps>`
  @keyframes topBottomAnimation {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0%);
    }
  }
  margin: auto auto 0;
  max-height: 90%;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 3;
  max-width: 900px;
  margin: auto;
  animation: topBottomAnimation 0.2s ease-in-out;
  background:#ffffff;
  border-radius: 8px;
  min-height: 120px;
  padding: 32px;

  ${({ widthAuto }) =>
    !widthAuto &&
    css`
      width: 900px;
    `}
`;

export const Clickable = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;