import Content from "../models/content";

const convertContent = (content: any): any => {
  return {
    id: content.content_id,
    title: content.title,
    reference: content.reference,
    description: content.description,
    duration: content.duration,
    active: content.is_active,
    position: content.position,
  };
};

export default convertContent;
