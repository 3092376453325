import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb';
import DefaultFilterForm from '../../components/DefaultFilterForm';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { EngagementContainer } from './style';
import Select, { components } from 'react-select';
import DefaultButton from '../../components/DefaultButton';
import { generateEngagementReportTestimonial } from '../../services/reports';

import { getAllCourses as getAllTrailsService } from '../../services/trails';
import { getAllContents } from '../../services/contents';
import { DefaultCreationFormGroup } from '../../components/DefaultCreationForm';
import { Switch } from '../CreateAndEditContent/style';

const msgStyles = {
  color: '#ff0000',
};

const NoOptionsMessage = (props: any) => {
  return (
    <components.NoOptionsMessage {...props}>
      <span className="custom-css-class">Sem mais opções</span>
    </components.NoOptionsMessage>
  );
};

const EngagementTestimonial: React.FC = () => {
  const [courses, setCourses] = useState(
    [] as { value: string; label: string }[],
  );
  const [trails, setTrails] = useState(
    [] as { value: string; label: string }[],
  );
  const [selectedCourses, setSelectedCourses] = useState([] as string[]);
  const [selectedTrails, setSelectedTrails] = useState([] as string[]);

  const [startCourseStart, setStartCourseStart] = useState('');
  const [startCourseFinish, setStartCourseFinish] = useState('');
  const [isChecked, setIsChecked] = useState(false);

  const generateReport = async (reportType: string) => {
    if (isChecked) {
      const queryStrings: any = {
        created_at_lte: startCourseFinish,
        created_at_gte: startCourseStart,
        content_id_ft: selectedCourses,
      };

      for (let prop of Object.keys(queryStrings)) {
        if (!queryStrings[prop]) {
          delete queryStrings[prop];
        }
      }

      await generateEngagementReportTestimonial(
        reportType,
        queryStrings,
        'content',
      );
    } else {
      const queryStringsTrail: any = {
        created_at_lte: startCourseFinish,
        created_at_gte: startCourseStart,
        trail_id_ft: selectedTrails,
      };

      for (let prop of Object.keys(queryStringsTrail)) {
        if (!queryStringsTrail[prop]) {
          delete queryStringsTrail[prop];
        }
      }

      await generateEngagementReportTestimonial(
        reportType,
        queryStringsTrail,
        'trail',
      );
    }
  };

  useEffect(() => {
    (async () => {
      if (isChecked) {
        const localCourses = await getAllContents({ is_active: true, is_library: true });

        if (localCourses && localCourses.length) {
          setCourses(localCourses.map(c => ({ label: c.title, value: c.id })));
        }
      } else {
        const localTrails = await getAllTrailsService();

        if (localTrails && localTrails.length) {
          setTrails(
            localTrails.map((trail: { name: string; trail_id: string }) => ({
              label: trail.name,
              value: trail.trail_id,
            })),
          );
        }
      }
    })();
  }, [isChecked]);

  return (
    <EngagementContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Dashboard</Link>,
          <span>Relatórios</span>,
          <span>Engajamento</span>,
        ]}
      />

      <DefaultPageTitle>Depoimentos</DefaultPageTitle>

      <div style={{ display: 'flex' }}>
        <DefaultCreationFormGroup>
          <label>Trilha ou Cursos</label>
          <Switch style={{ width: '50px' }}>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={() => {
                setIsChecked(oldValue => !oldValue);
              }}
            />
            <span className="slider round"></span>
          </Switch>
        </DefaultCreationFormGroup>
      </div>
      <DefaultFilterForm>
        {isChecked ? (
          <span className="entire-row">
            <label htmlFor="course">Cursos</label>
            <Select
              isMulti
              onChange={options =>
                setSelectedCourses(options.map(opt => opt.value))
              }
              options={courses}
              placeholder="Selecione um curso..."
              components={{ NoOptionsMessage }}
              styles={{ noOptionsMessage: base => ({ ...base, ...msgStyles }) }}
            />
          </span>
        ) : (
          <span className="entire-row">
            <label htmlFor="trilha">Trilha</label>
            <Select
              isMulti
              onChange={options =>
                setSelectedTrails(options.map(opt => opt.value))
              }
              options={trails}
              placeholder="Selecione uma trilha..."
              components={{ NoOptionsMessage }}
              styles={{ noOptionsMessage: base => ({ ...base, ...msgStyles }) }}
            />
          </span>
        )}

        <span>
          <label htmlFor="startAt">Início em</label>
          <input
            type="date"
            id="startAtGte"
            max={new Date().toISOString().split('T')[0]}
            onChange={e => setStartCourseStart(e.target.value)}
            onKeyDown={e => {
              e.stopPropagation();
              e.preventDefault();
              return false;
            }}
          />
        </span>

        <span>
          <span className="label">até</span>
          <input
            type="date"
            id="startAtLte"
            min={startCourseStart}
            onChange={e => setStartCourseFinish(e.target.value)}
            onKeyDown={e => {
              e.stopPropagation();
              e.preventDefault();
              return false;
            }}
            max={new Date().toISOString().split('T')[0]}
          />
        </span>

        <span className="entire-row center">
          <DefaultButton
            onClick={() => generateReport('pdf')}
            className="success"
            type="button"
          >
            Gerar Relatório (PDF)
          </DefaultButton>{' '}
          <DefaultButton
            onClick={() => generateReport('csv')}
            className="success"
            type="button"
          >
            Gerar Relatório (CSV)
          </DefaultButton>
        </span>
      </DefaultFilterForm>
    </EngagementContainer>
  );
};

export default EngagementTestimonial;
