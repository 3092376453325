import QuestionFromResponse from '../../models/from-api-response/question';
import Question from '../../models/question';

const convertToQuestion = (question: QuestionFromResponse): Question => {
  return {
    id: question.question_id,
    answer: question.answer,
    enunciated: question.enunciated,
    enabled: !!question.enabled,
    isCanceled: !!question.is_canceled,
    points: question.points ? question.points : 0,
    questionType: question.question_type,
    randomizeAlternatives: !!question.randomize_alternatives,
    alternative:
      question.alternative && question.alternative.length
        ? question.alternative.map(alt => ({
            id: alt.alternative_id,
            isCorrect: !!alt.is_correct,
            order: alt.order ? +alt.order : 0,
            value: alt.value,
          }))
        : [],
    created_at: question.created_at,
  };
};

export default convertToQuestion;
