import styled from "styled-components";

const CreateAndEditCategoryContainer = styled.div`
  textarea {
    font-size: 16px;
    padding: 4px;
    border-radius: 5px;
    outline: none;
  }

  button.questions-number {
    cursor: pointer;
    border: none;
    background: none;
    color: #2f80ed;
    margin-left: 12px;
    font-weight: 600;
    outline: none;

    &:hover {
      text-decoration: underline;
    }
  }

  span {
    color: #000;
  }
`;

const TrashButton = styled.button`
  width: 40px;
  height: 40px;
  background: var(--danger-color);
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08), 0px 4px 6px rgba(50, 50, 93, 0.1);
  margin: 8px auto 0;
`;

export { CreateAndEditCategoryContainer, TrashButton };
