import httpClient from '../http-client';
import UserFromResponse from '../models/from-api-response/user';

const getActualUser = async () => {
  return (await httpClient.get('users/me')).data;
};

const getAllUsers = async (filters?: any) => {
  if (filters) {
    for (let param of Object.keys(filters)) {
      if (!filters[param]) {
        delete filters[param];
      }
    }
  }

  const users = (
    await httpClient.get<UserFromResponse[]>(`users/`, { params: filters })
  ).data;

  if (users && users.length) {
    return users;
  }
};

const getUsersForSelect = async () => {
  const users = (await httpClient.get<UserFromResponse[]>(`users/`)).data;

  if (users && users.length) {
    return users.map(user => ({
      value: user.user_id,
      label: user.name,
    }));
  }

  return [] as { value: string; label: string }[];
};

const updateUserPermissionToAdmin = async (user_id: string) => {
  await httpClient.patch(`users/set-user-admin`, { user_id });
};

const updateUserPermissionToAnalyst = async (user_id: string) => {
  await httpClient.patch(`users/set-user-analyst`, { user_id });
};

const importUsersFromFile = (formData: FormData) => {
  return httpClient
    .post('/users/import', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(({ data }) => data);
};

export {
  getUsersForSelect,
  getActualUser,
  updateUserPermissionToAdmin,
  updateUserPermissionToAnalyst,
  getAllUsers,
  importUsersFromFile,
};
