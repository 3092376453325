import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getActualUser } from '../../services/users';
import User from '../../models/user';

export interface IAuthRouteProps {
  component: any;
  path: string;
  exact?: boolean;
}

const PrivateRoute: React.FC<IAuthRouteProps> = ({
  component: Component,
  path,
  exact,
  ...rest
}) => {
  const [user, setUser] = useState({} as User);

  useEffect(() => {
    (async () => {
      setUser(await getActualUser());
    })();
  }, []);

  const authentication = () => {
    const currentLocation = window.location.pathname;
    if (user.is_admin) {
      return true;
    }
    if (
      currentLocation.includes('reports') ||
      currentLocation.includes('tests-received') ||
      currentLocation.includes('profile')
    ) {
      return true;
    } else return false;
  };

  return (
    <Route
      exact
      path={path}
      {...rest}
      render={props =>
        authentication() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/profile', state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export { PrivateRoute };
