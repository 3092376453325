import httpClient from "../http-client";
import ExtraMaterialFromResponse from "../models/from-api-response/extra-material";

const createExtraMaterial = async (
  contentId: string,
  extra_materials: { filename: string; reference: string }[]
) => {
  return (
    await httpClient.post<ExtraMaterialFromResponse>(
      `/extra-materials/${contentId}`,
      {
        extra_materials,
      }
    )
  ).data;
};

const getExtraMaterials = async (contentId: string) => {
  const extraMaterials = (
    await httpClient.get<{
      course_id: string;
      extra_material: ExtraMaterialFromResponse[];
    }>(`/extra-materials/${contentId}`)
  ).data;

  if (extraMaterials) {
    return extraMaterials.extra_material;
  }

  return [] as ExtraMaterialFromResponse[];
};

const deleteExtraMaterial = async (extraMaterialId: string) => {
  await httpClient.delete(`/extra-materials/${extraMaterialId}`);
};

export { createExtraMaterial, getExtraMaterials, deleteExtraMaterial };
