import httpClient from '../http-client';
import IDepositions from '../models/from-api-response/depositions';

const getAllDepositions = async (filters?: any) => {
  if (filters) {
    for (let param of Object.keys(filters)) {
      if (!filters[param]) {
        delete filters[param];
      }
    }
  }

  const depositions = (
    await httpClient.get<IDepositions[]>(`/trails/review/trail-and-content`, {
      params: filters,
    })
  ).data;

  return depositions;
};

const changeTrailDeposition = async (trailId: string, userId: string) => {
  await httpClient.patch(
    `/trail-user-reviews/${trailId}/${userId}/show-to-all-users`,
  );
};

const changeContentDeposition = async (contentId: string, userId: string) => {
  await httpClient.patch(
    `/content-user-reviews/${contentId}/${userId}/show-to-all-users`,
  );
};

export { getAllDepositions, changeTrailDeposition, changeContentDeposition };
