import axios from 'axios';
import Swal from 'sweetalert2';
import getErrorMessage from '../helpers/get-error-message';

interface IFormSignIn {
  registration: string;
  password: string;
}

const signIn = async ({
  registration,
  password,
}: IFormSignIn): Promise<void> => {
  try {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/cognito/login`, {
        username: registration,
        password,
      })
      .then(response => {
        const { data } = response;
        if (data && data.user && (data.user.is_admin || data.user.is_analyst)) {
          window.localStorage.setItem(
            'telhanorte-api-token',
            response.data.api_token,
          );
          window.localStorage.setItem(
            'actual-telhanorte-user',
            JSON.stringify(response.data.user),
          );
        } else {
          Swal.fire({
            title: 'Erro',
            text: 'O usuário informado não tem permissão para fazer login.',
            icon: 'error',
          });

          throw new Error('User is not an administrator');
        }
      });
    return Promise.resolve();
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    if (errorMessage === 'Incorrect username or password.') {
      Swal.fire({
        title: 'Erro',
        text: 'A matrícula e senha informadas estão incorretas ou não existem.',
        icon: 'error',
      });
    } else {
      Swal.fire({
        title: 'Erro',
        text: 'Algo deu errado ao tentar fazer login. Tente novamente.',
        icon: 'error',
      });
    }

    return Promise.reject();
  }
};

export { signIn };
