import React from "react";
import { CircleSwitch, DefaultSwitchContainer, LabelSwitch, Switch } from "./style";

interface IPropsSwitch {
    switchState: string,
    setSwitchState: React.Dispatch<React.SetStateAction<string>>;
    setReference: React.Dispatch<React.SetStateAction<string>>;
}

const DefaultSwitch: React.FC<IPropsSwitch> = ({
    switchState, setSwitchState, setReference
}) => {

    const switchValueState = () => {
        setSwitchState(switchState === 'video' ? 'scorm' : 'video');
        setReference('');
    }

    return (
        <DefaultSwitchContainer onClick={switchValueState}>
            <Switch>
                <LabelSwitch switchState={switchState}>
                    {switchState === 'video' ? "Vídeo" : "Scorm"}
                </LabelSwitch>
                <CircleSwitch switchState={switchState}/>
            </Switch>
        </DefaultSwitchContainer>
    )
}

export default DefaultSwitch;