import httpClient from "../http-client";
import FAQ from "../models/faq";

const getAllFaqs = async () => {
  const faqs = (await httpClient.get<FAQ[]>("/faqs")).data;

  return faqs;
};

const getFaq = async (faqId: string) => {
  const faq = (await httpClient.get<FAQ>(`/faqs/${faqId}`)).data;

  return faq;
};

const createFaq = async (question: string, answer: string) => {
  if (!question || !answer) {
    throw new Error("Dados do faq necessários para criação");
  }

  const response = (
    await httpClient.post("/faqs", {
      question,
      answer,
    })
  ).data;

  return response;
};

const deleteFaq = async (faqId: string) => {
  const response = (await httpClient.delete(`/faqs/${faqId}`)).data;

  return response;
};

const updateFaq = async (faqId: string, question: string, answer: string) => {
  const response = (
    await httpClient.put(`/faqs/${faqId}`, { question, answer })
  ).data;

  return response;
};

export { getAllFaqs, getFaq, createFaq, deleteFaq, updateFaq };
