import httpClient from '../http-client';
import UserFile from '../models/from-api-response/user-file';

const getUserFile = async (courseId: string, reference_type: string) => {
  const userFile = (
    await httpClient.get<UserFile[]>(
      `user-files/?reference_id=${courseId}&reference_type=${reference_type}`,
    )
  ).data;

  return userFile;
};

const updateSituationFile = async (file_id: string, situation: string) => {
  await httpClient.patch<UserFile[]>(`user-files/${file_id}`, { situation });
};

const deleteUserFile = async (file_id: string) => {
  await httpClient.delete<UserFile[]>(`user-files/${file_id}`);
};

export { getUserFile, updateSituationFile, deleteUserFile };
