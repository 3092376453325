import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Link, useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import BreadCrumb from '../../components/BreadCrumb';
import DefaultButton from '../../components/DefaultButton';
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
} from '../../components/DefaultCreationForm';
import { BiTrash } from 'react-icons/bi';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { CreateAndEditCategoryContainer, TrashButton } from './style';
import {
  deleteQuestions,
  getQuestionsForCategory,
} from '../../services/forum-category';
import DefaultTable from '../../components/DefaultTable';
import { AiOutlineEye } from 'react-icons/ai';

interface CreateAndEditCategoryParams {
  categoryId: string;
}

const EditQuestions: React.FC = () => {
  const history = useHistory();

  const { categoryId } = useParams<CreateAndEditCategoryParams>();
  const [questions, setQuestions] = useState([] as any[]);

  const getQuestions = async () => {
    const localQuestions = await getQuestionsForCategory(categoryId);
    setQuestions(localQuestions || []);
  };

  useEffect(() => {
    getQuestions();
  }, [getQuestionsForCategory]);

  const goToForumCategories = () => {
    history.push('/forum/categories');
  };

  const removeItem = useCallback(async (categoryId: any) => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: 'Tem certeza que deseja remover este tópico?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await deleteQuestions(categoryId);

          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Tópico excluído com sucesso!',
          });

          await getQuestions();
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: `Erro ao excluir o tópico.`,
          });
        }
      }
    });
  }, []);

  const questionsToBeShown = useMemo(() => {
    let localQuestions = [];
    if (questions && questions.length) {
      localQuestions.push(
        ...(((questions || []).map(q => q.questions) || []).flat() || [])
          .filter(q => !!q)
          .map((q: any) => {
            return {
              title: q.title,
              description: q.description,
              comments: (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  {q.answer > 0 && (
                    <DefaultButton
                      className="questions-number transparent small"
                      onClick={() =>
                        history.push(
                          `/forum/categories/${categoryId}/questions/${q.question_id}/comments`,
                        )
                      }
                    >
                      <span>{q.answer}</span> <AiOutlineEye size={18} />
                    </DefaultButton>
                  )}
                </div>
              ),
              actions: (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <DefaultButton
                    className="small danger"
                    onClick={() => removeItem(q.question_id)}
                  >
                    <BiTrash size={18} />
                  </DefaultButton>
                </div>
              ),
            };
          }),
      );
    }

    return localQuestions;
  }, [questions]);

  return (
    <CreateAndEditCategoryContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Dashboard</Link>,
          <Link to="/categories">Fórum</Link>,
          <span>Editar Tópicos</span>,
        ]}
      />

      <DefaultPageTitle>Editar Tópicos</DefaultPageTitle>

      <div>
        <DefaultTable
          items={questionsToBeShown}
          headersConfig={[
            {
              headerLabel: <span>Título</span>,
              propName: 'title',
              attributes: { style: { width: '35%' } },
            },
            {
              headerLabel: <span>Descrição</span>,
              propName: 'description',
              attributes: { style: { width: '35%' } },
            },
            {
              headerLabel: <span>Comentários</span>,
              propName: 'comments',
            },
            {
              headerLabel: <span>Ações</span>,
              propName: 'actions',
            },
          ]}
        />
      </div>

      <DefaultCreationForm>
        <DefaultCreationFormButtonGroup>
          <DefaultButton
            type="button"
            className="danger"
            onClick={goToForumCategories}
          >
            Voltar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditCategoryContainer>
  );
};

export default EditQuestions;
