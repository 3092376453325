import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import BreadCrumb from '../../components/BreadCrumb';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { DepositionsContainer } from './style';
import DefaultTable from '../../components/DefaultTable';
import DefaultButton from '../../components/DefaultButton';
import DefaultFilterForm from '../../components/DefaultFilterForm';
import Select, { components } from 'react-select';
import { getAllCourses as getAllTrailsService } from '../../services/trails';
import { FcApproval } from 'react-icons/fc';
import { IoIosCloseCircle } from 'react-icons/io';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import {
  getUserFile as getUserFileService,
  updateSituationFile as updateSituationFileService,
  deleteUserFile as deleteUserFileService,
} from '../../services/user-files';
import { DefaultCreationFormGroup } from '../../components/DefaultCreationForm';
import { Switch } from '../CreateAndEditContent/style';
import { getAllContents } from '../../services/contents';
import UserFile from '../../models/from-api-response/user-file';
import formatDataFromString from '../../helpers/converters/convert-date';
import { AiOutlineDownload } from 'react-icons/ai';

const TestsReceived: React.FC = () => {
  const [selectedTrails, setSelectedTrails] = useState([] as unknown as string);
  const [trailsOptions, setTrailsOptions] = useState(
    [] as { value: string; label: string }[],
  );
  const [coursesOptions, setCoursesOptions] = useState(
    [] as { value: string; label: string }[],
  );
  const [isChecked, setIsChecked] = useState(false);
  const [userFile, setUserFile] = useState([] as UserFile[]);

  useEffect(() => {
    (async () => {
      if (isChecked) {
        const localCourses = await getAllContents({
          is_active: true,
          is_library: true,
        });

        if (localCourses && localCourses.length) {
          setCoursesOptions(
            localCourses
              .filter(course => course.info.allow_user_file)
              .map(c => ({ label: c.title, value: c.id })),
          );
        }
      } else {
        const localTrails = await getAllTrailsService({
          is_active: true,
        });

        if (localTrails && localTrails.length) {
          setTrailsOptions(
            localTrails
              .filter((course: any) => course.info.allow_user_file)
              .map((trail: { name: string; trail_id: string }) => ({
                label: trail.name,
                value: trail.trail_id,
              })),
          );
        }
      }
    })();
  }, [isChecked]);

  const selectionTrailsOrCourses = useCallback(async () => {
    if (selectedTrails.length === 0) {
      return;
    }
    const localUserFile = await getUserFileService(
      selectedTrails,
      isChecked ? 'content' : 'trail',
    );

    if (localUserFile) {
      setUserFile(localUserFile);
    }
  }, [isChecked, selectedTrails]);

  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">Sem mais opções</span>
      </components.NoOptionsMessage>
    );
  };

  const handleDownload = async (reference: string) => {
    try {
      window.open(reference, '_blank');
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao baixar o arquivo ' + error.message,
        icon: 'error',
      });
    }
  };

  const approvedUpdate = useCallback(
    async (fileId: string) => {
      Swal.fire({
        title: 'Confirmação',
        text: `Tem certeza que deseja aprovar esse aluno?`,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        focusConfirm: false,
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await updateSituationFileService(fileId, 'approved');
            selectionTrailsOrCourses();
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: `Erro ao aprovar o aluno` + error.message,
            });
          }
        }
      });
    },
    [selectionTrailsOrCourses],
  );

  const reprovedUpdate = useCallback(
    async (fileId: string) => {
      Swal.fire({
        title: 'Confirmação',
        text: `Tem certeza que deseja reprovar esse aluno?`,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        focusConfirm: false,
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await updateSituationFileService(fileId, 'reproved');
            selectionTrailsOrCourses();
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: `Erro ao reprovar o aluno` + error.message,
            });
          }
        }
      });
    },
    [selectionTrailsOrCourses],
  );

  const deleteUserFile = useCallback(
    async (fileId: string) => {
      Swal.fire({
        title: 'Confirmação',
        text: `Tem certeza que deseja deletar este arquivo?`,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        focusConfirm: false,
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await deleteUserFileService(fileId);
            selectionTrailsOrCourses();
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: `Erro ao deletar o arquivo` + error.message,
            });
          }
        }
      });
    },
    [selectionTrailsOrCourses],
  );

  useEffect(() => {
    selectionTrailsOrCourses();
  }, [selectedTrails, isChecked]);

  const testsReceivedToBeShown = useMemo(() => {
    return userFile.length === 0
      ? []
      : userFile.map((trailSelected: UserFile) => ({
          title: trailSelected.name,
          name: trailSelected.file_name,
          date: formatDataFromString(trailSelected.created_at),
          status:
            trailSelected.situation === 'pending'
              ? 'Pendente'
              : trailSelected.situation === 'approved'
              ? 'Aprovado'
              : 'Reprovado',
          actions: (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                gap: '5px',
                wordBreak: 'normal',
              }}
            >
              <DefaultButton
                className="small info"
                onClick={() => approvedUpdate(trailSelected.user_file_id)}
              >
                Aprovar
                <FcApproval />
              </DefaultButton>
              <DefaultButton
                className="small warning"
                onClick={() => reprovedUpdate(trailSelected.user_file_id)}
              >
                Reprovar
                <IoIosCloseCircle />
              </DefaultButton>
              <DefaultButton
                className="small sucess"
                onClick={() => handleDownload(trailSelected.file_reference)}
              >
                Baixar
                <AiOutlineDownload />
              </DefaultButton>
              <DefaultButton
                className="small danger"
                onClick={() => deleteUserFile(trailSelected.user_file_id)}
              >
                Excluir
                <RiDeleteBin6Fill />
              </DefaultButton>
            </div>
          ),
        }));
  }, [approvedUpdate, deleteUserFile, reprovedUpdate, userFile]);

  return (
    <DepositionsContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Dashboard</Link>,
          <span>Avaliar Exercícios</span>,
        ]}
      />

      <DefaultPageTitle>Avaliar Exercícios</DefaultPageTitle>

      <div style={{ display: 'flex' }}>
        <DefaultCreationFormGroup>
          <label>Trilha ou Curso</label>
          <Switch style={{ width: '50px' }}>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={() => {
                setIsChecked(oldValue => !oldValue);
              }}
            />
            <span className="slider round"></span>
          </Switch>
        </DefaultCreationFormGroup>
      </div>

      <DefaultFilterForm>
        <span className="entire-row">
          <label htmlFor="trilha">{isChecked ? 'Curso' : 'Trilha'}</label>
          <Select
            Single
            isClearable
            options={isChecked ? coursesOptions : trailsOptions}
            placeholder={isChecked ? 'Curso' : 'Trilha'}
            components={{ NoOptionsMessage }}
            onChange={options =>
              setSelectedTrails(options?.value ? options.value : '')
            }
            styles={{ noOptionsMessage: base => ({ ...base }) }}
          />
        </span>
      </DefaultFilterForm>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Nome</span>,
            propName: 'title',
            attributes: { style: { width: '15%' } },
          },
          {
            headerLabel: <span>Arquivo</span>,
            propName: 'name',
            attributes: { style: { width: '15%' } },
          },
          {
            headerLabel: <span>Data de envio</span>,
            propName: 'date',
            attributes: { style: { width: '15%' } },
          },
          {
            headerLabel: <span>Situação</span>,
            propName: 'status',
            attributes: { style: { textAlign: 'center' } },
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={testsReceivedToBeShown}
        emptyListMessage={'Não foram encontrados arquivos enviados!'}
      />
    </DepositionsContainer>
  );
};

export default TestsReceived;
