import styled from "styled-components";

const SideBarContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const LogoContainer = styled.div`
  padding: 25px 0px;
  margin-bottom: 10px;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 140px;
  }
`;

const Menus = styled.div`
  padding: 0 15px;

  > div {
    width: 100%;
  }
  a,
  button {
    display: flex;
    align-items: center;
    cursor: pointer;
    background: none;
    outline: none;
    border: none;
    color: var(--primary-color);
    font-size: var(--font-small);
    text-decoration: none;
    width: 100%;
    border-radius: 5px;
    padding: 10px 5px;
    transition: all 0.3s;
    margin-bottom: 10px;
    span:first-of-type {
      margin-right: 5px;
      display: flex;
      align-items: center;
      svg {
        width: 20px;
        height: 20px;
      }
    }

    .icon {
      margin-right: 5px;
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 20px;
        height: 20px;
      }
    }
    .sub-items {
      margin-left: auto;
      transition: transform 0.3s ease;
      &.active {
        transform: rotate(90deg);
      }
    }

    &:hover,
    &.active {
      background: #f4f5f7;
    }

    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
`;

const LogoutButton = styled.button``;

export { SideBarContainer, LogoContainer, Menus, LogoutButton };
