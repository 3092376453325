import httpClient from "../http-client";

const getSegmentations = async () => {
  const segmentationItemTypes = (
    await httpClient.get("/segmentation-items/type")
  ).data;

  return segmentationItemTypes;
};

const getSegmentation = async (segmentationId: string) => {
  const segmentation = (
    await httpClient.get(`/segmentation-items/${segmentationId}/type`)
  ).data;
  return segmentation;
};

const removeSegmentation = async (segmentationItemId: string) => {
  await httpClient.delete(`/segmentation-items/${segmentationItemId}`);
};

const createSegmentation = async (
  segmentationTypeId: string,
  newSegmentation: any
) => {
  await httpClient.post(
    `/segmentation-items/${segmentationTypeId}`,
    newSegmentation
  );
};

const updateSegmentation = async (
  segmentationId: string,
  newSegmentation: any
) => {
  await httpClient.put(
    `/segmentation-items/${segmentationId}`,
    newSegmentation
  );
};

export {
  getSegmentations,
  getSegmentation,
  removeSegmentation,
  createSegmentation,
  updateSegmentation,
};
