import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AiOutlineSearch, AiOutlineUpload } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import BreadCrumb from '../../components/BreadCrumb';
import DefaultButton from '../../components/DefaultButton';
import { DefaultCreateButtonAndSearchFormContainer } from '../../components/DefaultCreateButtonAndSearchFormContainer';
import DefaultFilterForm from '../../components/DefaultFilterForm';
import DefaultInput from '../../components/DefaultInput';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import DefaultTable from '../../components/DefaultTable';
import {
  getAllUsers,
  updateUserPermissionToAdmin,
  importUsersFromFile,
  updateUserPermissionToAnalyst,
} from '../../services/users';
import { UsersContainer } from './style';
import Switch from 'react-switch';
import { CreateButtonDefaultContainer } from '../../components/CreateButtonDefaultContainer';
import { BiMessageAltError } from 'react-icons/bi';
import httpClient from '../../http-client';
import ModalPhone from './ModalPhone';
import { getNumberForString } from '../../helpers/mask';

const Users: React.FC = () => {
  const [users, setUsers] = useState([] as any[]);
  const [userName, setUserName] = useState('');
  const [phoneModalOpen, setPhoneModalOpen] = useState<string>('');
  const fileRef = useRef<HTMLInputElement>(null);

  const getUsers = async (filters?: any, event?: React.FormEvent) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    const users = await getAllUsers({ ...filters, name: userName });
    setUsers(users || []);
  };

  const updatePermissionToAdmin = useCallback(async (userId: string) => {
    try {
      await updateUserPermissionToAdmin(userId);

      Swal.fire({
        title: 'Sucesso',
        text: 'Permissão alterada com sucesso',
        icon: 'success',
      });

      await getUsers();
    } catch (err) {
      Swal.fire({
        title: 'Erro',
        text: 'Não foi alterar a permissão do usuário.',
        icon: 'error',
      });
    }
  }, []);

  const updatePermissionToAnalyst = useCallback(async (userId: string) => {
    try {
      await updateUserPermissionToAnalyst(userId);

      Swal.fire({
        title: 'Sucesso',
        text: 'Permissão alterada com sucesso',
        icon: 'success',
      });

      await getUsers();
    } catch (err) {
      Swal.fire({
        title: 'Erro',
        text: 'Não foi alterar a permissão do usuário.',
        icon: 'error',
      });
    }
  }, []);

  function triggerInputFile() {
    const btn = fileRef.current;
    if (btn) btn.click();
  }

  function getUsersFile(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();

    let file = e.currentTarget?.files;

    if (file && file[0]) {
      const formData = new FormData();
      formData.append('file', file[0]);
      importUsersFromFile(formData)
        .then(() =>
          Swal.fire({
            title: 'Sucesso',
            text: 'Usuário(s) importado(s) com sucesso',
            icon: 'success',
          }).then(() => window.location.reload()),
        )
        .catch(err =>
          Swal.fire({
            title: 'Erro',
            text: `Não foi possível importar o(s) usuário(s). ${err.response.data.message}.`,
            icon: 'error',
          }),
        );
    }
  }

  const updateUser = useCallback(
    async (data: { user_id: string; phone_number?: string }) => {
      try {
        await httpClient.post<any>(`/users/reset-user`, { ...data });
        await getUsers();
      } catch (error) {
        Swal.fire({
          title: 'Erro',
          text: `Usuário não registrado!`,
        });
      }
    },
    [],
  );

  const handleResetUsers = useCallback(async (e, user_id: string) => {
    e.preventDefault();

    const askAboutPhone = (user_id: string): void => {
      Swal.fire({
        title: '<strong>Alterar Telefone</strong>',
        html: 'Deseja adicionar <b>um novo número de telefone</b>?',
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim',
        focusConfirm: false,
        icon: 'question',
      }).then(async result => {
        if (result.isConfirmed) {
          setPhoneModalOpen(user_id);
        } else {
          updateUser({ user_id });
        }
      });
    };

    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: 'Tem certeza que deseja <b>resetar</b> esse usuário?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
      focusConfirm: false,
      icon: 'question',
    }).then(async result => {
      if (result.isConfirmed) {
        askAboutPhone(user_id);
      }
    });
  }, []);

  const usersToBeShown = useMemo(() => {
    return users && users.length
      ? users.map(user => ({
          id: user.user_id,
          name: user.name,
          registration: user.registration,
          is_admin: (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                gap: '5px',
              }}
              title={user.is_admin ? 'Remover permissões' : 'Tornar admin'}
            >
              <Switch
                onChange={checked => updatePermissionToAdmin(user.user_id)}
                checked={user.is_admin}
              />
            </div>
          ),
          is_analyst: (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                gap: '5px',
              }}
              title={user.is_analyst ? 'Remover permissões' : 'Tornar analista'}
            >
              <Switch
                onChange={checked => updatePermissionToAnalyst(user.user_id)}
                checked={user.is_analyst}
              />
            </div>
          ),
          reset: (
            <a
              href="#/"
              onClick={e => handleResetUsers(e, user.user_id)}
              style={{ background: 'none' }}
            >
              <BiMessageAltError size={20} color={`#54006e`} />
            </a>
          ),
        }))
      : [];
  }, [users, updatePermissionToAdmin]);

  const onSaveUserModal = useCallback(
    async (user_id: string, phone_number: string) => {
      updateUser({ user_id, phone_number: getNumberForString(phone_number) });
    },
    [updateUser],
  );

  useEffect(() => {
    if (!userName) {
      getUsers();
    }
  }, [userName]);

  return (
    <>
      {phoneModalOpen && (
        <ModalPhone
          onClose={() => setPhoneModalOpen('')}
          onSave={phone => onSaveUserModal(phoneModalOpen, phone)}
        />
      )}
      <UsersContainer>
        <BreadCrumb
          crumbs={[<Link to="/profile">Dashboard</Link>, <span>Usuários</span>]}
        />
        <DefaultPageTitle>Usuários</DefaultPageTitle>

        <DefaultFilterForm
          style={{
            display: 'grid',
            gridTemplateColumns: '95% 5%',
            gap: '10px',
            margin: '10px 0',
          }}
        ></DefaultFilterForm>

        <DefaultCreateButtonAndSearchFormContainer
          style={{ justifyContent: 'flex-end' }}
        >
          <CreateButtonDefaultContainer>
            <DefaultButton onClick={triggerInputFile}>
              <AiOutlineUpload size={18} />
              Importar usuários
            </DefaultButton>

            <input
              type="file"
              style={{ display: 'none' }}
              ref={fileRef}
              onChange={e => getUsersFile(e)}
              accept=".csv, .xls, .xlsx"
            />
          </CreateButtonDefaultContainer>

          <form
            onSubmit={e => getUsers(undefined, e)}
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'stretch',
              gap: '10px',
            }}
          >
            <DefaultInput
              type="search"
              placeholder="Digite sua pesquisa aqui..."
              value={userName}
              onChange={e => setUserName(e.target.value)}
              style={{ margin: 0 }}
            />
            <DefaultButton
              className="small"
              style={{ margin: 0 }}
              type="submit"
            >
              <AiOutlineSearch size={24} />
            </DefaultButton>
          </form>
        </DefaultCreateButtonAndSearchFormContainer>

        <DefaultTable
          headersConfig={[
            {
              headerLabel: <span>Matrícula</span>,
              propName: 'registration',
            },
            {
              headerLabel: <span>Nome</span>,
              propName: 'name',
            },
            {
              headerLabel: <span>Admin</span>,
              propName: 'is_admin',
            },
            {
              headerLabel: <span>Analista</span>,
              propName: 'is_analyst',
            },
            {
              headerLabel: <span>Reset</span>,
              propName: 'reset',
            },
          ]}
          items={usersToBeShown}
          emptyListMessage={'Não foram encontrados usuários cadastrados!'}
        />
      </UsersContainer>
    </>
  );
};

export default Users;
