import convertToQuestion from '../helpers/converters/convert-question';
import httpClient from '../http-client';
import QuestionForCreate from '../models/for-create/question';
import QuestionForUpdate from '../models/for-update/question';
import QuestionFromResponse from '../models/from-api-response/question';
import Question from '../models/question';

const getQuestions = async (filters?: any) => {
  if (filters) {
    for (let param of Object.keys(filters)) {
      if (!filters[param]) {
        delete filters[param];
      }
    }
  }

  const questions = (
    await httpClient.get<QuestionFromResponse[]>('/questions', {
      params: filters,
    })
  ).data;

  let convertedQuestions = [] as Question[];
  if (questions && questions.length) {
    convertedQuestions = questions.map(convertToQuestion);
  }

  return convertedQuestions;
};

const createQuestion = async (question: QuestionForCreate) => {
  await httpClient.post('/questions', question);
};

const updateQuestion = async (
  questionId: string,
  question: QuestionForUpdate,
) => {
  await httpClient.put(`/questions/${questionId}`, question);
};

const getQuestion = async (questionId: string) => {
  const question = (
    await httpClient.get<QuestionFromResponse>(`/questions/${questionId}`)
  ).data;

  let convertedQuestion = {} as Question;
  if (question && Object.keys(question).length) {
    convertedQuestion = convertToQuestion(question);
  }

  return convertedQuestion;
};

const getCorrectAlternativeId = async (questionId: string) => {
  return (
    await httpClient.get<{ correct_alternative: { alternative_id: string } }>(
      `/questions/${questionId}/correct-alternative`,
    )
  ).data.correct_alternative.alternative_id;
};

const deleteQuestion = async (questionId: string) => {
  await httpClient.delete(`/questions/${questionId}`);
};

const activateOrInactivateQuestion = async (questionId: string) => {
  await httpClient.put(`/questions/${questionId}/active`);
};

export {
  getQuestions,
  createQuestion,
  updateQuestion,
  getQuestion,
  deleteQuestion,
  activateOrInactivateQuestion,
  getCorrectAlternativeId,
};
