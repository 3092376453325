import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import BreadCrumb from '../../components/BreadCrumb';
import DefaultButton from '../../components/DefaultButton';
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from '../../components/DefaultCreationForm';

import DefaultInput from '../../components/DefaultInput';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { UpdatedAtLabel } from '../../components/UpdatedAt';
import { DefaultTextArea } from '../../components/DefaultTextArea';

import {
  ContentUploadOrSelectContainer,
  CreateAndEditContentContainer,
  ExtraMaterialsInput,
} from './style';
import {
  createContent as createContentService,
  getContent as getContentService,
  updateContent as updateContentService,
} from '../../services/contents-trail';
import checkEmptyString from '../../helpers/check-empty-string';
import { deleteFile, uploadFile } from '../../services/files';
import ReactAudioPlayer from 'react-audio-player';
import Content from '../../models/content';

interface CreateAndEditContentProps {
  fileContentId: string;
}

const CreateAndEditFileContents: React.FC = () => {
  const inputRef = useRef<any>(null);
  const [fileContent, setFileContent] = useState<File>();
  const [content, setContent] = useState<Content>();
  const { fileContentId } = useParams<CreateAndEditContentProps>();

  const history = useHistory();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [reference, setReference] = useState('');
  const [updatedAt, setUpdatedAt] = useState('');
  const [fileExtension, setFileExtension] = useState('');

  const fileDuration = 0;
  const fileType = 'FILE';

  const createContent = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(title)) {
        throw new Error('Informe um título válido para o arquivo.');
      }

      if (!fileContent) {
        throw new Error('Selecione um arquivo antes.');
      }

      const formData = new FormData();

      formData.append('file', fileContent);

      const { reference, file_id } = await uploadFile(formData);

      await createContentService({
        title: title,
        description: description.trim().length ? description : undefined,
        duration: fileDuration,
        reference: reference,
        type: fileType.toUpperCase(),
        is_library: false,
        info: { file_extension: fileExtension, file_id },
      });

      Swal.fire({
        title: 'Sucesso!',
        text: 'Arquivo criado com sucesso!',
        icon: 'success',
      });

      goToFiles();
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao criar o arquivo. ' + error.message,
        icon: 'error',
      });
    }
  };

  const updateContent = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(title)) {
        throw new Error('Informe um título válido para o arquivo.');
      }

      if (!reference) {
        throw new Error('Selecione um arquivo antes.');
      }
      let updateReference = content?.reference;
      let updateFileId = content?.info.file_id;

      if (fileContent) {
        const formData = new FormData();
        formData.append('file', fileContent);

        const { reference, file_id } = await uploadFile(formData);
        updateReference = reference;
        updateFileId = file_id;
      }

      const updatedContent = await updateContentService(fileContentId, {
        title: title,
        description: description || undefined,
        duration: fileDuration,
        reference: updateReference,
        type: fileType.toLocaleUpperCase(),
        is_active: true,
        is_library: false,
        info: {
          file_extension: fileExtension,
          file_id: updateFileId,
        },
      });
      if (updatedContent.info.file_id !== content!.info.file_id) {
        await deleteFile(content!.info.file_id);
      }

      Swal.fire({
        title: 'Sucesso!',
        text: 'Arquivo editado com sucesso!',
        icon: 'success',
      });

      goToFiles();
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao editar o arquivo. ' + error.message,
        icon: 'error',
      });
    }
  };

  const goToFiles = () => {
    history.push('/trails/file');
  };

  const getContent = useCallback(async () => {
    if (fileContentId) {
      const content = await getContentService(fileContentId);
      if (content && Object.keys(content).length) {
        setTitle(content.content.title);
        setFileExtension(content.content.info.file_extension);
        setDescription(content.content.description);
        setReference(content.content.reference);
        setContent(content.content);
        if (content.content?.updated_at) {
          let date = new Date(content.content.updated_at);
          setUpdatedAt(date.toLocaleString());
        }
      }
    }
  }, [fileContentId]);

  useEffect(() => {
    getContent();
  }, [getContent]);

  const isEditting = useMemo(() => {
    if (fileContentId) {
      return true;
    }

    return false;
  }, [fileContentId]);

  const addFile = (event: any) => {
    event.preventDefault();
    try {
      if (event) {
        let file = event.target.files[0];
        file.alreadySaved = false;

        if (file.type.includes('mpeg')) {
          setFileExtension('MP3');
        } else if (file.type.includes('pdf')) {
          setFileExtension('PDF');
        } else {
          throw new Error('Somente arquivos PDF ou MP3 são aceitos');
        }
        setFileContent(file);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => setReference(`${reader.result}`);
      }
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: error.message,
        icon: 'error',
      });
    }
  };

  return (
    <CreateAndEditContentContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Dashboard</Link>,
          <Link to="/trails/file">Trilhas</Link>,
          <span>{isEditting ? 'Editar' : 'Criar'} Arquivos</span>,
        ]}
      />

      <DefaultPageTitle>
        {isEditting ? 'Editar' : 'Criar'} Arquivos
        {updatedAt && (
          <UpdatedAtLabel> - Última atualização em {updatedAt}</UpdatedAtLabel>
        )}
      </DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">
            Título
          </label>
          <DefaultInput
            value={title}
            onChange={e => setTitle(e.target.value)}
            id="title"
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="description">Descrição</label>
          <DefaultTextArea
            value={description}
            onChange={e => setDescription(e.target.value)}
            id="description"
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="reference">
            Arquivo
          </label>
          <ContentUploadOrSelectContainer>
            {reference ? (
              fileExtension === 'MP3' ? (
                <ReactAudioPlayer
                  src={reference}
                  volume={0.5}
                  controls
                  style={{ marginBottom: '10px' }}
                />
              ) : (
                <iframe
                  title="referenced-file"
                  allowFullScreen
                  src={reference}
                  frameBorder={0}
                  style={{ marginBottom: '10px' }}
                ></iframe>
              )
            ) : (
              <></>
            )}
            <label htmlFor="extra-materials" className="extra-materials">
              Selecionar Arquivo
            </label>
            <ExtraMaterialsInput
              type="file"
              onChange={e => addFile(e)}
              ref={inputRef}
              id="extra-materials"
              accept=".pdf,.mp3"
            />
          </ContentUploadOrSelectContainer>
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton type="button" className="danger" onClick={goToFiles}>
            Cancelar
          </DefaultButton>
          <DefaultButton
            onClick={e => (isEditting ? updateContent(e) : createContent(e))}
            className="success"
          >
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditContentContainer>
  );
};

export default CreateAndEditFileContents;
