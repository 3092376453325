import styled from "styled-components";

const CategoriesContainer = styled.div`
  button.questions-number {
    cursor: pointer;
    border: none;
    background: none;
    color: #2f80ed;
    margin-left: 12px;
    font-weight: 600;
    outline: none;

    &:hover {
      text-decoration: underline;
    }
  }

  span {
    color: #000;
  }
`;

export { CategoriesContainer };
