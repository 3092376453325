import React, { useCallback, useEffect, useState } from 'react';
import { LogoContainer, LogoutButton, Menus, SideBarContainer } from './style';
import { Link, useHistory, useLocation } from 'react-router-dom';
import menusAdmin from './data/menusAdmin';
import menusAnalyst from './data/menusAnalyst';
import { BiLogOut } from 'react-icons/bi';
import { ReactComponent as Arrow } from '../../assets/arrow.svg';

import Logo from '../../assets/logo.svg';
import User from '../../models/user';
import { getActualUser } from '../../services/users';

const SideBar: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const [menuChildrenToBeShown, setMenuChildrenToBeShown] = useState<number[]>(
    [],
  );

  const showOrHideMenuChildren = (index: number) => {
    if (menuChildrenToBeShown.includes(index)) {
      const indexOfMenu = menuChildrenToBeShown.indexOf(index);
      if (indexOfMenu > -1 && indexOfMenu < menuChildrenToBeShown.length) {
        menuChildrenToBeShown.splice(indexOfMenu, 1);
      }
    } else {
      menuChildrenToBeShown.push(index);
    }

    setMenuChildrenToBeShown([...menuChildrenToBeShown]);
  };

  const checkShouldShowMenuChildren = useCallback(
    (index: number) => {
      return menuChildrenToBeShown.includes(index);
    },
    [menuChildrenToBeShown],
  );

  const logout = () => {
    window.localStorage.setItem('telhanorte-api-token', '');
    window.localStorage.setItem('actual-telhanorte-user', '');

    history.push('/login');
  };

  const [user, setUser] = useState({} as User);

  useEffect(() => {
    (async () => {
      setUser(await getActualUser());
    })();
  }, []);

  return (
    <SideBarContainer>
      <LogoContainer>
        <img src={Logo} alt="Portal construir" />
      </LogoContainer>
      <Menus>
        {menusAdmin && menusAdmin.length ? (
          user.is_admin ? (
            menusAdmin.map((menu, index) =>
              menu.children && menu.children.length ? (
                <div key={index}>
                  <button onClick={() => showOrHideMenuChildren(index)}>
                    {menu.label}
                    <span
                      className={`sub-items ${
                        menuChildrenToBeShown.includes(index) ? 'active' : ''
                      }`}
                    >
                      <Arrow />
                    </span>
                  </button>
                  <div style={{ marginLeft: '25px' }}>
                    {checkShouldShowMenuChildren(index) ? (
                      menu.children.map(child => (
                        <Link
                          className={
                            location.pathname === child.path ? 'active' : ''
                          }
                          key={child.path}
                          to={child.path}
                        >
                          {child.label}
                        </Link>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ) : menu.path ? (
                <Link
                  className={location.pathname === menu.path ? 'active' : ''}
                  key={menu.path}
                  to={menu.path}
                >
                  {menu.label}
                </Link>
              ) : (
                <></>
              ),
            )
          ) : (
            user.is_analyst &&
            menusAnalyst.map((menu, index) =>
              menu.children && menu.children.length ? (
                <div key={index}>
                  <button onClick={() => showOrHideMenuChildren(index)}>
                    {menu.label}
                    <span
                      className={`sub-items ${
                        menuChildrenToBeShown.includes(index) ? 'active' : ''
                      }`}
                    >
                      <Arrow />
                    </span>
                  </button>
                  <div style={{ marginLeft: '25px' }}>
                    {checkShouldShowMenuChildren(index) ? (
                      menu.children.map(child => (
                        <Link
                          className={
                            location.pathname === child.path ? 'active' : ''
                          }
                          key={child.path}
                          to={child.path}
                        >
                          {child.label}
                        </Link>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ) : menu.path ? (
                <Link
                  className={location.pathname === menu.path ? 'active' : ''}
                  key={menu.path}
                  to={menu.path}
                >
                  {menu.label}
                </Link>
              ) : (
                <></>
              ),
            )
          )
        ) : (
          <></>
        )}

        <LogoutButton onClick={logout}>
          <span className="icon">
            <BiLogOut />
          </span>
          <span className="text">Logout</span>
        </LogoutButton>
      </Menus>
    </SideBarContainer>
  );
};

export default SideBar;
