import React, { useEffect, useState } from "react";
import getActualUser from "../../helpers/get-actual-user";
import User from "../../models/user";
import { ProfileContainer } from "./style";

const Profile: React.FC = () => {
  const [user, setUser] = useState({} as User);

  useEffect(() => {
    (async () => {
      setUser(await getActualUser());
    })();
  }, []);

  return (
    <ProfileContainer>
      <h2>Seja Bem Vindo (a) {user.name}</h2>
    </ProfileContainer>
  );
};

export default Profile;
