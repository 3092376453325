import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import BreadCrumb from '../../components/BreadCrumb';
import DefaultButton from '../../components/DefaultButton';
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from '../../components/DefaultCreationForm';
import DefaultInput from '../../components/DefaultInput';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { DefaultTextArea } from '../../components/DefaultTextArea';
import {
  QuestionsList,
  CreateAndEditExamContainer,
  Switch,
  ShowPreview,
} from './style';
import {
  createExam as createExamService,
  updateExam as updateExamService,
  getExam as getExamService,
} from '../../services/exams';
import checkEmptyString from '../../helpers/check-empty-string';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import { BiChevronUp, BiTrash } from 'react-icons/bi';
import Question from '../../models/question';
import { hideModal, showModal } from '../../helpers/modal';
import SelectQuestions from './components/SelectQuestions';
import getErrorMessage from '../../helpers/get-error-message';
import Previewer from './components/Previewer';

interface CreateAndEditExamParams {
  examId: string;
}

const CreateAndEditExam: React.FC = () => {
  const { examId } = useParams<CreateAndEditExamParams>();
  const history = useHistory();
  const [title, setTitle] = useState('');
  const [instructions, setInstructions] = useState('');
  const [attempts, setAttepmts] = useState(0);
  const [attemptsInterval, setAttepmtsInterval] = useState(0);
  const [average, setAverage] = useState(0);
  const [questionsQuantity, setQuestionsQuantity] = useState(0);
  const [questions, setQuestions] = useState([] as Question[]);
  const [examAtStart, allowExamAtStart] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  const selectQuestions = () => {
    showModal(
      'Selecionar Questões',
      <SelectQuestions
        selectedQuestions={[...questions]}
        onApplySelection={changeSelectedQuestions}
      />,
    );
  };

  const changeSelectedQuestions = (questions: Question[]) => {
    setQuestions([...questions]);
    hideModal();
  };

  const goToExams = () => {
    history.push('/exams-questions/exams');
  };

  const getExam = useCallback(async () => {
    const localExam = await getExamService(examId);
    if (localExam && Object.keys(localExam).length) {
      setTitle(localExam.title);
      setInstructions(localExam.instructions);
      setQuestions(localExam.questions);
      setAttepmts(localExam.attempts);
      setAverage(localExam.average);
      setQuestionsQuantity(localExam.amount);
      allowExamAtStart(localExam.info.enable_quiz);
      allowExamAtStart(localExam.examAtStart);

      if (localExam.attempts_interval && localExam.attempts_interval.days) {
        setAttepmtsInterval(localExam.attempts_interval.days);
      }

      if (
        localExam &&
        localExam.info &&
        localExam.info.enable_quiz &&
        localExam.attempts > 1
      ) {
        setAttepmts(localExam.attempts - 1);
      } else {
        setAttepmts(localExam.attempts);
      }
    }
  }, [examId]);

  const validate = () => {
    if (checkEmptyString(title)) {
      throw new Error('Informe um título válido para a prova!');
    }

    if (checkEmptyString(instructions)) {
      throw new Error('Informe instruções válidas para a prova!');
    }

    if (!questions || questions.length < 1) {
      throw new Error('Informe ao menos uma questão!');
    }

    if (attempts <= 0) {
      throw new Error('O número de tentativas deve ser superior a 0!');
    }

    if (questionsQuantity <= 0) {
      throw new Error(
        'O número de questões a serem randomizadas deve ser superior a 0!',
      );
    }

    if (questionsQuantity > questions.length) {
      throw new Error(
        'A quantidade máxima de questões deve ser o número de questões selecionadas!',
      );
    }
  };

  const createExam = async (event: React.FormEvent) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    if (average <= 0) {
      Swal.fire({
        title: 'Erro',
        text: 'Erro ao criar a prova. A média da prova deve ser superior a 0.',
        icon: 'error',
      });
      return;
    }

    try {
      validate();

      await createExamService({
        title: title,
        instructions: instructions,
        questions:
          questions && questions.length
            ? questions.map(q => ({ question_id: q.id }))
            : [],
        amount_questions: [{ points: 1, amount: questionsQuantity }],
        attempts: examAtStart ? attempts + 1 : attempts,
        attempts_interval:
          attemptsInterval || attemptsInterval === 0
            ? { days: attemptsInterval }
            : undefined,
        average: average,

        info: { enable_quiz: examAtStart },
      });

      Swal.fire({
        title: 'Sucesso',
        text: 'Prova cadastrada com sucesso!',
        icon: 'success',
      });

      goToExams();
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Erro ao cadastrar Prova. ' + error.message,
        icon: 'error',
      });
    }
  };

  const updateExam = async (event: React.FormEvent) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    if (average <= 0) {
      Swal.fire({
        title: 'Erro',
        text: 'Erro ao atualizar a prova. A média da prova deve ser superior a 0.',
        icon: 'error',
      });
      return;
    }

    try {
      validate();

      await updateExamService(examId, {
        title: title,
        instructions: instructions,
        questions:
          questions && questions.length
            ? questions.map(q => ({ question_id: q.id }))
            : [],
        amount_questions: [{ points: 1, amount: questionsQuantity }],
        attempts: examAtStart ? attempts + 1 : attempts,
        average: average,
        info: { enable_quiz: examAtStart },
        attempts_interval:
          attemptsInterval || attemptsInterval === 0
            ? { days: attemptsInterval }
            : undefined,
      });

      Swal.fire({
        title: 'Sucesso',
        text: 'Prova editada com sucesso!',
        icon: 'success',
      });

      goToExams();
    } catch (error) {
      const message = getErrorMessage(error);

      if (message === 'As provas devem ter ao menos uma tentativa') {
        Swal.fire({
          title: 'Erro',
          text: 'Erro ao editar Prova. A prova deve ter ao menos duas tentativas.',
          icon: 'error',
        });
      } else {
        Swal.fire({
          title: 'Erro',
          text: 'Erro ao editar Prova. ' + error.message,
          icon: 'error',
        });
      }
    }
  };

  const removeQuestion = (question: Question) => {
    Swal.fire({
      icon: 'question',
      text: 'Tem certeza que deseja remover esta questão?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
    }).then(result => {
      if (result.isConfirmed) {
        const newQuestions = [...questions.filter(q => q !== question)];
        setQuestions(newQuestions);

        if (newQuestions.length < questionsQuantity) {
          setQuestionsQuantity(newQuestions.length);
        }
      }
    });
  };

  const changeQuestionPosition = (question: Question, up: boolean) => {
    const indexOfQuestion = questions.indexOf(question);
    if (up) {
      const nextQuestion = questions[indexOfQuestion - 1];

      questions[indexOfQuestion] = nextQuestion;
      questions[indexOfQuestion - 1] = question;
    } else {
      const previousQuestion = questions[indexOfQuestion + 1];

      questions[indexOfQuestion] = previousQuestion;
      questions[indexOfQuestion + 1] = question;
    }

    setQuestions([...questions]);
  };

  const isEditting = useMemo(() => {
    return !!examId;
  }, [examId]);

  useEffect(() => {
    if (examId) {
      getExam();
    }
  }, [examId, getExam]);

  return (
    <CreateAndEditExamContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Dashboard</Link>,
          <span>Provas e Questões</span>,
          <span>{isEditting ? 'Editar' : 'Criar'} Prova</span>,
        ]}
      />

      <DefaultPageTitle>
        {isEditting ? 'Editar' : 'Criar'} Prova
      </DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">
            Título
          </label>
          <DefaultInput
            id="title"
            type="text"
            required
            value={title}
            onChange={e => setTitle(e.target.value)}
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="instructions" className="required">
            Instruções
          </label>
          <DefaultTextArea
            id="instructions"
            required
            value={instructions}
            onChange={e => setInstructions(e.target.value)}
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="attemptsInterval">
            Tentativas
          </label>
          <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
            <DefaultInput
              type="number"
              id="attempts"
              min={1}
              value={attempts}
              onWheel={e => {
                e.stopPropagation();
              }}
              onChange={e => {
                const value = e.target.value;
                if (value && isNaN(+value || 0)) {
                  e.stopPropagation();
                  return false;
                }

                setAttepmts(+value.replaceAll('_', ''));
              }}
              required
            />
          </div>
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="attempts">
            Intervalo entre as tentativas (em dias)
          </label>
          <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
            <DefaultInput
              type="number"
              id="attemptsInterval"
              min={1}
              value={attemptsInterval}
              onWheel={e => {
                e.stopPropagation();
              }}
              onChange={e => {
                const value = e.target.value;
                if (value && isNaN(+value || 0)) {
                  e.stopPropagation();
                  return false;
                }

                setAttepmtsInterval(+value.replaceAll('_', ''));
              }}
              required
            />
          </div>
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="average">
            Média
          </label>

          <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
            <DefaultInput
              style={{ flexGrow: 1, marginRight: '10px', padding: 0 }}
              type="range"
              id="average"
              min={0}
              max={100}
              value={average}
              onChange={e => setAverage(+e.target.value.replaceAll('_', ''))}
              required
            />
            <p>{average}%</p>
          </div>
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="name">
            Banco de questões
          </label>
          <div
            style={{
              border: 'solid 1px var(--default-dark-gray)',
              borderRadius: '5px',
              width: '100%',
              padding: '10px',
            }}
          >
            <div className="center">
              <DefaultButton onClick={selectQuestions} type="button">
                Selecionar Questões
              </DefaultButton>
            </div>

            {questions && questions.length ? (
              <QuestionsList>
                {questions.map((question, index) => (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <p style={{ flexGrow: 1 }}>{question.enunciated}</p>

                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      <DefaultButton
                        type="button"
                        onClick={() => removeQuestion(question)}
                        className="small danger"
                      >
                        <BiTrash />
                      </DefaultButton>
                    </div>
                  </div>
                ))}
              </QuestionsList>
            ) : (
              <></>
            )}
          </div>
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="average">
            Número de questões randomizadas exibidas na prova
          </label>

          <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
            <DefaultInput
              required
              type="number"
              id="average"
              onWheel={e => {
                e.stopPropagation();
              }}
              min={1}
              max={questions.length}
              value={questionsQuantity}
              disabled={!questions.length}
              onChange={e => {
                const value = e.target.value;
                if (value && isNaN(+value || 0)) {
                  e.stopPropagation();
                  return false;
                }

                setQuestionsQuantity(+value.replaceAll('_', ''));
              }}
            />
          </div>
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label>Essa prova será um quiz?</label>
          <Switch>
            <input
              type="checkbox"
              checked={examAtStart}
              onChange={e => allowExamAtStart(!examAtStart)}
            />
            <span className="slider round"></span>
          </Switch>
        </DefaultCreationFormGroup>
        <ShowPreview
          onClick={e => {
            e.preventDefault();
            setShowPreview(!showPreview);
          }}
        >
          {`${showPreview ? 'Ocultar' : 'Exibir'}`} pré-visualização das
          questões{' '}
          <BiChevronUp size={26} className={`${showPreview ? 'opened' : ''}`} />
        </ShowPreview>
        {showPreview ? (
          <Previewer
            questions={questions}
            title={title}
            description={instructions}
          />
        ) : (
          ''
        )}
        <DefaultCreationFormButtonGroup>
          <DefaultButton type="button" className="danger" onClick={goToExams}>
            Cancelar
          </DefaultButton>
          <DefaultButton
            onClick={e => (isEditting ? updateExam(e) : createExam(e))}
            className="success"
          >
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditExamContainer>
  );
};

export default CreateAndEditExam;
