import React, { ReactChild, useEffect } from 'react';

import { Clickable, Container, Content } from './styles';

interface IModal {
  onClickOutside?: () => void;
  children: ReactChild;
  widthAuto?: boolean;
}

const Modal: React.FC<IModal> = ({
  children,
  onClickOutside,
  widthAuto = false,
}: IModal) => {
  useEffect(() => {
    const root = document.getElementById('root');

    if (root) {
      root.style.overflow = 'hidden';
    }

    return () => {
      const rootEl = document.getElementById('root');
      if (rootEl) {
        rootEl.style.overflow = 'auto';
      }
    };
  }, []);

  return (
    <Container>
      <Clickable onClick={onClickOutside && onClickOutside} />
      <Content widthAuto={widthAuto}>{children}</Content>
    </Container>
  );
};

export default Modal;