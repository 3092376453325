import React from "react";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import "react-toastify/dist/ReactToastify.css";

interface IDatatable {
  pageSize: any;
  className: string;
  pagination: any;
  customColumns: any;
  myData: any;
}

const Datatable: React.FC<IDatatable> = ({
  pageSize,
  className,
  pagination,
  customColumns,
  myData,
}) => {
  function capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  let columns = [];

  if (!customColumns) {
    for (var key in myData[0]) {
      let editable = null;

      columns.push({
        Header: <b>{capitalize(key.toString())}</b>,
        accessor: key,
        Cell: editable,
        style: {
          textAlign: "center",
        },
      });
    }
  } else {
    columns = customColumns.map((col: any) => {
      return {
        ...col,
        Header: <b>{col.Header}</b>,
        style: {
          textAlign: "center",
        },
      };
    });
  }

  return (
    <>
      <ReactTable
        data={myData}
        columns={columns}
        defaultPageSize={pageSize}
        className={className}
        showPagination={pagination}
        NoDataComponent="div"
        noDataText=""
      />
    </>
  );
};

export default Datatable;
