import styled from 'styled-components';

const SelectQuestionsContainer = styled.div`
  width: 100%;
  padding: 0 40px;
  height: calc(100% - 60px);
  overflow-y: auto;
  border-bottom: solid 1px var(--default-dark-gray);

  & input[type='checkbox'] {
    display: block;
    margin: 0 auto;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  & > :not(:last-child) {
    margin-bottom: 10px;
  }

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;

export { SelectQuestionsContainer };
