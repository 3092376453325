import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DefaultButton from '../../../../components/DefaultButton';
import DefaultTable from '../../../../components/DefaultTable';
import Content from '../../../../models/content';
import Lesson from '../../../../models/lesson';
import { getAllContents } from '../../../../services/contents-trail';
import { SelectLessonContainer } from './style';

interface SelectLessonProps {
  selectedLessons: Content[];
  onApplySelection: (selectedLessons: Content[]) => void;
}

const SelectLesson: React.FC<SelectLessonProps> = ({
  selectedLessons,
  onApplySelection,
}) => {
  const [lessons, setLessons] = useState([] as any[]);

  const getLessons = async () => {
    const localLessons = await getAllContents();
    if (localLessons && localLessons.length) {
      const filteredLessons = localLessons.filter(
        (localLesson: Content) => localLesson.active,
      );
      setLessons(filteredLessons);
    }
  };

  const handleCheckLesson = useCallback(
    (event: React.FormEvent<HTMLInputElement>, lesson: Content) => {
      if (
        !selectedLessons.find(selectedLesson => selectedLesson.id === lesson.id)
      ) {
        selectedLessons.push(lesson);

        event.currentTarget.checked = true;
      } else {
        const indexOfLesson = selectedLessons
          .map(les => les.id)
          .indexOf(lesson.id);
        if (indexOfLesson > -1 && indexOfLesson < selectedLessons.length) {
          selectedLessons.splice(indexOfLesson, 1);

          event.currentTarget.checked = false;
        }
      }
    },
    [selectedLessons],
  );

  const applySelection = (event: React.FormEvent<HTMLButtonElement>) => {
    onApplySelection(selectedLessons);
  };

  const lessonsToBeShown = useMemo(() => {
    return lessons.map(lesson => {
      const shouldBeChecked = selectedLessons
        .map(lesson => lesson.id)
        .includes(lesson.id);
       
      return {
        title: lesson.title,
        description: lesson.description,
        type: lesson.type === 'FILE' ? 'ARQUIVO': lesson.type,
        check: (
          <>
            <input
              type="checkbox"
              value={lesson.id}
              defaultChecked={shouldBeChecked}
              onChange={e => handleCheckLesson(e, lesson)}
            />
          </>
        ),
      };
    });
  }, [lessons, selectedLessons, handleCheckLesson]);

  useEffect(() => {
    getLessons();
  }, []);

  return (
    <SelectLessonContainer>
      <DefaultButton
        style={{ position: 'fixed', right: '11%', bottom: '11%' }}
        onClick={applySelection}
      >
        Aplicar Seleção
      </DefaultButton>
      <DefaultTable
        usePagination={false}
        headersConfig={[
          {
            headerLabel: <span>Título</span>,
            propName: 'title',
            attributes: { style: { width: '35%' } },
          },
          { headerLabel: <span>Descrição</span>, propName: 'description' },
          { headerLabel: <span>Tipo</span>, propName: 'type' },
          { headerLabel: <span>Selecionar</span>, propName: 'check' },
        ]}
        items={lessonsToBeShown}
      />
    </SelectLessonContainer>
  );
};

export default SelectLesson;
