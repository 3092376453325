import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import BreadCrumb from '../../components/BreadCrumb';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { DepositionsContainer } from './style';
import { getQuestionsForCategory } from '../../services/forum-category';
import {
  changeContentDeposition,
  changeTrailDeposition,
  getAllDepositions,
} from '../../services/depositions';
import DefaultTable from '../../components/DefaultTable';
import Switch from 'react-switch';
import { DefaultCreateButtonAndSearchFormContainer } from '../../components/DefaultCreateButtonAndSearchFormContainer';
import { CreateButtonDefaultContainer } from '../../components/CreateButtonDefaultContainer';
import DefaultInput from '../../components/DefaultInput';
import DefaultButton from '../../components/DefaultButton';
import { AiOutlineSearch } from 'react-icons/ai';
import IDepositions from '../../models/from-api-response/depositions';

const Depositions: React.FC = () => {
  const [depositions, setDepositions] = useState([] as IDepositions[]);
  const [depositionName, setDepositionName] = useState('');

  const getSegmentation = async (event?: React.FormEvent) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    const localDepositions = await getAllDepositions({ name: depositionName });

    setDepositions(localDepositions || []);
  };

  const handleUpdateDeposition = useCallback(
    async (deposition: IDepositions) => {
      try {
        if (deposition.content_id) {
          await changeContentDeposition(
            deposition.content_id,
            deposition.user_id,
          );
        } else if (deposition.trail_id) {
          await changeTrailDeposition(deposition.trail_id, deposition.user_id);
        }

        Swal.fire({
          title: 'Sucesso',
          text: 'Exibição do depoimento alterado com sucesso!',
          icon: 'success',
        });

        await getSegmentation();
      } catch (err) {
        Swal.fire({
          title: 'Erro',
          text: 'Houve um erro ao alterar a exibição do depoimento.',
          icon: 'error',
        });
      }
    },
    [],
  );

  const depositionsToBeShown = useMemo(() => {
    return depositions && depositions.length
      ? depositions.map((deposition: IDepositions) => ({
          title: deposition.name,
          course: deposition.trail_name
            ? deposition.trail_name
            : deposition.title,
          description: deposition.description,
          show: (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                gap: '5px',
              }}
              title={
                deposition.show_to_all_users
                  ? 'Esconder dos usuários'
                  : 'Mostrar aos usuários'
              }
            >
              <Switch
                onChange={() => handleUpdateDeposition(deposition)}
                checked={deposition.show_to_all_users}
              />
            </div>
          ),
        }))
      : [];
  }, [depositions, handleUpdateDeposition]);

  useEffect(() => {
    getSegmentation();
  }, []);

  useEffect(() => {
    if (!depositionName) {
      getSegmentation();
    }
  }, [depositionName]);

  return (
    <DepositionsContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Dashboard</Link>,
          <span>Depoimentos</span>,
        ]}
      />

      <DefaultPageTitle>Depoimentos</DefaultPageTitle>

      <DefaultCreateButtonAndSearchFormContainer style={{ gap: 0 }}>
        <span></span>
        <form
          onSubmit={getSegmentation}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'stretch',
            gap: '10px',
          }}
        >
          <DefaultInput
            type="search"
            placeholder="Digite o nome do autor..."
            value={depositionName}
            onChange={e => setDepositionName(e.target.value)}
            style={{ margin: 0 }}
          />
          <DefaultButton className="small" style={{ margin: 0 }} type="submit">
            <AiOutlineSearch size={24} />
          </DefaultButton>
        </form>
      </DefaultCreateButtonAndSearchFormContainer>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Autor</span>,
            propName: 'title',
            attributes: { style: { width: '15%' } },
          },
          {
            headerLabel: <span>Curso/Trilha</span>,
            propName: 'course',
            attributes: { style: { width: '15%' } },
          },
          {
            headerLabel: <span>Depoimento</span>,
            propName: 'description',
            attributes: { style: { width: '40%' } },
          },
          {
            headerLabel: <span>Exibir</span>,
            propName: 'show',
          },
        ]}
        items={depositionsToBeShown}
        emptyListMessage={'Não foram encontrados depoimentos cadastrados!'}
      />
    </DepositionsContainer>
  );
};

export default Depositions;
